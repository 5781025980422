import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DocsHRResponse } from 'core/API/documents';
import { useCachedQueryData } from 'core/hooks/useCachedQueryData';

import { useDocumentContext } from 'module/documents/context';
import CommonFields from 'module/documents/tabs/CommonFields';
import { DirectDepositForm } from 'module/documents/tabs/DirectDepositForm';
import { EmployeeTippingNotice } from 'module/documents/tabs/EmployeeTippingNotice';
import { FoodReportAgreements } from 'module/documents/tabs/FoodReportAgreements';
import { FTEForm } from 'module/documents/tabs/FTEForm';
import { HumanResourceTab } from 'module/documents/tabs/HumanResourceTab';
import { IUnderstandForm } from 'module/documents/tabs/IUnderstandForm';
import { MIW4 } from 'module/documents/tabs/MIW4';
import { PCIComplianceForm } from 'module/documents/tabs/PCIComplianceForm';
import SelectedEmployeeTab from 'module/documents/tabs/SelectedEmployeeTab';

import { W2Consent } from 'module/documents/tabs/W2Consent';
import { lazy, ReactNode, Suspense, SyntheticEvent, useMemo, useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useEmployeeFormData } from 'module/documents/hooks';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

// lazy load I9Fields component
const W4Form = lazy(() => import('module/documents/tabs/W4Form'));
const I9Fields = lazy(() => import('module/documents/tabs/I9Fields'));
const I9SupplementAForm = lazy(() => import('module/documents/tabs/I9FieldsSupplementA'));
const I9SupplementBForm = lazy(() => import('module/documents/tabs/I9FieldsSupplementB'));

interface TabPanelProps extends BoxProps {
	children?: ReactNode;
	index: number;
	value: number;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...rest } = props;
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			minHeight="50vh"
			overflow="auto"
			p={3}
			flex="1"
			{...rest}
		>
			{value === index && children}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface ITabs {
	icon?: ReactNode;
	label: string;
	component: ReactNode;
	visible?: boolean;
	disabled?: boolean;
	helperText?: string;
	id?: string;
}

// Extracted function to generate the tabs array
const generateTabs = (
	formikValues, 
	selectedEmployee: boolean,  
	isHumanResource: boolean,  
	hrDetails: DocsHRResponse,  
	loadedFormIds: Record<string, number>,
	enableHumanResource: boolean,
	setEnableHumanResource: React.Dispatch<React.SetStateAction<boolean>>,
	setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>,
	employeeSignatureFile: File | null,
	setEmployeeSignatureFile: React.Dispatch<React.SetStateAction<File | null>>,
	translatorSignatureFile: File | null,
	setTranslatorSignatureFile: React.Dispatch<React.SetStateAction<File | null>>,
	employerSignatureFile: File | null,
	setEmployerSignatureFile: React.Dispatch<React.SetStateAction<File | null>>,
): Array<ITabs> => {

	const disableAll =
		(isHumanResource && !selectedEmployee) || (isHumanResource && hrDetails === null);
	console.log('loadedFormIds:', loadedFormIds);
	return [
		{
			label: 'Select employee',
			component: <SelectedEmployeeTab setActiveTabIndex={setActiveTabIndex} enableHumanResource={enableHumanResource} setEnableHumanResource={setEnableHumanResource} />,
			visible: isHumanResource,
		},
		{
			label: 'Human Resource Data',
			component: <HumanResourceTab setEmployerSignatureFile={setEmployerSignatureFile} />,
			icon: hrDetails === null ? <WarningAmberIcon fontSize="small" color="warning" /> : null,
			visible: isHumanResource,
			disabled: enableHumanResource,
		},
		{
			label: 'Common Fields',
			component: <CommonFields />,
			visible: true,
			icon:
				loadedFormIds.employeeFormId === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'I-9 Form',
			component: <I9Fields />,
			visible: true,
			id: 'i9', // Assigned from the tabs list
			icon:
				loadedFormIds.i9FormId === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'I-9 Supplement A',
			helperText: 'Enabled in I-9 Form #3a',
			component: <I9SupplementAForm translatorSignatureFile={translatorSignatureFile} setTranslatorSignatureFile={setTranslatorSignatureFile} />,
			disabled: disableAll || !formikValues.translator_assisted,
			icon:
				loadedFormIds.supplI9A === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
		},
		{
			label: 'I-9 Supplement B',
			component: <I9SupplementBForm employeeSignatureFile={employeeSignatureFile} setEmployeeSignatureFile={setEmployeeSignatureFile} 
						translatorSignatureFile={translatorSignatureFile} employerSignatureFile={employerSignatureFile}
						/>,
			visible: isHumanResource,
			disabled: disableAll || !formikValues.employee_rehire,
			icon:
				loadedFormIds.supplI9B === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			helperText: 'Enabled in I-9 Form #3b',
		},
		{
			label: 'W-4 Form',
			component: <W4Form employeeSignatureFile={employeeSignatureFile} />,
			visible: true,
			id: 'federal_w4', // Assigned from the tabs list
			icon:
				loadedFormIds.w4FormId === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'Notice to Tipped Employees',
			component: <EmployeeTippingNotice employerSignatureFile={employerSignatureFile} employeeSignatureFile={employeeSignatureFile} />,
			visible: true,
			disabled: disableAll,
			id: 'tip_credit_form', // Assigned from the tabs list
		},
		{
			label: 'MI-W4',
			component: <MIW4 />,
			visible: true,
			id: 'state_w4_michigan', // Assigned from the tabs list
			icon:
				loadedFormIds.miW4FormId === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'Food Report Agreements',
			component: <FoodReportAgreements />,
			visible: true,
			id: 'employee_food_report_agreements', // Assigned from the tabs list
			disabled: disableAll,
		},
		{
			label: 'Electronic W2 consent form',
			component: <W2Consent />,
			visible: true,
			id: 'electronic_w2_consent_form', // Assigned from the tabs list
			icon:
				loadedFormIds.electronicW2Consent === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'PCI Compliance Acknowledgment',
			component: <PCIComplianceForm />,
			visible: true,
			id: 'pci_compliance_security_acceptable_user_agreements', // Assigned from the tabs list
			disabled: disableAll,
		},
		{
			label: 'Direct Deposit Form',
			component: <DirectDepositForm />,
			visible: true,
			id: 'direct_deposit', // Assigned from the tabs list
			icon:
				loadedFormIds.directDepositId === undefined ? null : (
					<CheckCircle fontSize="small" color="success" />
				),
			disabled: disableAll,
		},
		{
			label: 'Full / Variable Time Form',
			component: <FTEForm />,
			visible: true,
			id: 'fte_form', // Assigned from the tabs list
			disabled: disableAll,
		},
		{
			label: 'I Understand Form',
			component: <IUnderstandForm />,
			visible: true,
			id: 'i_understand_form', // Assigned from the tabs list
			disabled: disableAll,
		},
	].filter((tab) => tab.visible !== false);
};

export function CompanyRegistrationStepper() {
	const [employeeSignatureFile, setEmployeeSignatureFile] = useState<File | null>(null);
	const [translatorSignatureFile, setTranslatorSignatureFile] = useState<File | null>(null);
	const [employerSignatureFile, setEmployerSignatureFile] = useState<File | null>(null);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [enableHumanResource, setEnableHumanResource] = useState(true);

	const isAbove765 = useMediaQuery('(min-width:765px)');

	const { formik, isEmployer, selectedEmployee, loadedFormIds } = useDocumentContext();

	const { workplace } = useWorkplacesContext();

	useEmployeeFormData();

	const handleFormTabChange = (event: SyntheticEvent, newValue: number) => {
		setActiveTabIndex(newValue);
	};

	const hrDetails = useCachedQueryData<DocsHRResponse>('employee-form/hr');

	const tabs = useMemo(
		() => {
			const tabsToGenerate = generateTabs(
				formik.values,
				isEmployer,
				Boolean(selectedEmployee),
				hrDetails,       
				loadedFormIds,
				enableHumanResource,
				setEnableHumanResource,
				setActiveTabIndex,
				employeeSignatureFile,
				setEmployeeSignatureFile,
				translatorSignatureFile,
				setTranslatorSignatureFile,
				employerSignatureFile,
				setEmployerSignatureFile
			);

			return tabsToGenerate.filter((tab) => {
				if (!tab.id) return true;

				return workplace.approved_forms.includes(tab.id);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			formik.values?.translator_assisted,
			formik.values?.employee_rehire,
			selectedEmployee,
			hrDetails,
			loadedFormIds,
			enableHumanResource,
			setActiveTabIndex,
			employeeSignatureFile,
			setEmployeeSignatureFile,
			translatorSignatureFile,
			setTranslatorSignatureFile,
			employerSignatureFile,
			setEmployerSignatureFile
		]
	);

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				flexDirection: isAbove765 ? 'row' : 'column',
				overflowY: 'auto',
				maxHeight: '75vh',
			}}
		>
			<Tabs
				variant="scrollable"
				scrollButtons={false}
				orientation={isAbove765 ? 'vertical' : 'horizontal'}
				value={activeTabIndex}
				textColor="secondary"
				indicatorColor="secondary"
				onChange={handleFormTabChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider', paddingLeft: '20px' }}
			>
				{tabs.map((tab, index) => (
					<Tab
						key={index}
						disabled={tab.disabled}
						label={
							<Box display="flex" flexDirection="column" position="relative" textAlign="left">
								<Box display="flex" alignItems="center" gap="8px">
									{tab.icon || null}
									{tab.label}
								</Box>
								{tab.helperText && tab.disabled && (
									<Typography fontSize="0.75rem" variant="subtitle1" sx={{ textTransform: 'none' }}>
										<strong>ⓘ</strong> {tab.helperText}
									</Typography>
								)}
							</Box>
						}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>

			<Suspense
				fallback={
					<TabPanel
						value={100}
						index={100}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<CircularProgress />
					</TabPanel>
				}
			>
				{tabs.map((tab, index) => (
					<TabPanel key={index} value={activeTabIndex} index={index} sx={{ position: 'relative' }}>
						{tab.component}
					</TabPanel>
				))}
			</Suspense>
		</Box>
	);
}
