import React from 'react';
import './MobilePage.style.scss';
import {
	Eventcalendar,
	setOptions,
	MbscEventcalendarView,
	MbscCalendarEvent,
	CalendarNav,
	CalendarPrev,
	CalendarToday,
	CalendarNext,
	MbscCalendarEventData,
	momentTimezone,
} from '@mobiscroll/react';
import { useQueryClient } from 'react-query';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';

import wavingHand from 'assets/icons/WavingHand.svg';
import trade from 'assets/icons/Trade.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import cover from 'assets/icons/Cover.svg';
import statusPublished from 'assets/icons/StatusPublished.svg';
import statusUnpublished from 'assets/icons/StatusUnpublished.svg';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Icons from 'styles/icons.module.scss';

import momentTZ from 'moment-timezone';

// setup Mobiscroll Moment plugin
momentTimezone.moment = momentTZ;

interface IMobilePage {
	openEditShiftModal: (args: any) => void;
	updateTimeRange: (firstday: Date, lastday: Date) => void;
	allSchedules: MbscCalendarEvent[];
	allEvents: MbscCalendarEvent[];
	setCalendarType: (args: 'schedule' | 'request' | 'event') => void;
	calendarType: string;
	openRequestsModal: (args: any) => void;
}

setOptions({
	theme: 'ios',
	themeVariant: 'light',
});

const MobilePage: React.FC<IMobilePage> = ({
	openEditShiftModal,
	allSchedules,
	allEvents,
	updateTimeRange,
	calendarType,
	setCalendarType,
	openRequestsModal,
}): JSX.Element => {
	const queryClient = useQueryClient();

	const { workplaceId, timezone } = useWorkplacesContext();

	const workplacesData = queryClient.getQueryData<IWorkplaces>(['workplaces']);

	const view = React.useMemo<MbscEventcalendarView>(() => {
		return {
			calendar: { type: 'week' },
			agenda: { type: 'day' },
		};
	}, []);

	/* const changeWorkplaceHandler = (id: number) => {
        setWorkplaceIDHandler(id);
    }; */

	const renderMyHeader = () => {
		return (
			<>
				<div className="customHeader">
					<div className="customHeaderTop">
						<CalendarNav className="md-work-week-nav" />

						<div>
							<CalendarPrev className="md-work-week-prev" />
							<CalendarToday className="md-work-week-today" />
							<CalendarNext className="md-work-week-next" />
						</div>
					</div>
					<div className="md-work-week-picker">
						<div className="toggleSchdule">
							<button
								className={`${calendarType === 'schedule' ? 'active' : null}`}
								onClick={() => setCalendarType('schedule')}
							>
								Schedule
							</button>
							<button
								className={`${calendarType === 'request' ? 'active' : null}`}
								onClick={() => setCalendarType('request')}
							>
								Requests
							</button>
							<button
								className={`${calendarType === 'event' ? 'active' : null}`}
								onClick={() => setCalendarType('event')}
							>
								Events
							</button>
						</div>
					</div>
					<div className="customHeaderBottom">
						<span className="WorkplaceTitle">
							Workplace:
							<span className="WorkplaceName">
								{workplacesData?.items?.map(({ workplace }) => workplaceId === workplace.id && workplace.name)}
							</span>
						</span>
					</div>
				</div>
			</>
		);
	};

	const renderEventContent = React.useCallback<(data: MbscCalendarEventData) => any>(
		(data: MbscCalendarEventData) => {
			const original = data.original!;
			return (
				<div>
					<div className={`mobileCustomEvent ${data.title === 'Open shift' ? 'mobileOpenShift' : null}`}>
						<div className="mobileShift">
							<p>{original.role ?? 'Event'}</p>
							<h4>{original?.title}</h4>
						</div>
						<div>
							{!original.scheduled ? (
								// hardcoded shift type 4 = available shift
								original?.shift_type?.id === 4 ? (
									<SvgIcon spriteUrl={wavingHand} className={Icons.shiftIcon} />
								) : original?.shift_type?.id === 2 ? (
									<SvgIcon spriteUrl={timeOff} className={Icons.shiftIcon} />
								) : //hardcoded shift type 6 = trade request shift
								original?.shift_type?.id === 6 ? (
									<SvgIcon spriteUrl={trade} className={Icons.shiftIcon} />
								) : original?.shift_type?.id === 3 ? (
									<SvgIcon spriteUrl={shiftPick} className={Icons.shiftIcon} />
								) : original?.shift_type?.id === 5 ? (
									<SvgIcon spriteUrl={cover} className={Icons.shiftIcon} />
								) : null
							) : original.overlap ? (
								<SvgIcon spriteUrl={statusUnpublished} className={Icons.shiftIcon} />
							) : original.published ? (
								<SvgIcon spriteUrl={statusPublished} className={Icons.shiftIcon} />
							) : null}
						</div>
					</div>
				</div>
			);
		},
		[calendarType]
	);

	const orderMyEvents = React.useCallback(
		(firstEvent, secondEvent) => {
			const firstEventUserLoggedIn = firstEvent.owner; // true/false
			const secondEventUserLoggedIn = secondEvent.owner; // true/false
			let roleCompare = undefined;
			if (firstEvent?.role && secondEvent?.role) {
				roleCompare = firstEvent?.role.localeCompare(secondEvent?.role); // 1/0/-1
			}
			const firstEventStart = new Date(firstEvent.start).getTime(); // number
			const secondEventStart = new Date(secondEvent.start).getTime(); // number
			//username
			//const firstEventUser = ...; // string
			//const secondEventUser = ...; // string
			const titleA = firstEvent?.title?.split(' ');
			const titleB = secondEvent?.title?.split(' ');
			if (firstEventUserLoggedIn !== secondEventUserLoggedIn) {
				return firstEventUserLoggedIn ? -1 : 1;
			} else {
				if (roleCompare !== 0) {
					return roleCompare;
				} else {
					if (firstEventStart !== secondEventStart) {
						return firstEventStart - secondEventStart;
					} else {
						return titleB[titleB.length - 1].localeCompare(titleA[titleA.length - 1]);
					}
				}
			}
		},
		[allSchedules]
	);

	return (
		<>
			<Eventcalendar
				theme="ios"
				themeVariant="light"
				dataTimezone={timezone}
				displayTimezone={timezone}
				timezonePlugin={momentTimezone}
				data={calendarType === 'event' ? allEvents : allSchedules}
				view={view}
				eventOrder={orderMyEvents}
				renderHeader={renderMyHeader}
				renderEventContent={renderEventContent}
				onEventClick={({ event }) => {
					if (calendarType === 'request') {
						openRequestsModal(event);
					} else {
						if (event.role) {
							openEditShiftModal(event);
						}
					}
				}}
				onPageChange={(event) => {
					/* Your custom event handler goes here */

					updateTimeRange(event.firstDay, event.lastDay);
				}}
			/>
		</>
	);
};

export default MobilePage;
