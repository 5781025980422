import { Participant } from 'core/API/broadcast';
import { Role } from 'core/API/roles';
import React, { createContext, useContext, useState } from 'react';


export interface BroadcastGroup {
  id: number;
  group_name: string;
  participants: Participant[];
  organization_broadcast: boolean;
  division_broadcast: boolean;
  operating_unit_broadcast: boolean;
  workplace_broadcast: boolean;
  role: Role[];
  workplace: { id: number; name: string }[];
  division: { id: number; name: string }[];
  operating_unit: { id: number; name: string }[];
  organization: { id: number; name: string }[];
  number_of_messages?: number;
}

interface BroadcastGroupContextProps {
  broadcastGroup: Partial<BroadcastGroup>;
  setBroadcastGroup: React.Dispatch<React.SetStateAction<Partial<BroadcastGroup>>>;
  resetBroadcastGroup: () => void;
}

const BroadcastGroupContext = createContext<BroadcastGroupContextProps | undefined>(undefined);

const initialBroadcastGroupState: Partial<BroadcastGroup> = {
  group_name: '',
  participants: [],
  organization_broadcast: false,
  division_broadcast: false,
  operating_unit_broadcast: false,
  workplace_broadcast: false,
  role: [],
  workplace: [],
  division: [],
  operating_unit: [],
  organization: [],
};

export const BroadcastGroupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [broadcastGroup, setBroadcastGroup] = useState<Partial<BroadcastGroup>>(initialBroadcastGroupState);

  const resetBroadcastGroup = () => {
    setBroadcastGroup(initialBroadcastGroupState);
  };

  return (
    <BroadcastGroupContext.Provider value={{ broadcastGroup, setBroadcastGroup, resetBroadcastGroup }}>
      {children}
    </BroadcastGroupContext.Provider>
  );
};

export const useBroadcastGroup = () => {
  const context = useContext(BroadcastGroupContext);
  if (!context) {
    throw new Error('useBroadcastGroup must be used within a BroadcastGroupProvider');
  }
  return context;
};
