import moment from 'moment';
// import 'moment-timezone/data/en.js'; // Load English timezone data (adjust based on your needs)

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const fmt = 'MM/DD/YYYY HH:mm:ss';

export default function getTimeWithTimezone(
	firstday: Date,
	lastday: Date,
	timezone: string,
	format: string = DATE_FORMAT
): string[] {
	const newFirstDay = moment(firstday).format(fmt);
	const newStart_at = moment.tz(newFirstDay, fmt, timezone).utc().format(format);

	const newLastDay = moment(lastday).format(fmt);
	const newEnd_at = moment.tz(newLastDay, fmt, timezone).utc().format(format);

	return [newStart_at, newEnd_at];
}

export const formatTimeWithtimezone = (time: any, timezone: string, format: string): string => {
	// Check if timezone is provided, otherwise assume UTC
	if (timezone) {
		const start = moment.tz(time.split('+')[0], 'UTC').tz(timezone).format(format);
		return start;
	} else {
		// Handle case where timezone is undefined (optional)
		console.warn('Timezone not provided, assuming UTC');
		const start = moment.utc(time).format(format);
		return start;
	}
};
