import { Box, Stack } from '@mui/material';
import { useWorkplaces } from 'core/hooks/broadcast';
import { AllToggle } from 'module/laborsaber/components/Chat/components/selects/components';
import { selectStyles } from 'module/laborsaber/components/Chat/components/selects/styles';
import { FC, useEffect, useMemo, useRef } from 'react';
import Select, { MultiValue } from 'react-select';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';

interface SelectOption {
	value: number;
	label: string;
}

const WorkplaceAutocomplete: FC = () => {
	const textFieldRef = useRef<HTMLInputElement>(null);

	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();

	// Fetch workplaces data based on selected operating units
	const {
		data: workplaces,
		isLoading,
		isFetching,
	} = useWorkplaces(broadcastGroup.operating_unit.map((ou) => ou.id));

	// Update workplace selection when new workplaces data is available
	useEffect(() => {
		if (
			workplaces &&
			broadcastGroup.workplace &&
			workplaces.items &&
			// Check if there are differences between current workplaces and those in the broadcast group
			broadcastGroup.workplace.some(
				(workplace) => !workplaces.items.some((item) => item.id === workplace.id)
			)
		) {
			const updatedWorkplaces = broadcastGroup.workplace.map(
				(workplace) => workplaces.items.find((r) => r.id === workplace.id) || workplace
			);
			console.log('Updating selected workplaces:', updatedWorkplaces);

			setBroadcastGroup((prev) => ({
				...prev,
				workplace: updatedWorkplaces,
			}));
		}
	}, [workplaces, broadcastGroup.workplace, setBroadcastGroup]);

	// Handle workplace selection changes
	const handleWorkplaceChange = (selectedOptions: MultiValue<SelectOption>) => {
		const newWorkplaces = selectedOptions.map((option) => ({
			id: option.value,
			name: option.label,
		}));
		console.log('Selected new workplaces:', newWorkplaces);
		setBroadcastGroup((prev) => ({
			...prev,
			workplace: newWorkplaces,
		}));

		// Clear the input field after selection
		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	// Handle the "Select All" or "Deselect All" action
	const onAllToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			console.log('Selecting all workplaces:', workplaces?.items);
			setBroadcastGroup((prev) => ({
				...prev,
				workplace: workplaces?.items || [],
			}));
		} else {
			console.log('Deselecting all workplaces');
			setBroadcastGroup((prev) => ({
				...prev,
				workplace: [],
			}));
		}
	};

	// Create options for the Select dropdown
	const filteredOptions = useMemo<SelectOption[]>(() => {
		if (!workplaces?.items) {
			console.log('No workplaces data available');
			return [];
		}

		// Map and filter workplaces to exclude already selected ones
		const options = workplaces.items
			.map((workplace) => ({
				value: workplace.id,
				label: workplace.name,
			}))
			.sort((a, b) => {
				return a.label.localeCompare(b.label);
			})
			.filter(
				(option) =>
					!broadcastGroup.workplace?.some(
						(selectedWorkplace) => selectedWorkplace.id === option.value
					)
			);

		console.log('Filtered options for select:', options);
		return options;
	}, [workplaces, broadcastGroup.workplace]);

	// Get currently selected workplaces for the Select component
	const value = useMemo<SelectOption[]>(() => {
		const selectedValues =
			broadcastGroup.workplace?.map((workplace) => ({
				value: workplace.id,
				label: workplace.name,
			})) || [];

		console.log('Current selected workplaces:', selectedValues);
		return selectedValues;
	}, [broadcastGroup.workplace]);

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllToggle isLoading={isLoading || isFetching} onAllToggle={onAllToggle} />
			<Box sx={{ width: '100%' }}>
				<Select
					isMulti
					value={value}
					isLoading={isLoading || isFetching}
					onChange={handleWorkplaceChange}
					options={filteredOptions}
					placeholder="Select Workplaces"
					classNamePrefix="select"
					isClearable
					styles={selectStyles}
				/>
			</Box>
		</Stack>
	);
};

export default WorkplaceAutocomplete;
