import Typography from '@mui/joy/Typography';
import { Box, Button } from '@mui/material';
import { DelayButton } from 'module/documents/components/components';
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureBox: React.FC<{ existingPath?: string; onSubmit: (file: File) => void }> = ({
	onSubmit,
	existingPath,
}) => {
	const sigCanvasRef = useRef<SignatureCanvas>(null);

	const handleClear = () => {
		sigCanvasRef.current?.clear();
	};

	const handleSubmit = async () => {
		if (sigCanvasRef.current) {
			// Get the base64 data URL of the signature
			const trimmedDataURL = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');

			// Convert the base64 data URL to a File object
			const file = dataURLToFile(trimmedDataURL, 'signature.png');

			// Trigger the submit callback with the file
			onSubmit(file);
		}
	};

	// Function to convert base64 data URL to File
	const dataURLToFile = (dataUrl: string, fileName: string): File => {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], fileName, { type: mime });
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			justifyContent="center"
			pb="2rem"
		>
			<Typography level="title-sm">
				{existingPath || existingPath === '' ? 'Employee signature' : 'Sign Below'}
			</Typography>
			<Box alignSelf="flex-start">
				{existingPath ? (
					<Box width="200px" height="100px" margin="16px">
						<img
							src={process.env.REACT_APP_URL + existingPath}
							style={{
								width: 200,
								height: 100,
								// filter: 'blur(18px)',
							}}
						/>
					</Box>
				) : (
					<SignatureCanvas
						ref={sigCanvasRef}
						penColor="black"
						canvasProps={{
							width: 400,
							height: 200,
							className: 'sigCanvas',
							style: { border: '1px solid black' },
						}}
					/>
				)}
			</Box>
			<Box mt={2} visibility={existingPath ? 'hidden' : 'visible'}>
				<DelayButton variant="contained" onClick={handleSubmit}>
					Confirm
				</DelayButton>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleClear}
					style={{ marginLeft: 10 }}
				>
					Clear
				</Button>
			</Box>
		</Box>
	);
};

export default SignatureBox;
