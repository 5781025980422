import { fileUpload } from 'core/API/events';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { ClipLoader } from 'react-spinners';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import AddEventModalStyle from './AddEventModal.module.scss';

import Forms from 'styles/forms.module.scss';
import Icons from 'styles/icons.module.scss';
import bin from 'assets/icons/Bin.svg';
import { useCallback, useState } from 'react';

interface iUploadFile {
	setFieldValue: any;
	fieldName: string;
	value: string;
}

export default function UploadFile({ setFieldValue, fieldName, value }: iUploadFile): JSX.Element {
	const [err, setErr] = useState(false);
	const { mutate: uploadDocument, isLoading } = useMutation(fileUpload, {
		onSuccess: (data) => {
			console.log('Success', data.path);
			setFieldValue(fieldName, data.path);
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	const onDropMenu = useCallback((acceptedFiles, rejectedFiles) => {
		if (rejectedFiles.length) {
			setErr(true);
		} else {
			const fd = new FormData();
			fd.append('file', acceptedFiles[0]);
			uploadDocument(fd);
		}
	}, []);

	const {
		getRootProps: getRootPropsMenu,
		getInputProps: getInputPropsMenu,
		isDragActive: isDragActiveMenu,
	} = useDropzone({
		multiple: false,
		onDrop: onDropMenu,
		accept: {
			'.pdf': ['application/pdf'],
			'.doc': ['application/msword'],
			'.docx': ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
		},
	});

	return isLoading ? (
		<ClipLoader color="#841D80" loading={true} size={30} />
	) : value ? (
		<div className={AddEventModalStyle.uploadedFileNameRow}>
			<a href={`${process.env.REACT_APP_URL}${value}`} target="_blank" rel="noopener noreferrer">
				{value}
			</a>
			<SvgIcon
				spriteUrl={bin}
				className={Icons.navIcon}
				onClick={() => {
					setFieldValue(fieldName, '');
				}}
			/>
		</div>
	) : (
		<div
			{...getRootPropsMenu({
				className: AddEventModalStyle.fileUploadDropzone,
			})}
		>
			<input {...getInputPropsMenu()} />
			{isDragActiveMenu ? (
				<label className={Forms.formLabel}>Drop the files here ...</label>
			) : (
				<label className={Forms.formLabel}>Drag & Drop or Browse</label>
			)}
			{err ? <div className={AddEventModalStyle.uploadFileError}>Supported files .pdf, .doc, .docx</div> : null}
		</div>
	);
}
