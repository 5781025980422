import { FC, useState } from 'react';

import { useQuery } from 'react-query';
import moment from 'moment';

import Loading from 'core/components/Loading/Loading';
import { GetOneShift } from 'core/API/shifts';

// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

interface IShiftPickModal {
	closeModal: () => void;
	requestData: any;
}

const ShiftPickModal: FC<IShiftPickModal> = (props) => {
	const [shiftDate, setShiftDate] = useState<string>('');

	const { data: requestData, isLoading: shiftLoading } = useQuery(
		['shift-pick', props?.requestData?.shift_id],
		() => GetOneShift(props.requestData.shift_id!),
		{
			onSuccess: (data) => {
				const shiftDateString = `${moment.utc(data.start_at).format('dddd, D MMMM YYYY, H:mm A')} - ${moment
					.utc(data.end_at)
					.format('H:mm A')}`;

				setShiftDate(shiftDateString);
			},
		}
	);

	return shiftLoading ? (
		<Loading />
	) : (
		<>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>Employee who made request</p>
				<p className={Forms.formLockedText}>
					{requestData?.user.first_name} {requestData?.user.last_name}
				</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>When</p>
				<p className={Forms.formLockedText}>{shiftDate}</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>Status</p>
				<p className={Forms.formLockedText}>Waiting for approval</p>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Leave a note for employee"
					maxLength={160}
					disabled
				></textarea>
			</div>
			<div className={Forms.formFooterSplit}>
				<button className={Buttons.btnSuccess}>Approve</button>
				<button className={Buttons.btnError} onClick={props.closeModal}>
					Deny
				</button>
			</div>
		</>
	);
};

export default ShiftPickModal;
