import { MbscEventcalendarView } from '@mobiscroll/react';
import useQueryParams from './useQueryParams';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { getDayNumber } from 'shared/utils/dates';

type CalendarView = 'week' | 'day' | 'workweek';

export const getCalendarConfig = (
	view: CalendarView,
	firstDayOfWeek: number = 1
): MbscEventcalendarView => {
	switch (view) {
		case 'day': {
			return {
				timeline: {
					type: 'day',
					timeCellStep: 60,
					timeLabelStep: 60,
				},
			};
		}
		case 'workweek': {
			return {
				timeline: {
					type: 'week',
					eventList: true,
					startDay: firstDayOfWeek,
					endDay: firstDayOfWeek - 1 || 0,
				},
			};
		}
		case 'week':
		default: {
			return {
				timeline: {
					type: 'week',
					eventList: true,
					startDay: firstDayOfWeek,
					endDay: firstDayOfWeek - 1 || 0,
					timeCellStep: 1440,
					timeLabelStep: 1440,
				},
			};
		}
	}
};

export const useCalendarView = () => {
	const { query, change } = useQueryParams();
	const { workplace } = useWorkplacesContext();

	const calendarView = (query.time || 'day') as CalendarView;

	const setCalendarView = (view: CalendarView) => {
		change({ time: view });
	};

	const calendarConfig = getCalendarConfig(
		calendarView,
		getDayNumber(workplace.first_day_of_the_week)
	);

	return { calendarView, calendarConfig, setCalendarView };
};
