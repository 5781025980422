import { Box } from '@mui/material';
import { FormGroupSection } from 'module/documents/components/components';
import { WorkspaceUserAutocomplete } from 'module/documents/tabs/HumanResource/components';
import gamemasterLogo from 'assets/icons/gamemaster_Onboarding_WordIcon.png';
import { FC } from 'react';

// Define the prop types
interface SelectedEmployeeTabProps {
	enableHumanResource: boolean;
	setEnableHumanResource: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  }

  const SelectedEmployeeTab: FC<SelectedEmployeeTabProps> = ({
	setEnableHumanResource,
	setActiveTabIndex,
  }) => {
  
	return (
	  <Box display="flex" flexDirection="column" gap={2}>
<Box 
  sx={{ 
    display: 'flex', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    marginBottom: '-20px', // Negative margin to pull the form closer
	marginTop: '-30px'
  }}
>
  <img 
    src={gamemasterLogo} 
    alt="GameMaster Onboarding" 
    style={{ height: '90px', width: 'auto', margin: '0' }} // Reduced height
  />
</Box>
		<FormGroupSection>
		<WorkspaceUserAutocomplete 
          setEnableHumanResource={setEnableHumanResource}
		  setActiveTabIndex={setActiveTabIndex}
        />
		</FormGroupSection>
	  </Box>
	);
  };

export default SelectedEmployeeTab;
