import {
	Eventcalendar,
	MbscCalendarEvent,
	MbscEventClickEvent,
	momentTimezone,
} from '@mobiscroll/react';
import momentTZ from 'moment-timezone';
import { useQueryClient } from 'react-query';

import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';

import { useCalendarView } from 'core/hooks/useCalendarView';
import { renderEventButton, EventHeader, EventResource } from './DesktopPage.components';
import './DesktopPage.style.scss';
import { useUserResources } from './DesktopPage.utils';

momentTimezone.moment = momentTZ;

interface IDesktopPage {
	events: MbscCalendarEvent[];
	onScheduleEventClick: (data: MbscEventClickEvent) => void;
	updateTimeRange: (firstday: Date, lastday: Date) => void;
	handleTradePickingShift: (args: any) => void;
	tradeMyShift: MbscCalendarEvent | null;
}

export default function DesktopPage(props: IDesktopPage): JSX.Element {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();

	const { workplaceId, firstDayOfWeek, timezone, isTradeSelected } = useWorkplacesContext();

	const { calendarConfig } = useCalendarView();

	// *********************************************
	const workplacesData = queryClient.getQueryData<IWorkplaces>(['workplaces']);
	const workplaces = workplacesData?.items?.map(
		({ workplace }) => workplaceId === workplace.id && workplace.name
	);
	// *********************************************

	const resources = useUserResources(workplaceId, props.events);

	return (
		<Eventcalendar
			theme="ios"
			themeVariant="light"
			dataTimezone={timezone}
			displayTimezone={timezone}
			timezonePlugin={momentTimezone}
			clickToCreate={false}
			dragToCreate={false}
			view={calendarConfig}
			data={props.events}
			renderScheduleEvent={(data) => renderEventButton(data, isTradeSelected)}
			resources={resources}
			renderHeader={() => <EventHeader workplaces={workplaces} />}
			renderResource={EventResource}
			dragTimeStep={30}
			cssClass="md-switching-timeline-view-cont md-timeline-height"
			timeFormat="HH:mm"
			externalDrop={true}
			onEventClick={(e) => {
				if (isTradeSelected) {
					if (!props.tradeMyShift) {
						props.handleTradePickingShift(e.event);
					} else {
						resources.forEach((resource) => {
							if (resource.id === e.event.resource) {
								if (
									resource.role.filter((value: any) => value.name === props?.tradeMyShift?.role)
										.length
								) {
									props.handleTradePickingShift(e.event);
								} else {
									showToast('error', 'User does not have role for this shift!');
								}
							}
						});
					}

					return;
				}
				props.onScheduleEventClick(e);
			}}
			onPageChange={(event) => {
				props.updateTimeRange(event.firstDay, event.lastDay);
			}}
		/>
	);
}
