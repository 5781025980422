import { Box, Stack } from '@mui/material';
import { useRoles } from 'core/hooks/broadcast';

import {
	AllToggle,
	useAutocomplete,
} from 'module/laborsaber/components/Chat/components/selects/components';
import { selectStyles } from 'module/laborsaber/components/Chat/components/selects/styles';
import { FC } from 'react';
import Select from 'react-select';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { Role } from 'core/API/roles';

const RolesAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const { data: roles, isLoading, isFetching } = useRoles();

	const {
		filteredOptions,
		value,
		handleChange,
		handleSelectAllToggle,
		isLoading: selectIsLoading,
	} = useAutocomplete<Role>({
		data: roles?.items,
		selectedItems: broadcastGroup.role,
		setSelectedItems: (newRoles) => setBroadcastGroup((prev) => ({ ...prev, role: newRoles })),
		isLoading,
		isFetching,
	});

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllToggle isLoading={selectIsLoading} onAllToggle={handleSelectAllToggle} />
			<Box sx={{ width: '100%' }}>
				<Select
					isMulti
					value={value}
					isLoading={selectIsLoading}
					onChange={handleChange}
					options={filteredOptions}
					placeholder="Select Roles"
					classNamePrefix="select"
					isClearable
					styles={selectStyles}
				/>
			</Box>
		</Stack>
	);
};

export default RolesAutocomplete;
