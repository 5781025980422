export const selectStyles = {
	control: (baseStyles, state) => ({
		...baseStyles,
		borderColor: state.isFocused ? 'grey' : '',
	}),
	multiValue: (baseStyles) => ({
		...baseStyles,
		backgroundColor: '#2196f3',
		color: 'white',
		borderRadius: '12px',
		padding: '2px 4px',
		fontSize: '1rem',
	}),
	multiValueLabel: (baseStyles) => ({
		...baseStyles,
		color: 'white',
	}),
};
