import { IUsers } from 'core/interfaces/userInterface';
import { apiService } from 'core/services/apiService';
import { UserMe } from 'types';
import { Participant } from './broadcast';

const GetUsersList = async (workplaceId: number): Promise<IUsers[]> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`users-workplaces/${workplaceId}?active=1&deleted=0`)
	);

	return Object.values(data);
};

const GetParticipantsList = async (workplaceId: number): Promise<Participant[]> => {
	const { ...data } = apiService.responseHandler(
		await apiService.get(`users-workplaces/${workplaceId}?active=1&deleted=0`)
	);

	const participants: Array<Participant> = Object.values(data as Array<IUsers>).map((user) => ({
		id: user.user.id,
		first_name: user.user.first_name.trim(),
		last_name: user.user.last_name.trim(),
		email: user.user.email || '',
	}));

	return participants;
};

const getUser = async (userId: number): Promise<UserMe> => {
	const { data } = apiService.responseHandler(await apiService.get(`users/${userId}`));
	return data;
};

export { GetParticipantsList, getUser, GetUsersList };
