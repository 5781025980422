import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';

const SelectedItemsDisplay: React.FC = () => {
	const { broadcastGroup } = useBroadcastGroup();

	const itemsToShow = useMemo(() => {
		const selectedWorkplaces = broadcastGroup.workplace?.length || 0;
		const selectedDivisions = broadcastGroup.division?.length || 0;
		const selectedOperatingUnits = broadcastGroup.operating_unit?.length || 0;
		const selectedOrganizations = broadcastGroup.organization?.length || 0;
		const selectedRoles = broadcastGroup.role?.length || 0;
		const selectedParticipants = broadcastGroup.participants?.length || 0;

		const displayItems = [
			{ label: 'roles', count: selectedRoles },
			{ label: 'workplaces', count: selectedWorkplaces },
			{ label: 'divisions', count: selectedDivisions },
			{ label: 'operating units', count: selectedOperatingUnits },
			{ label: 'organizations', count: selectedOrganizations },
			{ label: 'participants', count: selectedParticipants },
		];

		return displayItems.filter((item) => item.count > 0);
	}, [broadcastGroup]);

	return (
		<Box sx={{ marginBottom: 2 }}>
			{itemsToShow.length > 0 ? (
				<Typography variant="subtitle1" component="div">
					Selected:{' '}
					{itemsToShow.map((item, index) => (
						<span key={index}>
							{item.count} {item.label}
							{index < itemsToShow.length - 1 && ', '}
						</span>
					))}
				</Typography>
			) : (
				<Typography variant="subtitle1" component="div">
					No items selected.
				</Typography>
			)}
		</Box>
	);
};

export default SelectedItemsDisplay;
