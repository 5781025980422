export interface test {}

export interface IPaginatedResponse<T> {
	total_results: number;
	total_pages: number;
	current_page: number;
	limit: number;
	offset: number;
	order_by: string;
	direction: string;
	items: Array<T>;
}

export enum BroadcastRoleType {
	BROADCAST_ORGANIZATION = 'BROADCAST_ORGANIZATION',
	BROADCAST_DIVISION = 'BROADCAST_DIVISION',
	BROADCAST_OPERATING_UNIT = 'BROADCAST_OPERATING_UNIT',
	BROADCAST_WORKPLACE = 'BROADCAST_WORKPLACE',
}

// Define the data structure for the response
interface Tag {
	id: number;
	name: string;
	color: string;
}

export interface Person {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	email_confirmed: boolean;
	active: boolean;
}

interface ImportOrigin {
	id: number;
}

export interface UserMe {
	id: number;
	email: string;
	roles: string[];
	first_name: string;
	last_name: string;
	gender: string;
	active: boolean;
	login_token: string;
	password_reset_token: string;
	email_confirmed: boolean;
	tags: Tag[];
	person: Person;
	profile_picture: string;
	qr_code: string;
	pin: number;
	deleted: boolean;
	import_origin: ImportOrigin;
	import_id: string;
	parameter: string;
	phone: string;
}

export interface ErrorDetail {
	message: string;
	class: string;
	trace: TraceDetail[];
}

export interface TraceDetail {
	namespace: string;
	short_class: string;
	class: string;
	type: string;
	function: string;
	file: string;
	line: number;
	args: any[];
}

export interface DebugDetail {
	line: number;
	file: string;
	exception: ErrorDetail[];
}

export interface ApiErrorResponse {
	error: string;
	code: number;
	message: string;
	errors: string[];
	debug: DebugDetail;
}

export interface CommonGetParams {
	query?: string;
	orderBy?: string;
	direction?: 'ASC' | 'DESC';
	limit?: number;
	page?: number;
}
