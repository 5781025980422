import { GetEmployeePerformance } from 'core/API/laborsaber';
import Loading from 'core/components/Loading/Loading';
import { getBusinessWeek } from 'module/laborsaber/utils/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

// CSS modules
import moment from 'moment';
import Sections from '../sections.module.scss';

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 2,
});

const formatUserPerformance = (row) => {
	const price = parseFloat(row.price || 0);
	const tip = parseFloat(row.tip);
	const void_amount = parseFloat(row.void);

	const hours_worked = row.hours_worked;

	const sales_per_hour_raw = hours_worked ? price / hours_worked : 0;
	const sales_per_hour = sales_per_hour_raw ? formatter.format(sales_per_hour_raw) : '-';

	const discount_amount = parseFloat(row.discount_amount);

	const calculateTip = (tip / price) * 100;
	const calculateAmount = (void_amount / price) * 100;
	const calculateDiscount = (discount_amount / price) * 100;

	return {
		price,
		tip,
		hours_worked,
		sales_per_hour_raw,
		sales_per_hour,
		void_amount,
		discount_amount,
		tardiness: row.tardiness || '-',
		calculateTip,
		calculateAmount,
		calculateDiscount,
	};
};

const EmployeePerformance: FC = () => {
	const { workplaceId, timezone } = useWorkplacesContext();

	const { isLoading, data: empData } = useQuery('employeePerformance', () => {
		const time = moment.tz(timezone);

		const [firstBusinessDay, lastBusinessDay] = getBusinessWeek(time);

		return GetEmployeePerformance({
			workplaceId,
			formatedFirstDay: firstBusinessDay,
			formatedLastDay: lastBusinessDay,
		});
	});

	const [areFieldsVisible, setFieldsVisible] = useState(false);

	const employeePerformanceData = useMemo(() => {
		return (
			empData?.items
				?.map((row) => ({ ...row, ...formatUserPerformance(row) }))
				?.filter((row) => {
					if (areFieldsVisible) return true;

					return row.calculateDiscount !== 0;
				})
				.sort((a, b) => b.sales_per_hour_raw - a.sales_per_hour_raw) || []
		);
	}, [empData, areFieldsVisible]);

	return (
		<article
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'emplPerf',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{isLoading ? (
				<div>
					<Loading />
				</div>
			) : (
				<>
					<h4 style={{ paddingLeft: '16px' }}>Weekly Employee Performance</h4>
					<div style={{ paddingLeft: '16px', display: 'flex' }}>
						<input id="hidden_fields" type="checkbox" onChange={() => setFieldsVisible(!areFieldsVisible)} />
						<label htmlFor="hidden_fields" style={{ color: 'var(--rs-text-primary)', fontSize: '12px', padding: '2px 0px 0px 8px' }}>
							Show hidden fields
						</label>
					</div>
					<div style={{ display: 'flex', overflow: 'auto' }}>
						<div className={Sections.table}>
							<div className={Sections.tableHeader}>
								<span>Employee</span>
								<span>Total Sales</span>
								<span>Weekly Hours</span>
								<span>Sales Per Hour</span>
								<span>Tardiness</span>
								<span>Avg Tips %</span>
								<span>Void %</span>
								<span>Discounts %</span>
							</div>
							<div className={Sections.tableBody}>
								{!empData?.items?.length ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											minHeight: '300px',
										}}
									>
										No data
									</div>
								) : null}
								{employeePerformanceData.length === 0 ? (
									<div className={Sections.tableRow} style={{ color: 'var(--rs-text-primary)', fontSize: '14px' }}>
										<span>
											No data
										</span>
									</div>
								) : (
									employeePerformanceData.map((row) => {
										return (
											<div key={row.employee_id} className={Sections.tableRow}>
												<div>
													{row.first_name} {row.last_name}
												</div>
												<div>${row.price.toFixed(2)}</div>
												<div>{row.hours_worked}</div>
												<div>{row.sales_per_hour}</div>
												<div>{row.tardiness}</div>
												<div>{row.calculateTip.toFixed(2) || 0}%</div>
												<div>{row.calculateAmount.toFixed(2) || 0}%</div>
												<div>{row.calculateDiscount.toFixed(2) || 0}%</div>
											</div>
										);
									})
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</article>
	);
};

export default EmployeePerformance;
