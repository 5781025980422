import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import './style.scss';

import { LoginInput } from '../../../../core/components/styles/LoginInput';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAuth } from 'module/auth/context/AuthContext';
import { useEffect } from 'react';
import useModal from 'core/hooks/useModal';
import CenterRemodal from 'core/components/Modal/CenterModal';
import ResetPasswordModal from './ResetPasswordModal';

interface FormValues {
	email: string;
	password: string;
}

interface MyFormProps {
	initialEmail?: string;
	initialPassword?: string;
	submitClick: (data: FormValues) => void;
}

const InnerForm = (props: FormikProps<FormValues>) => {
	const { open: isModalOpen, openModal, closeModal } = useModal();

	const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting } = props;

	const auth = useAuth();

	useEffect(() => {
		if (isSubmitting) {
			setSubmitting(false);
		}
	}, [auth.error, isSubmitting, setSubmitting]);

	return (
		<>
			<div className="FormBox">
				<h3>Login</h3>
				<form onSubmit={handleSubmit}>
					<LoginInput
						placeholder="Email"
						hasError={touched.email && errors.email}
						errorMsg={errors.email}
						name="email"
						type="text"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.email}
					/>

					<LoginInput
						placeholder="Password"
						hasError={touched.password && errors.password}
						errorMsg={errors.password}
						name="password"
						type="password"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.password}
					/>

					{auth.error ? <p className="InputErrorBox">{auth.error}</p> : null}

					<button
						type="submit"
						disabled={isSubmitting || !!(errors.email && touched.email) || !!(errors.password && touched.password)}
					>
						{isSubmitting ? <ClipLoader color="#fff" loading={true} size={25} /> : 'Sign In'}
					</button>
					<p onClick={openModal}>Forgot your password?</p>
				</form>
			</div>

			{isModalOpen ? (
				<CenterRemodal
					title="Enter your email"
					close={closeModal}
					render={() => <ResetPasswordModal closeModal={closeModal} />}
				/>
			) : null}
		</>
	);
};

export const AuthForm = withFormik<MyFormProps, FormValues>({
	mapPropsToValues: (props) => ({
		email: props.initialEmail || '',
		// email: props.initialEmail || 'mario.rozic@q.agency',
		// email: props.initialEmail || 'pibedag867@mnqlm.com',
		password: props.initialPassword || '',
		// props.initialPassword || 'e885cb5a1',
		// props.initialPassword || '31a3cee8fc373bcabf65e7f5dee31859f95c68a2',
	}),

	validationSchema: Yup.object().shape({
		email: Yup.string().email('Email not valid').required('Email is required'),
		password: Yup.string().required('Password is required'),
	}),

	handleSubmit({ email, password }: FormValues, { props }) {
		props.submitClick({ email, password });
	},
})(InnerForm);
