import { FC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { ROUTES } from 'module/navigation/enums/routes.enums';

import { useAuth } from 'module/auth/context/AuthContext';

import closeIcon from 'assets/icons/Close.svg';

import Portal from 'core/components/Modal/Portal';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Menu from './MobileMenu.module.scss';

interface IMobileMenu {
	close: () => void;
}

const MobileMenu: FC<IMobileMenu> = ({ close }) => {
	const auth = useAuth();
	const { push, location } = useHistory();

	return (
		<Portal>
			<div className={Menu.menuRoot}>
				<div className={Menu.menuWrapper}>
					<div className={Menu.menuHeader}>
						<SvgIcon spriteUrl={closeIcon} className={Menu.menuIcon} onClick={close} />
					</div>
					<div className={Menu.menuContent}>
						<nav className={Menu.menuNav}>
							<ul className={Menu.menuNavList} onClick={close}>
								<li className={Menu.menuNavListItem}>
									<NavLink
										exact
										to={auth.isManager ? ROUTES.MANAGER : ROUTES.EMPLOYEE}
										className={Menu.menuNavListLink}
										activeClassName={Menu.menuNavListLinkActive}
									>
										Schedule
									</NavLink>
								</li>
								{auth.isManager && (
									<>
										<li className={Menu.menuNavListItem}>
											<NavLink
												to={ROUTES.LABORSABER}
												className={Menu.menuNavListLink}
												activeClassName={Menu.menuNavListLinkActive}
											>
												Laborsaber
											</NavLink>
										</li>
										<li className={Menu.menuNavListItem}>
											<NavLink
												to={ROUTES.SITUATION_ROOM}
												className={Menu.menuNavListLink}
												activeClassName={Menu.menuNavListLinkActive}
											>
												Situation room
											</NavLink>
										</li>
									</>
								)}
							</ul>
							<ul className={Menu.menuNavList} onClick={close}>
								<li className={Menu.menuNavListItem}>
									<a
										className={Menu.menuNavListLink}
										onClick={() =>
											push({
												pathname: ROUTES.LOGIN,
												state: location.pathname,
											})
										}
									>
										Switch workplace
									</a>
								</li>
								<li className={Menu.menuNavListItem}>
									<a href="#" className={Menu.menuNavListLink} onClick={auth.logout}>
										Log out
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className={Menu.menuBackdrop} onClick={close}></div>
			</div>
		</Portal>
	);
};

export default MobileMenu;
