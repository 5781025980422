import { PDFDocument, rgb } from "pdf-lib";
import moment from "moment";
import { getTokenFromLocalStorage } from 'core/services/axiosInterceptors';

/**
 * Type definitions for field mappings
 */
type TextFieldMappings = Record<string, string | Date | undefined>;
type DropdownFieldMappings = Record<string, string | undefined>;
type CheckboxMappings = string[];

/**
 * Formats date to MM/DD/YYYY
 */
const formatDate = (date: Date | string | undefined): string => {
    return date ? moment(date).format("MM/DD/YYYY") : "";
};

type SignatureInfo = {
    file?: File; // when user signs in canvas
    imageUrl?: string; // when signature is already uploaded and available via URL
    fieldName: string;
    position: { x: number; y: number; width: number; height: number };
    pageNum: number;
  };

type TextOverlay = {
text: string;
position: { x: number; y: number; size?: number };
pageNum?: number;
}; 


/**
 * Generates and downloads a modified PDF with given text fields, dropdowns, and checkboxes.
 */

export const generateAndDownloadPDF = async (
    pdfPath: string,
    textFieldMappings: TextFieldMappings,
    dropdownFieldMappings: DropdownFieldMappings,
    checkboxMappings: CheckboxMappings,
    fileNamePrefix: string,
    workplaceId: number,
    employeeId: number,
    signatures?: SignatureInfo[], 
    textOverlays?: TextOverlay[],
    ): Promise<void> => {
    try {
        const existingPdfBytes = await fetch(pdfPath).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true, updateMetadata: false });
        const form = pdfDoc.getForm();

        const fields = pdfDoc.getForm().getFields();
        const holdFields = [];
        const holdCheckboxes = [];
        
        fields.forEach((field) => {
        	const fieldName = field.getName();
        
        	if (field.constructor.name === 'PDFCheckBox') {
        		holdCheckboxes.push(fieldName);
        	} else {
        		holdFields.push(fieldName);
        	}
        });
        console.log("FIELDS", holdFields);
        console.log("CHECKBOXES", holdCheckboxes);

        // Populate text fields
        Object.entries(textFieldMappings).forEach(([field, value]) => {
            const textField = form.getTextField(field);
            if (textField) {
                textField.setText(typeof value === "string" ? value : formatDate(value));
            }
        });

        // Populate dropdown fields
        Object.entries(dropdownFieldMappings).forEach(([field, value]) => {
            const dropdownField = form.getDropdown(field);
            if (dropdownField && typeof value === "string") {
                dropdownField.select(value);
            }
        });

        // Handle checkboxes
        checkboxMappings.forEach((checkbox) => {
            form.getCheckBox(checkbox)?.check();
        });

        //Draw signatures
        if (signatures && signatures.length > 0) {
            for (const sig of signatures) {
              await drawSignatureOnPdf(
                pdfDoc,
                pdfDoc.getForm(),
                sig.file,
                sig.position,
                sig.pageNum
              );
            }
          }

        //Draw text overlays
        if (textOverlays && textOverlays.length > 0) {
            for (const { text, position, pageNum = 0 } of textOverlays) {
                const page = pdfDoc.getPages()[pageNum];
                page.drawText(typeof text === "string" ? text : formatDate(text), {
                x: position.x,
                y: position.y,
                size: position.size || 12,
                color: rgb(0, 0, 0),
                });
            }
        }

        // Convert the modified PDF to a Blob
        const modifiedPdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Generate a dynamic filename
        const fileName = `${fileNamePrefix}.pdf`;

        // Auto-download the filled PDF
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


        // Upload to API
        try {
            const formData = new FormData();
            formData.append("image", pdfBlob, fileName);
            const token = getTokenFromLocalStorage('token_key'); // Replace with your actual token

            const response = await fetch(`${process.env.REACT_APP_API_URL}/employee-form/${workplaceId}/${employeeId}/form/upload`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json",
                },
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log("✅ PDF uploaded successfully");
        } catch (error) {
            console.error("❌ Error uploading PDF:", error);
        }
    } catch (error) {
        console.error("❌ Error generating PDF:", error);
    }
};


/**
 * Embeds and draws a PNG signature on a PDF form at the given field position.
 *
 * @param {PDFDocument} pdfDoc - The PDFLib document instance.
 * @param {File} signatureFile - The PNG signature file (e.g., from an input).
 * @param {object} position - Coordinates and size for drawing the image.
 * @param {number} position.x - X coordinate.
 * @param {number} position.y - Y coordinate.
 * @param {number} position.width - Width of the signature.
 * @param {number} position.height - Height of the signature.
 */
export const drawSignatureOnPdf = async (pdfDoc, form, signatureFile, position, pageNum) => {
    try{
        if (!pdfDoc || !signatureFile || !position) return;

    
        let signatureBytes;
        try {
        // Try reading it as File (e.g., from SignaturePad)
        signatureBytes = await signatureFile.arrayBuffer();
        } catch (err) {
            try {
                // Fallback: treat it as URL
                signatureBytes = await fetch(signatureFile.replace(/([^:]\/)\/+/g, "$1")).then(res => res.arrayBuffer());
            } catch (urlError) {
                console.error("❌ Could not load signature from file or URL", urlError);
                return;
            }
        }
        const signatureImage = await pdfDoc.embedPng(signatureBytes);
        const page = pdfDoc.getPage(pageNum);
    
        page.drawImage(signatureImage, {
        x: position.x,
        y: position.y,
        width: position.width,
        height: position.height,
        });
    } catch (signError) {
        console.log("Signature Error", signError);
    }
  };