import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';

export const AllCheckbox = ({ onChange, isDisabled }) => {
	return (
		<FormControlLabel
			control={<Switch disabled={isDisabled} color="secondary" onChange={onChange} />}
			label="All"
		/>
	);
};
