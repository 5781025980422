import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Info } from '@mui/icons-material';
import {
	Box,
	ClickAwayListener,
	IconButton,
	List,
	ListItem as MuiListItem,
	ListItemText as MuiListItemText,
	Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import Masonry from '@mui/lab/Masonry';
import { BroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { useDebouncedValue } from 'core/hooks/useDecbouncedValue';

const PopupBody = styled('div')(
	({ theme }) => `
    width: max-content;
    padding: 12px 16px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: ${
			theme.palette.mode === 'dark'
				? `0px 4px 8px rgb(0 0 0 / 0.7)`
				: `0px 4px 8px rgb(0 0 0 / 0.1)`
		};
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    z-index: 1;
`
);

const ListItemText = styled(MuiListItemText)({
	fontSize: '0.75rem',
	margin: 0,
	'& > span': {
		fontSize: '0.75rem',
	},
	'&.count > span': {
		fontSize: '0.8rem',
		fontWeight: 500,
	},
});

const ListItem = styled(MuiListItem)({});

const UnderlinedTypography = styled(Typography)({
	textDecoration: 'underline',
});

const RenderList: React.FC<{ items: any[] }> = ({ items }) => {
	if (items.length === 0)
		return (
			<List>
				<ListItem>
					<ListItemText primary="● ALL" />
				</ListItem>
			</List>
		);

	const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));

	return (
		<List>
			{sortedItems.slice(0, 10).map((item) => (
				<ListItem disableGutters key={item.id}>
					<ListItemText primary={`● ${item.name}`} />
				</ListItem>
			))}
			{sortedItems.length > 10 && (
				<ListItemText className="count" primary={`+ ${sortedItems.length - 10} more 👤`} />
			)}
		</List>
	);
};

export const ChatHeaderPopover: React.FC<{ group: BroadcastGroup }> = ({ group }) => {
	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchor(anchor ? null : event.currentTarget);
	};

	const open = Boolean(anchor);
	const id = open ? 'simple-popup' : undefined;

	const openDebounced = useDebouncedValue(open, 100);

	return (
		<div>
			<IconButton
				aria-describedby={id}
				onClick={handleClick}
				sx={{ width: '42px', height: '42px' }}
			>
				<Info color="secondary" fontSize="large" />
			</IconButton>
			<BasePopup
				id={id}
				open={open}
				anchor={anchor}
				style={{
					zIndex: 1300,
					background: 'white',
					visibility: openDebounced ? 'visible' : 'hidden',
				}}
			>
				<PopupBody>
					<ClickAwayListener onClickAway={() => setAnchor(null)}>
						<BroadcastGroupDetails group={group} />
					</ClickAwayListener>
				</PopupBody>
			</BasePopup>
		</div>
	);
};

export const BroadcastGroupDetails: React.FC<{ group: Partial<BroadcastGroup> }> = ({ group }) => {
	const showRoles = !!group.role?.length;
	const showWorkplaces =
		!!group.workplace?.length || !group.division?.length || !group.operating_unit?.length;
	const showOperatingUnits = !!group.operating_unit?.length || !group.division?.length;
	const showDivisions = !!group.division?.length;
	const showParticipants = !!group.participants?.length;

	return (
		<Box>
			<Typography variant="h6" fontSize="1rem" paddingBottom="8px">
				Group Details
			</Typography>
			<Masonry columns={3} spacing={2}>
				{showParticipants && (
					<Box>
						<UnderlinedTypography variant="subtitle2">Participants</UnderlinedTypography>
						<RenderList
							items={group.participants.map((p) => ({
								...p,
								name: `${p.first_name} ${p.last_name}`,
							}))}
						/>
					</Box>
				)}
				{showRoles && (
					<Box>
						<UnderlinedTypography variant="subtitle2">Roles</UnderlinedTypography>
						<RenderList items={group.role} />
					</Box>
				)}
				{showWorkplaces && (
					<Box>
						<UnderlinedTypography variant="subtitle2">Workplaces</UnderlinedTypography>
						<RenderList items={group.workplace} />
					</Box>
				)}
				{showOperatingUnits && (
					<Box>
						<UnderlinedTypography variant="subtitle2">Operating Units</UnderlinedTypography>
						<RenderList items={group.operating_unit} />
					</Box>
				)}
				{showDivisions && (
					<Box>
						<UnderlinedTypography variant="subtitle2">Divisions</UnderlinedTypography>
						<RenderList items={group.division} />
					</Box>
				)}
			</Masonry>
		</Box>
	);
};

export const ChatHeaderInfo: React.FC = () => <div>TEST</div>;
