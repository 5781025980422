import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	CalendarToday,
	MbscCalendarEvent,
	MbscCalendarEventData,
	MbscResource,
} from '@mobiscroll/react';

import React, { useEffect } from 'react';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';
import { useCalendarView } from 'core/hooks/useCalendarView';

import { ShiftIconComponent } from './components/ShiftIcon';
import Buttons from 'styles/buttons.module.scss';

interface ScheduleEventProps {
	calendarType: string;
	data: MbscCalendarEventData;
}

const getScheduleEventStyles = (original: MbscCalendarEvent) => {
	const dynamicStyle = original.added_by_mabel
		? { border: '2px solid var(--color-purple)' }
		: { borderColor: original?.background_color };

	return {
		cursor: 'pointer',
		backgroundColor: original?.background_color,
		color: original?.text_color,
		...dynamicStyle,
	};
};

const getEventClassNames = (calendarType: string, original: MbscCalendarEventData['original']) => {
	return [
		'md-timeline-template-event',
		original?.overlap ? 'hasStripeBackground' : '',
		calendarType === 'request' ? 'requestsEventCustom' : '',
		!original?.role && calendarType === 'event' ? 'scheduleEvent' : '',
		original?.role === undefined && calendarType === 'request' ? 'timeoffColor' : '',
	]
		.filter(Boolean)
		.join(' ');
};
export const ScheduleEvent: React.FC<ScheduleEventProps> = ({ calendarType, data }) => {
	const { original } = data!;

	const isRequest = original.role !== undefined || calendarType !== 'request';

	if (original?.shift_type?.id === 2 && original?.scheduled) return null;

	return (
		<div
			className={getEventClassNames(calendarType, original)}
			style={getScheduleEventStyles(original)}
		>
			<div className="md-custom-event-wrapper">
				<div className="md-custom-event-details">
					<div className="md-custom-event-title">
						{isRequest ? (
							<span>
								{data.start} - {data.end}
							</span>
						) : (
							'Time off'
						)}
					</div>
				</div>
				<div className="md-timeline-template-role">
					{calendarType === 'event' ? (
						<span>
							{original?.name}, {original?.location}
						</span>
					) : (
						<span>{original?.role ?? 'No Role'}</span>
					)}
					<ShiftIconComponent shift={original} />
				</div>
			</div>
		</div>
	);
};

interface ScheduleResourceProps {
	resource: MbscResource;
	calendarType: string;
}

export const ScheduleResource: React.FC<ScheduleResourceProps> = ({ resource, calendarType }) => {
	return (
		<div className={`md-work-week-cont`}>
			<div className="md-work-week-name">
				{calendarType === 'event'
					? resource.name
					: `${resource.name} (${resource?.active_week_hours} h)`}
			</div>
			<div className="md-work-week-title">
				{resource?.role?.map(({ name }: any) => `${name}`).join(', ')}
			</div>
		</div>
	);
};

interface MyHeaderProps {
	calendarType: string;
	setCalendarType: (args: 'schedule' | 'request' | 'event') => void;
	workplacesData?: IWorkplaces;
	workplaceId: number;
}

export const CalendarHeader: React.FC<MyHeaderProps> = ({
	calendarType,
	setCalendarType,
	workplacesData,
	workplaceId,
}) => {
	const { calendarView, setCalendarView } = useCalendarView();

	return (
		<>
			<div className="customHeader">
				<div className="customHeaderTop">
					<CalendarNav className="md-work-week-nav" />
					<div className={Buttons.btnToggleThree}>
						<button
							type="button"
							className={`${Buttons.btnToggleDefaultThree} ${
								calendarType === 'schedule' ? Buttons.btnToggleActiveThree : ''
							}`}
							onClick={() => setCalendarType('schedule')}
						>
							Schedule
						</button>
						<button
							type="button"
							className={`${Buttons.btnToggleDefaultThree} ${
								calendarType === 'request' ? Buttons.btnToggleActiveThree : ''
							}`}
							onClick={() => setCalendarType('request')}
						>
							Requests
						</button>
						<button
							type="button"
							className={`${Buttons.btnToggleDefaultThree} ${
								calendarType === 'event' ? Buttons.btnToggleActiveThree : ''
							}`}
							onClick={() => setCalendarType('event')}
						>
							Events
						</button>
					</div>
					<div className={Buttons.btnToggle}>
						<button
							type="button"
							className={`${Buttons.btnToggleDefault} ${
								calendarView === 'day' ? Buttons.btnToggleActive : ''
							}`}
							onClick={() => setCalendarView('day')}
						>
							Day
						</button>
						<button
							type="button"
							className={`${Buttons.btnToggleDefault} ${
								calendarView === 'week' ? Buttons.btnToggleActive : ''
							}`}
							onClick={() => setCalendarView('week')}
						>
							Week
						</button>
					</div>
				</div>
				<div className="customHeaderBottom">
					<span className="WorkplaceTitle">
						Workplace:
						<span className="WorkplaceName">
							{workplacesData?.items?.map(
								({ workplace }) => workplaceId === workplace.id && workplace.name
							)}
						</span>
					</span>
					<div>
						<CalendarPrev className="md-work-week-prev" />
						<CalendarToday className="md-work-week-today" />
						<CalendarNext className="md-work-week-next" />
					</div>
				</div>
			</div>
		</>
	);
};
