import { ReactComponent as HappyFace } from 'assets/icons/HappyFace.svg';
import { ReactComponent as SmileFace } from 'assets/icons/SmileFace.svg';
import FooterButtons from '../FooterButtons/FooterButtons';

import style from './Welcome.module.scss';

interface WelcomeProps {
	welcome: string;
	setWelcome: React.Dispatch<React.SetStateAction<string>>;
	goNext: () => void;
	goBack: () => void;
}

export default function Welcome({ welcome, setWelcome, goNext, goBack }: WelcomeProps): JSX.Element {
	return (
		<>
			<p className={style.InfoText}>Thank you for dining with us today! Please provide us with your feedback.</p>
			<div
				className={`${style.SelectButtons} ${welcome === 'withInfo' ? style.SelectedButtonColor : null}`}
				onClick={() => setWelcome('withInfo')}
			>
				<HappyFace />
				<p>Fill in information and get reward</p>
			</div>
			<div
				className={`${style.SelectButtons} ${welcome === 'withoutInfo' ? style.SelectedButtonColor : null}`}
				onClick={() => setWelcome('withoutInfo')}
			>
				<SmileFace />
				<p>Give feedback without information, no reward</p>
			</div>

			<FooterButtons goNext={goNext} goBack={goBack} isDisabled={welcome === ''} showPrevious={false} label="Start" />
		</>
	);
}
