import { Box, Typography } from '@mui/material';
import SignatureBox from 'components/featured/documents/SignatureBox';
import { DocsHRPostPayload, submitHRForm, uploadHRFile } from 'core/API/documents';
import { useProviderFunctions } from 'core/components/Toast/context/ToastContext';
import { FormGroupSection, SignAndSubmit } from 'module/documents/components/components';
import DynamicFormComponent from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import { EmployerFieldsForm } from 'module/documents/data';
import { useState, FC } from 'react';
import { useQueryClient } from 'react-query';

const allEmployerFields = EmployerFieldsForm.fields
	.map((field) => field.value)
	.filter((value) => value !== 'first_date_employment');
interface HumanResourceFormProps {
	setEmployerSignatureFile: React.Dispatch<React.SetStateAction<File | null>>;
}

export const HumanResourceTab: FC<HumanResourceFormProps> = ({
	setEmployerSignatureFile
}) => {
	const [signatureSubmitted, setSignatureSubmitted] = useState(false);
	const { formik, isEmployer } = useDocumentContext();

	const { showToast } = useProviderFunctions();
	const queryClient = useQueryClient();

	const handleFormSubmit = async () => {
		const payload: DocsHRPostPayload = {
			employer_title: formik.values.employer_title,
			employer_name: formik.values.employer_name,
			employer_business_name: formik.values.employer_business_name,
			phone: "",
			address: formik.values.employer_business_address,
			city: formik.values.employer_city,
			state: formik.values.employer_state,
			zip_code: formik.values.employer_zip_code,
			country: formik.values.country,
			employer_ein: formik.values.employer_ein,
			signature_approved: true,
			signature_file: {
				id: formik.values.signature_file_id,
			},
		};
		try {
			await submitHRForm(payload);
			showToast('success', 'Form submitted successfully!');
			queryClient.invalidateQueries(['employee-form/hr']);
		} catch (error) {
			console.error('Error submitting form:', error);
			showToast('error', 'Error submitting form!');
		}
	};

	const handleSignatureSubmit = async (file: File) => {
		console.log('handleSignatureSubmit:', handleSignatureSubmit);
		try {
			const response = await uploadHRFile(file);
			setEmployerSignatureFile(file);
			console.log('Signature uploaded successfully:', response);
			formik.setFieldValue('employer_signature', true);
			formik.setFieldValue('signature_file_id', response.id);
			setSignatureSubmitted(true);
		} catch (error) {
			console.error('Error uploading signature:', error);
			setEmployerSignatureFile(file);
			formik.setFieldValue('employer_signature', false);
		}
	};

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					Human Resource Tab
				</Typography>

				<Box px="16px" bgcolor="rgb(240, 245, 250)">
					<Box paddingY="16px">
						<Typography variant="body1">
							Employer information that is going to be used for signing other documents.
						</Typography>
					</Box>
				</Box>
			</Box>

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title={EmployerFieldsForm.title}
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={allEmployerFields} />
				</FormGroupSection>
				<DynamicFormComponent mt={2}>
					<SignatureBox
						onSubmit={handleSignatureSubmit}
						existingPath={formik.values.employer_signature_path}
					/>
				</DynamicFormComponent>
			</FormGroupSection>

			<SignAndSubmit
				formData={EmployerFieldsForm}
				isSubmitDisabled={!formik.values.employer_signature || !signatureSubmitted}
				extraValidation={[{ value: formik.values.employer_signature, field: 'Employer Signature' }]}
				onSubmit={handleFormSubmit}
			/>
		</Box>
	);
};
