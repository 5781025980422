import React from 'react';

import style from './FooterButtons.module.scss';

export interface IFooterButtons {
	goNext: () => void;
	goBack: () => void;
}

interface FooterButtonsProps extends IFooterButtons {
	isDisabled: boolean;
	showPrevious: boolean;
	label?: string;
}
export default function FooterButtons({
	goNext,
	goBack,
	isDisabled,
	showPrevious,
	label = 'Start',
}: FooterButtonsProps): JSX.Element {
	return (
		<>
			<div className={style.Footer}>
				{showPrevious ? (
					<button onClick={goBack} className={style.BorderButton}>
						Go to previous question
					</button>
				) : null}
				<button onClick={goNext} disabled={isDisabled}>
					{label}
				</button>
				{/* {screenIndex > 2 &&
                screenIndex !==
                    state.guest_connect_additional_field.length + 3 ? (
                    <button onClick={goBack} className={style.BorderButton}>
                        Go to previous question
                    </button>
                ) : null}
                <button onClick={goNext} disabled={!isDisabled}>
                    {state.guest_connect_additional_field.length + 3 !==
                    screenIndex
                        ? 'Start'
                        : 'Finish'}
                </button> */}
			</div>
		</>
	);
}
