import { AxiosResponse } from 'axios';

import { initializeApiInterceptors } from './axiosInterceptors';
import { HttpClient } from './httpClient';

export type ResponseHandler<T = any, R = T> = (response: AxiosResponse<T>) => R;

interface ApiServiceGetConfig {
	params: any;
}

function statusChecker<T>(response: AxiosResponse<T>) {
	if (response?.status >= 200 && response?.status < 300) {
		return response;
	}

	throw response?.data;
}

class ApiService {
	private _httpClient = HttpClient.getInstance();
	constructor() {
		initializeApiInterceptors(this._httpClient);
	}

	responseHandler: ResponseHandler = ({ data }) => data ?? null;

	async get<T = any>(url: string, config?: ApiServiceGetConfig): Promise<AxiosResponse<T>> {
		const { params } = config ?? {};

		return statusChecker(await this._httpClient.get<T>(url, { params }));
	}

	async post<T = any, B = any>(url: string, body?: B): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.post<T>(url, body));
	}

	async postUpload<T = any, B = any>(url: string, body?: B): Promise<AxiosResponse<T>> {
		return statusChecker(
			await this._httpClient.post<T>(url, body, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		);
	}

	async put<T = any, B = any>(url: string, body: B): Promise<AxiosResponse<T>> {
		return statusChecker(await this._httpClient.put<T>(url, body));
	}

	async delete(url: string): Promise<AxiosResponse<null>> {
		return statusChecker(await this._httpClient.delete(url));
	}
}

export const apiService = new ApiService();
