import { apiService } from 'core/services/apiService';
import { BroadcastGroup } from 'module/laborsaber/components/Chat/utils/BroadcastGroupProvider';
import { objectToQueryParams } from 'module/laborsaber/utils/utils';
import { IPaginatedResponse, UserMe } from 'types';
import { Role } from './roles';
import { Workplace } from 'module/workplaces/interface/WorkplacesInterface';

export interface Participant {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
}

export type BroadcastType = 'organization' | 'division' | 'operating_unit' | 'workplace';

export interface BroadcastSettings {
	organization_broadcast: boolean;
	division_broadcast: boolean;
	operating_unit_broadcast: boolean;
	workplace_broadcast: boolean;
}

export interface BroadcastFile {
	id: number;
	name?: number;
	path?: string;
}

export interface BroadcastMessage {
	id?: number;
	content: string;
	author: Participant;
	workplace: Workplace;
	broadcast_group: BroadcastGroup;
	direction?: string;
	created_at: string;
	broadcast_file?: BroadcastFile[];
}

export interface OutgoingBroadcastMessage extends Partial<BroadcastMessage> {}

const wait = (seconds: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, seconds * 1000);
	});
};

const saveToLocalStorage = (key, data) => {
	localStorage.setItem(key, JSON.stringify(data));
};

const getBroadcastGroups = async (
	workplaceId: number,
	limit: number = 1000
): Promise<IPaginatedResponse<BroadcastGroup>> => {
	const direction = 'DESC';
	const orderBy = 'updatedAt';

	const data = apiService.responseHandler(
		await apiService.get(
			`broadcast-group?${objectToQueryParams({
				workplace: workplaceId,
				limit,
				direction,
				orderBy,
			})}`
		)
	);

	return data;
};

/**
 *
 * @param broadcastGroup
 * @returns
 */
const createBroadcastGroup = async (
	broadcastGroup: Partial<BroadcastGroup>
): Promise<BroadcastGroup> => {
	const { data } = apiService.responseHandler(
		await apiService.post('broadcast-group', broadcastGroup)
	);

	return data;
};

const getSingleBroadcastGroup = async (id: number): Promise<BroadcastGroup> => {
	const { data } = apiService.responseHandler(await apiService.get(`broadcast-group/${id}`));

	return data;
};

const getEmployees = async (): Promise<BroadcastGroup> => {
	const { data } = apiService.responseHandler(await apiService.get(`employees/`));

	return data;
};

export const setBroadcastGroupMessageCounts = (messages) => {
	sessionStorage.setItem('broadcastGroupMessages', JSON.stringify(messages));
};

export const setSingleBroadcastGroupMessageCount = (groupId, count) => {
	const messages = JSON.parse(sessionStorage.getItem('broadcastGroupMessages')) || {};
	messages[groupId] = count;
	sessionStorage.setItem('broadcastGroupMessages', JSON.stringify(messages));
};

export const getBroadcastGroupMessageCount = (groupId) => {
	const messages = JSON.parse(sessionStorage.getItem('broadcastGroupMessages')) || {};
	return messages[groupId] || 0;
};

export const getNumberOfUnreadBroadcastGroups = () => {
	const messages = JSON.parse(sessionStorage.getItem('broadcastGroupMessages')) || {};

	return Object.keys(messages).length;
};

// ************************************************************
export const getDivisions = async (): Promise<IPaginatedResponse<any>> => {
	const data = apiService.responseHandler(await apiService.get('broadcast-group/divisions'));
	return data;
};

export const getOperatingUnits = async (
	searchByDivision?: number[]
): Promise<IPaginatedResponse<any>> => {
	let query = '';
	if (searchByDivision && searchByDivision.length > 0) {
		query = `?search_by_division=${searchByDivision.join(',')}`;
	}
	const data = apiService.responseHandler(
		await apiService.get(`broadcast-group/operating-units${query}`)
	);
	return data;
};

export const getRoles = async (): Promise<IPaginatedResponse<Role>> => {
	const data = apiService.responseHandler(await apiService.get('broadcast-group/roles'));
	return data;
};

export const getWorkplaces = async (
	searchByOperatingUnits?: number[]
): Promise<IPaginatedResponse<Workplace>> => {
	let query = '';
	if (searchByOperatingUnits && searchByOperatingUnits.length > 0) {
		query = `?search_by_operating_units=${searchByOperatingUnits.join(',')}`;
	}
	const data = apiService.responseHandler(
		await apiService.get(`broadcast-group/workplaces${query}`)
	);
	return data;
};
// ************************************************************
// Function to fetch the data from the /me endpoint
export const getMe = async (): Promise<UserMe> => {
	const data = apiService.responseHandler(await apiService.get('/me'));
	return data;
};
// ************************************************************

const updateBroadcastGroup = async (
	id: number,
	broadcastGroup: BroadcastGroup
): Promise<BroadcastGroup> => {
	const { data } = apiService.responseHandler(
		await apiService.put(`broadcast-group/${id}`, broadcastGroup)
	);

	return data;
};

const getBroadcastGroupMessages = async (
	broadcastGroup: number,
	workplace: number,
	orderBy: string = 'createdAt',
	direction: string = 'ASC',
	limit: number = 2000,
	page: number = 1
): Promise<IPaginatedResponse<BroadcastMessage>> => {
	console.log('💬 refetching messages...');

	const data = apiService.responseHandler(
		await apiService.get(
			`broadcast-messages?${objectToQueryParams({
				broadcastGroup,
				workplace,
				orderBy,
				direction,
				limit,
				page,
			})}`
		)
	);

	return {
		...data,
		items: data.items.map((item) => {
			// convert ASC to 'incoming' and DESC to 'outgoing'
			return { ...item, direction: direction === 'ASC' ? 'incoming' : 'outgoing' };
		}),
	};
};

const createBroadcastMessage = async (
	broadcastMessage: BroadcastMessage
): Promise<BroadcastMessage> => {
	// const fileIds = broadcastMessage.broadcast_file.map((file) => file.id);

	const { data } = apiService.responseHandler(
		await apiService.post('broadcast-messages', broadcastMessage)
	);

	return data;
};

const getSingleBroadcastMessage = async (id: number): Promise<BroadcastMessage> => {
	const { data } = apiService.responseHandler(await apiService.get(`broadcast-messages/${id}`));

	return data;
};

export {
	createBroadcastGroup,
	createBroadcastMessage,
	getBroadcastGroupMessages,
	getBroadcastGroups,
	getEmployees,
	getSingleBroadcastGroup,
	getSingleBroadcastMessage,
	updateBroadcastGroup,
};

export const uploadBroadcastFile = async (file: File): Promise<BroadcastFile> => {
	const formData = new FormData();
	formData.append('image', file);

	const response = await apiService.postUpload('broadcast/file/upload', formData);

	return response.data;
};
