import { ReactComponent as HappyFace } from 'assets/icons/HappyFace.svg';

import style from './ThankYou.module.scss';

export default function ThankYou(): JSX.Element {
	return (
		<div className={style.ThankYou}>
			<div className={style.ThankYouBox}>
				<HappyFace />
				<p>Thanks for your feedback!</p>
			</div>
		</div>
	);
}
