import { Box, Tab, Tabs } from '@mui/material';
import { useMe } from 'core/hooks/broadcast';
import React from 'react';
import { BroadcastRoleType } from 'types';
import { useBroadcastGroup } from '../utils/BroadcastGroupProvider';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ py: 3 }}>{children}</Box>}
		</div>
	);
}

export default function ChatCreateTabs({ children }) {
	const [value, setValue] = React.useState(0);
	const { resetBroadcastGroup } = useBroadcastGroup();
	const { data: me, isLoading } = useMe();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		resetBroadcastGroup();
	};

	const childrenArray = React.Children.toArray(children);

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					aria-label="broadcast tabs"
					className="no-scrollbar"
					textColor="secondary"
					indicatorColor="secondary"
					scrollButtons="auto"
				>
					<Tab wrapped value={0} label="Direct message" />
					<Tab
						wrapped
						disabled={isLoading || !me?.roles.includes(BroadcastRoleType.BROADCAST_WORKPLACE)}
						value={1}
						label="Group"
					/>
				</Tabs>
			</Box>

			{childrenArray.map((child, index) => (
				<CustomTabPanel key={index} value={value} index={index}>
					{child}
				</CustomTabPanel>
			))}
		</Box>
	);
}
