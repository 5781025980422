import { useToastContext } from './context/ToastContext';

import closeIcon from 'assets/icons/Close.svg';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import './Toast.style.scss';

export default function Toast(): JSX.Element {
	const { show, closeToast, type, text } = useToastContext();

	return (
		<>
			{show ? (
				<div className={`globalToast ${type}`}>
					<p>{text}</p>
					<SvgIcon spriteUrl={closeIcon} className="toastCloseIcon" onClick={closeToast} />
				</div>
			) : null}
		</>
	);
}
