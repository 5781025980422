import fi from '@mobiscroll/react/dist/src/i18n/fi';
import { indexFieldsByValue } from 'module/documents/utils';
import { title } from 'process';

export interface FormField {
	label: string;
	isOptional: boolean;
	type: string;
	value?: string;
	helper?: string;
	autocomplete?: string;
	computed?: boolean;
	disableField?: string;
	isEmployerField?: boolean;
	style?: Record<string, string>;
	options?: Record<string, any>[];
}

export interface FormGroup {
	title: string;
	fields: FormField[];
}

export const SignaturesData: FormGroup = {
	title: 'Signatures',
	fields: [
		{
			label: 'Employee Signature',
			isOptional: false,
			type: 'boolean',
			value: 'employee_signature',
			autocomplete: 'off',
		},
		{
			label: 'Employee Signature Date',
			isOptional: false,
			type: 'date',
			value: 'employee_signature_date',
			autocomplete: 'off',
		},
		{
			label: 'Employer or Authorized Representative Signature',
			isOptional: false,
			type: 'boolean',
			value: 'employer_signature',
			autocomplete: 'off',
		},
		{
			label: 'Employer or Authorized Representative Signature Date',
			isOptional: false,
			type: 'date',
			value: 'employer_signature_date',
			autocomplete: 'off',
		},
		{
			label: 'Preparer or Translator Signature',
			isOptional: false,
			type: 'boolean',
			value: 'preparer_signature',
			autocomplete: 'off',
		},
		{
			label: 'Preparer or Translator Signature Date',
			isOptional: false,
			type: 'date',
			value: 'preparer_signature_date',
			autocomplete: 'off',
		},
	],
};

export const EmployerFieldsForm: FormGroup = {
	title: 'Employer Information',
	fields: [
		{
			label: 'Employer’s Business or Organization Name',
			isOptional: false,
			type: 'text',
			value: 'employer_business_name',
			autocomplete: 'organization',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Last and First Name of Employer or Authorized Representative',
			isOptional: false,
			type: 'text',
			value: 'employer_name',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Employer’s Business or Organization Address (Street Number and Name)',
			isOptional: false,
			type: 'text',
			value: 'employer_business_address',
			autocomplete: 'street-address',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'City or Town',
			isOptional: false,
			type: 'text',
			value: 'employer_city',
			autocomplete: 'address-level2',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'State',
			isOptional: false,
			type: 'text',
			value: 'employer_state',
			autocomplete: 'address-level1',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'ZIP Code',
			isOptional: false,
			type: 'text',
			value: 'employer_zip_code',
			autocomplete: 'postal-code',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Employer verification number (EIN)',
			isOptional: true,
			type: 'text',
			value: 'employer_ein',
		},
		{
			label: 'First date of employment',
			isOptional: true,
			type: 'date',
			value: 'first_date_employment',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Title of Employer or Authorized Representative',
			isOptional: false,
			type: 'text',
			value: 'employer_title',
			autocomplete: 'off',
		},
	],
};

export const EmployerFieldsFormIndexed = indexFieldsByValue(EmployerFieldsForm);

export const I9SupplementAFormData: FormGroup = {
	title: 'Supplement A: Preparer and/or Translator Certification',
	fields: [
		{
			label: "Translator's First Name (Given Name)",
			isOptional: false,
			type: 'text',
			value: 'preparer_first_name',
			autocomplete: 'given-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Translator's Middle Initial (if any)",
			isOptional: true,
			type: 'text',
			value: 'preparer_middle_initial',
			autocomplete: 'additional-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Translator's Last Name (Family Name)",
			isOptional: false,
			type: 'text',
			value: 'preparer_last_name',
			autocomplete: 'family-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Translator's Address (Street Number and Name)",
			isOptional: false,
			type: 'text',
			value: 'preparer_address',
			autocomplete: 'street-address',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'City or Town',
			isOptional: false,
			type: 'text',
			value: 'preparer_city',
			autocomplete: 'address-level2',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'State',
			isOptional: false,
			type: 'text',
			value: 'preparer_state',
			autocomplete: 'address-level1',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'ZIP Code',
			isOptional: false,
			type: 'text',
			value: 'preparer_zip_code',
			autocomplete: 'postal-code',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Preparer or Translator Signature',
			isOptional: false,
			type: 'hidden',
			value: 'preparer_signature',
			autocomplete: 'off',
		},
		{
			label: 'I9 form submission',
			isOptional: true,
			type: 'hidden',
			value: 'employee_form_i9',
			autocomplete: 'off',
		},
	],
};

export const I9SupplementAFormDataIndexed = indexFieldsByValue(I9SupplementAFormData);

export const _2BFormSupplement: FormGroup = {
	title: 'Step 2(b) — Multiple Jobs Worksheet',
	fields: [
		{
			label: 'Two jobs',
			helper:
				"If you have two jobs or you’re married filing jointly and you and your spouse each have one job, find the amount from the appropriate table on page 4 using the 'Higher Paying Job' row and the 'Lower Paying Job' column.",
			isOptional: true,
			type: 'number',
			value: 'two_jobs_amount',
			autocomplete: 'off',
		},
		{
			label: 'Three jobs - Line 2a',
			helper:
				"If you and/or your spouse have three jobs at the same time, enter the amount from the appropriate table on page 4 for the highest paying job in the 'Higher Paying Job' row and the next highest paying job in the 'Lower Paying Job' column.",
			isOptional: true,
			type: 'number',
			value: 'three_jobs_line_2a',
			autocomplete: 'off',
		},
		{
			label: 'Three jobs - Line 2b',
			helper:
				"Add the annual wages of the two highest paying jobs from line 2a together and use the total as the wages in the 'Higher Paying Job' row. Use the annual wages for your third job in the 'Lower Paying Job' column to find the amount from the appropriate table on page 4.",
			isOptional: true,
			type: 'number',
			value: 'three_jobs_line_2b',
			autocomplete: 'off',
		},
		{
			label: 'Three jobs - Line 2c',
			helper: 'Add the amounts from lines 2a and 2b and enter the result on line 2c.',
			isOptional: true,
			type: 'number',
			value: 'three_jobs_line_2c',
			autocomplete: 'off',
		},
		{
			label: 'Number of pay periods per year for the highest paying job',
			helper:
				'Enter the number of pay periods per year for the highest paying job. For example, if that job pays weekly, enter 52; if it pays every other week, enter 26; if it pays monthly, enter 12, etc.',
			isOptional: true,
			type: 'number',
			value: 'pay_periods_per_year',
			autocomplete: 'off',
		},
		{
			label: 'Amount from Step 4(c) of Form W-4',
			helper:
				'Divide the annual amount on line 1 or line 2c by the number of pay periods on line 3. Enter this amount here and in Step 4(c) of Form W-4 for the highest paying job.',
			isOptional: true,
			type: 'number',
			value: 'amount_step_4c',
			autocomplete: 'off',
		},
	],
};

export const W4FormData: FormGroup = {
	title: "Form W-4: Employee's Withholding Certificate",
	fields: [
		{
			label: '',
			helper:
				'Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your spouse also works. The correct amount of withholding depends on income earned from all of these jobs.',
			isOptional: false,
			type: 'radio',
			value: 'multiple_jobs_or_spouse_works',
			autocomplete: 'off',
			options: [
				{
					label: '(Skip) Does not apply to me. (No multiple jobs or spouse works.)',
					value: 0,
				},
				{
					label:
						'Use the estimator at www.irs.gov/W4App for most accurate withholding and fill. If you or your spouse have self-employment income, use this option.',
					value: 1,
				},
				{
					label:
						'Use the Multiple Jobs Worksheet on page 3 and enter the result in Step 4(c) below.',
					value: 2,
				},
				{
					label:
						'If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job.',
					value: 3,
				},
			],
		},
		{
			label: 'Filing Status',
			isOptional: false,
			type: 'radio',
			value: 'filing_status',
			options: [
				{ label: 'Single or Married filing separately', value: 1 },
				{
					label: 'Married filing jointly or Qualifying surviving spouse',
					value: 2,
				},
			],
			helper:
				'Select your tax filing status. This determines how much tax will be withheld from your paycheck.',
		},
		{
			label: 'Head of household',
			isOptional: true,
			type: 'boolean',
			value: 'head_of_household',
			autocomplete: 'off',
		},
		{
			label: 'Number of Children under age 17',
			isOptional: true,
			type: 'number',
			value: 'number_of_children',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Number of Dependents',
			isOptional: true,
			type: 'number',
			value: 'number_of_dependents',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: '4(a): Other income (not from jobs)',
			isOptional: true,
			type: 'number',
			value: 'other_income',
			autocomplete: 'off',
			helper:
				'Enter any other income you expect to receive that isn’t from jobs, such as interest, dividends, or retirement income.',
		},
		{
			label: '4(b): Deductions',
			isOptional: true,
			type: 'number',
			value: 'deductions',
			autocomplete: 'off',
			helper:
				'Enter the amount of deductions other than the standard deduction you expect to claim, such as for retirement contributions or charitable donations.',
		},
		{
			label: '4(c): Extra withholding',
			isOptional: true,
			type: 'number',
			value: 'extra_withholding',
			autocomplete: 'off',
			helper: 'Enter any additional tax amount you want withheld from each paycheck.',
		},
		{
			label: 'Sign Date',
			isOptional: false,
			type: 'date',
			value: 'signature_date',
			autocomplete: 'off',
			helper: 'Enter the date on which you are signing this form.',
		},
	],
};

export const W4FormDataIndexed = indexFieldsByValue(W4FormData);

export const SupplementBFormData: FormGroup = {
	title: 'Supplement B: Reverification and Rehire',
	fields: [
		{
			label: 'Rehire Date (if applicable)',
			isOptional: true,
			type: 'date',
			value: 'rehire_date',
			autocomplete: 'off',
		},
		{
			label: 'Document Title',
			isOptional: false,
			type: 'text',
			value: 'document_title',
			autocomplete: 'off',
		},
		{
			label: 'Document Number',
			isOptional: false,
			type: 'text',
			value: 'document_number',
			autocomplete: 'off',
		},
		{
			label: 'Expiration Date (if any)',
			isOptional: false,
			type: 'date',
			value: 'expiration_date',
			autocomplete: 'off',
		},
		{
			label: 'Signature of Employer or Authorized Representative',
			isOptional: false,
			type: 'boolean',
			value: 'employer_signature',
			autocomplete: 'off',
		},
		{
			label: 'Date of Signature',
			isOptional: false,
			type: 'date',
			value: 'employer_signature_date',
			autocomplete: 'off',
		},
		{
			label: 'Title of Employer or Authorized Representative',
			isOptional: false,
			type: 'text',
			value: 'employer_title',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'I9 form submission',
			isOptional: true,
			type: 'hidden',
			value: 'employee_form_i9',
			autocomplete: 'off',
		},
	],
};

export const I9FormData: FormGroup = {
	title: 'I-9 Form: Employment Eligibility Verification',
	fields: [
		{
			label: 'Check one of the following boxes to attest to your citizenship or immigration status',
			isOptional: false,
			type: 'radio',
			value: 'immigration_status',
			autocomplete: 'off',
			options: [
				{
					label: 'A citizen of the United States',
					value: 1,
					helper: 'Select this if you are a U.S. citizen.',
				},
				{
					label: 'A noncitizen national of the United States',
					value: 2,
					helper: 'Select this if you are a U.S. noncitizen national.',
				},
				{
					label: 'A lawful permanent resident (Enter USCIS or A-Number.)',
					value: 3,
					helper:
						'Select this if you are a lawful permanent resident and enter your USCIS or A-Number.',
				},
				{
					label: 'A noncitizen authorized to work until (enter expiration date below)',
					value: 4,
					helper:
						'Select this if you are a noncitizen with work authorization and provide the expiration date if applicable.',
				},
			],
		},
		{
			label: 'USCIS A-Number',
			isOptional: true,
			type: 'text',
			value: 'uscis_a_number',
			autocomplete: 'off',
			style: {
				gridWidth: '1/3',
			},
		},
		{
			label: 'Form I-94 Admission Number',
			isOptional: true,
			type: 'text',
			value: 'i_94_admission_number',
			autocomplete: 'off',
			style: {
				gridWidth: '1/3',
			},
		},
		{
			label: 'Foreign Passport Number and Country of Issuance',
			isOptional: true,
			type: 'text',
			value: 'foreign_passport_number',
			autocomplete: 'off',
			style: {
				gridWidth: '1/3',
			},
		},
		{
			label: 'Authorization Expiration Date:',
			isOptional: true,
			type: 'date',
			value: 'authorization_expiration_date',
			autocomplete: 'off',
		},
		{
			label: '',
			isOptional: true,
			type: 'boolean',
			value: 'translator_assisted',
		},
		{
			label: '',
			isOptional: true,
			type: 'boolean',
			value: 'employee_rehire',
		},
	],
};

export const I9ImmigrationData = {
	title: 'I-9 Form: Employment Eligibility Verification - Immigration Data',
	fields: [
		{
			label: 'Document A Title',
			isOptional: true,
			type: 'text',
			value: 'document_a_title',
			autocomplete: 'off',
		},
		{
			label: 'Document A Issuing Authority (if any)',
			isOptional: true,
			type: 'text',
			value: 'document_a_authority',
			autocomplete: 'off',
		},
		{
			label: 'Document A Number',
			isOptional: true,
			type: 'text',
			value: 'document_a_number',
			autocomplete: 'off',
		},
		{
			label: 'Document A Expiration Date (if any)',
			isOptional: false,
			type: 'date',
			value: 'document_a_expiration_date',
			autocomplete: 'off',
		},
		{
			label: 'Document A File',
			isOptional: true,
			type: 'string',
			value: 'document_a_file',
			autocomplete: 'off',
		},
		{
			label: 'Document B Title',
			isOptional: true,
			type: 'text',
			value: 'document_b_title',
			autocomplete: 'off',
		},
		{
			label: 'Document B Issuing Authority (if any)',
			isOptional: true,
			type: 'text',
			value: 'document_b_authority',
			autocomplete: 'off',
		},
		{
			label: 'Document B Number',
			isOptional: true,
			type: 'text',
			value: 'document_b_number',
			autocomplete: 'off',
		},
		{
			label: 'Document B File',
			isOptional: true,
			type: 'string',
			value: 'document_b_file',
			autocomplete: 'off',
		},
		{
			label: 'Document B Expiration Date ,',
			isOptional: true,
			type: 'date',
			value: 'document_b_expiration_date',
			autocomplete: 'off',
		},
		{
			label: 'Document C Title',
			isOptional: true,
			type: 'text',
			value: 'document_c_title',
			autocomplete: 'off',
		},
		{
			label: 'Document C Issuing Authority (if any)',
			isOptional: true,
			type: 'text',
			value: 'document_c_authority',
			autocomplete: 'off',
		},
		{
			label: 'Document C Number',
			isOptional: true,
			type: 'text',
			value: 'document_c_number',
			autocomplete: 'off',
		},
		{
			label: 'Document C Expiration Date (if any)',
			isOptional: true,
			type: 'date',
			value: 'document_c_expiration_date',
			autocomplete: 'off',
		},
		{
			label: 'Document C File',
			isOptional: true,
			type: 'string',
			value: 'document_c_file',
			autocomplete: 'off',
		},
	],
};

export const I9ImmigrationDataIndexed = indexFieldsByValue(I9ImmigrationData);

export const I9EmployerCertificationData = {
	title: 'Employer Certification Information',
	fields: [
		{
			label: 'Last and First Name of Employer or Authorized Representative',
			isOptional: false,
			type: 'text',
			value: 'employer_name',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Signature of Employer or Authorized Representative',
			isOptional: false,
			type: 'hidden',
			value: 'signature_approved',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Today's Date",
			isOptional: false,
			type: 'date',
			value: 'employer_signature_date',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'First Day of Employment',
			isOptional: false,
			type: 'date',
			value: 'first_day_of_employment',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
	],
};

export const I9EmployerCertificationDataIndexed = indexFieldsByValue(I9EmployerCertificationData);

export const I9FormDataIndexed = indexFieldsByValue(I9FormData);

export const I9SupplementBFormDataIndexed = indexFieldsByValue(SupplementBFormData);

export const CommonFieldsForm: FormGroup = {
	title: 'Common Fields for Various Forms',
	fields: [
		{
			label: 'First Name (Given Name)',
			isOptional: false,
			type: 'text',
			value: 'first_name',
			autocomplete: 'given-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Middle Initial',
			isOptional: true,
			type: 'text',
			value: 'middle_initial',
			autocomplete: 'additional-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Last Name (Family Name)',
			isOptional: false,
			type: 'text',
			value: 'last_name',
			autocomplete: 'family-name',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Date of Birth (mm/dd/yyyy)',
			isOptional: false,
			type: 'date',
			value: 'date_of_birth',
			autocomplete: 'bday',
			style: {
				gridWidth: '1/3',
			},
		},
		{
			label: 'Email Address',
			isOptional: false,
			type: 'email',
			value: 'email',
			autocomplete: 'email',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Employee's Telephone Number",
			isOptional: false,
			type: 'text',
			value: 'phone',
			autocomplete: 'tel',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Address (Street Number and Name)',
			isOptional: false,
			type: 'text',
			value: 'address',
			autocomplete: 'street-address',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'City or Town',
			isOptional: false,
			type: 'text',
			value: 'city',
			autocomplete: 'address-level2',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'State',
			isOptional: false,
			type: 'text',
			value: 'state',
			autocomplete: 'address-level1',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'ZIP Code',
			isOptional: false,
			type: 'text',
			value: 'zip_code',
			autocomplete: 'postal-code',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'U.S. Social Security Number',
			isOptional: false,
			type: 'text',
			value: 'ssn',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'First date of employment',
			isOptional: true,
			type: 'date',
			value: 'first_date_employment',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: "Driver's License Number",
			isOptional: true,
			type: 'text',
			value: 'drivers_license_number',
			autocomplete: 'off',
			style: {
				gridWidth: '1/2',
			},
		},
	],
};

export const CommonFieldsFormIndexed = indexFieldsByValue(CommonFieldsForm);

export const EmployeTippingNotice: FormGroup = {
	title: 'Notice to Tipped Employees',
	fields: [
		{
			label: 'Restaurant Name',
			isOptional: false,
			type: 'text',
			value: 'restaurant_name',
			autocomplete: 'off',
		},
	],
};

export const EmployeTippingNoticeIndexed = indexFieldsByValue(EmployeTippingNotice);

export const MIW4FormData: FormGroup = {
	title: "MI-W4 Employee's Michigan Withholding Exemption Certificate",
	fields: [
		{
			label: 'Are you a new employee?',
			isOptional: true,
			type: 'boolean',
			value: 'new_employee',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'First date of employment',
			isOptional: true,
			type: 'date',
			value: 'first_date_employment',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Personal Exceptions ($)',
			isOptional: false,
			type: 'number',
			value: 'personal_exceptions',
			helper: 'Enter the number of personal exceptions you are claiming.',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Deduct Amount ($)',
			isOptional: true,
			type: 'number',
			value: 'deduct_amount',
			helper: 'Additional amount you want deducted from each pay (if employer agrees).',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'a) A Michigan income tax liability is not expected this year',
			isOptional: true,
			type: 'boolean',
			value: 'tax_not_expected',
		},
		{
			label: 'b) Wages are exempt from withholding',
			isOptional: true,
			type: 'boolean',
			value: 'wages_exempt',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Explanation for Wages Exemption',
			isOptional: true,
			type: 'text',
			value: 'wages_exempt_explanation',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'c) Permanent home (domicile) is located in the following Renaissance Zone',
			isOptional: true,
			type: 'boolean',
			value: 'home_residence_zone',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: '',
			isOptional: true,
			type: 'text',
			value: 'home_residence_zone_explanation',
			style: {
				gridWidth: '1/2',
			},
		},
	],
};

export const MIW4FormDataIndexed = indexFieldsByValue(MIW4FormData);

export const FoodReportAgreementsData: FormGroup = {
	title: 'Conditional and Food Employee Interview',
	fields: [
		{
			label: 'Diarrhea',
			isOptional: true,
			type: 'boolean',
			value: 'suffering_diarrhea',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Vomiting',
			isOptional: true,
			type: 'boolean',
			value: 'suffering_vomiting',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Jaundice',
			isOptional: true,
			type: 'boolean',
			value: 'suffering_jaundice',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Sore Throat with Fever',
			isOptional: true,
			type: 'boolean',
			value: 'suffering_sore_throat_fever',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'If so, enter the date when the first symptoms began.',
			isOptional: true,
			type: 'date',
			value: 'date_of_onset',
		},
		{
			label: 'Infected Wounds or Cuts?',
			isOptional: true,
			type: 'boolean',
			value: 'suffering_wounds',
		},
		{
			label: 'Ill with Typhoid Fever',
			isOptional: true,
			type: 'boolean',
			value: 'typhoid_fever_diagnosed',
			helper: 'Have you ever been diagnosed as being ill with typhoid fever (Salmonella Typhi)?',
		},
		{
			label: 'Took Antibiotics',
			isOptional: true,
			type: 'boolean',
			value: 'took_antibiotics',
			helper: 'Did you take antibiotics for S. Typhi within the past 3 months?',
		},
		{
			label: 'Finished Antibiotics Prescription',
			isOptional: true,
			type: 'boolean',
			value: 'finished_antibiotics',
			helper: 'If you took antibiotics, did you finish the prescription?',
		},
		{
			label: 'Exposed to a confirmed foodborne disease outbreak recently?',
			isOptional: true,
			type: 'boolean',
			value: 'exposed_to_outbreak',
			helper: 'Have you been exposed to a foodborne disease outbreak?',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Date of Outbreak',
			isOptional: true,
			type: 'date',
			value: 'date_of_outbreak',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'E. coli O157:H7 Exposure (within the past 3 days)',
			isOptional: true,
			type: 'boolean',
			value: 'ecoli_exposure',
		},
		{
			label: 'Norovirus - Date of Illness Outbreak',
			isOptional: true,
			type: 'date',
			disableField: 'norovirus_exposure',
			value: 'norovirus_outbreak_date',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Norovirus Exposure (within the past 48 hours)',
			isOptional: true,
			type: 'boolean',
			value: 'norovirus_exposure',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'E. coli - Date of Illness Outbreak',
			isOptional: true,
			type: 'date',
			disableField: 'ecoli_exposure',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Hepatitis A Exposure (within the past 30 days)',
			isOptional: true,
			type: 'boolean',
			value: 'hepatitis_a_exposure',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Hepatitis A - Date of Illness Outbreak',
			isOptional: true,
			type: 'date',
			disableField: 'hepatitis_a_exposure',
			value: 'hepatitis_a_outbreak_date',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Typhoid Fever Exposure (within the past 14 days)',
			isOptional: true,
			type: 'boolean',
			value: 'typhoid_fever_exposure',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Typhoid Fever - Date of Illness Outbreak',
			isOptional: true,
			type: 'date',
			disableField: 'typhoid_fever_exposure',
			value: 'typhoid_fever_outbreak_date',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Shigellosis Exposure (within the past 3 days)',
			isOptional: true,
			type: 'boolean',
			value: 'shigellosis_exposure',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Shigellosis - Date of Illness Outbreak',
			isOptional: true,
			disableField: 'shigellosis_exposure',
			type: 'date',
			value: 'shigellosis_outbreak_date',
			style: { gridWidth: '1/2' },
		},
		{
			label: 'Live with Person Diagnosed with Norovirus, Typhoid Fever, etc.',
			isOptional: true,
			type: 'boolean',
			value: 'live_with_person_diagnosed',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Date of Onset of Illness for Household Member',
			isOptional: true,
			type: 'date',
			disableField: 'live_with_person_diagnosed',
			value: 'date_of_onset_household_member',
			helper: 'If yes, provide the date of onset of illness.',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Household Member in Disease Outbreak Area',
			isOptional: true,
			type: 'boolean',
			value: 'household_member_in_outbreak_area',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Date of Onset of Illness for Outbreak Area',
			isOptional: true,
			type: 'date',
			disableField: 'household_member_in_outbreak_area',
			value: 'date_of_onset_outbreak_area',
			helper: 'If yes, provide the date of onset of illness.',
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Doctors Name',
			isOptional: false,
			type: 'text',
			value: 'doctor_name',
		},
		{
			label: 'Doctors Address',
			isOptional: false,
			type: 'text',
			value: 'doctor_address',
		},
		{
			label: 'Doctors Phone',
			isOptional: false,
			type: 'text',
			value: 'doctor_phone',
		},
	],
};

export const FoodReportAgreementsIndexed = indexFieldsByValue(FoodReportAgreementsData);

export const W2ConsentFormGroup: FormGroup = {
	title: 'W-2 Electronic Consent Form',
	fields: [
		{
			label: 'I CONSENT to receiving an electronic copy of my W2',
			isOptional: false, // This field is required
			type: 'boolean',
			value: 'consent_electronic_w2',
		},
		{
			label: 'Entered employee’s email / phone number into RASI',
			isOptional: true,
			type: 'boolean',
			value: 'entered_email_phone_rasi',
		},
		{
			label: 'Sent a Portal Invite to employee / confirm they can access',
			isOptional: true,
			type: 'boolean',
			value: 'portal_invite_sent',
		},
		{
			label: 'Sent the Director of HR a copy of the Consent Form (for non-consent forms only)',
			isOptional: true,
			type: 'boolean',
			value: 'hr_copy_sent',
		},
	],
};

export const W2ConsentFormGroupIndexed = indexFieldsByValue(W2ConsentFormGroup);

export const PCIComplianceAcknowledgmentForm: FormGroup = {
	title: 'PCI Compliance Employee Training Acknowledgment',
	fields: [
		{
			label: 'Acceptable Use Policy',
			isOptional: false,
			type: 'boolean',
			value: 'reviewed_acceptable_use_policy',
		},
		{
			label: 'Credit Card Policy',
			isOptional: false,
			type: 'boolean',
			value: 'reviewed_credit_card_policy',
		},
		{
			label: 'Security Policy (Available upon request)',
			isOptional: false,
			type: 'boolean',
			value: 'reviewed_security_policy',
		},
	],
};

export const PCIComplianceAcknowledgmentFormIndexed = indexFieldsByValue(
	PCIComplianceAcknowledgmentForm
);

export const DirectDepositFormData: FormGroup = {
	title: 'Employee Authorization Agreement for Payroll Direct Deposits',
	fields: [
		{
			label: 'Restaurant Name',
			isOptional: false,
			type: 'text',
			value: 'restaurant_name',
		},
		{
			label: 'Bank Name',
			isOptional: false,
			type: 'text',
			value: 'bank_name',
		},
		{
			label: 'Routing Transit Number',
			isOptional: false,
			type: 'text',
			value: 'routing_number',
		},
		{
			label: 'Account Number',
			isOptional: false,
			type: 'text',
			value: 'account_number',
		},
		{
			label: 'Is this account for',
			isOptional: false,
			type: 'radio',
			value: 'account_type',
			options: [
				{ label: 'Checking', value: 1 },
				{ label: 'Savings', value: 2 },
			],
			style: {
				gridWidth: '1/2',
			},
		},
		{
			label: 'Is this form to change your current payroll direct deposit bank account?',
			isOptional: true,
			type: 'boolean',
			value: 'change_payroll_account',
		},
		{
			label: 'Last 4 Digits of Social Security Number',
			isOptional: false,
			type: 'text',
			value: 'ssn_last_4',
		},
		{
			label: 'Check file',
			isOptional: true,
			type: 'string',
			value: 'check_file',
			autocomplete: 'off',
		},
	],
};

export const DirectDepositFormDataIndexed = indexFieldsByValue(DirectDepositFormData);

export const IUnderstandFormData = {
	title: 'I Understand Form',
	fields: [],
};

export const FullTimeVariableTimeForm: FormGroup = {
	title: 'Full Time/Variable Time Form',
	fields: [
		{
			label: 'I am accepting a full-time position',
			isOptional: false,
			type: 'boolean',
			value: 'accepting_full_time',
			helper: 'A full-time employee is defined as working 30 or more hours per week.',
		},
		{
			label: 'I am accepting a part-time position',
			isOptional: false,
			type: 'boolean',
			value: 'accepting_part_time',
			helper: 'A part-time employee typically works fewer than 30 hours per week on average.',
		},
	],
};
