import { GetGraphSales } from 'core/API/laborsaber';
import { useQuery } from 'react-query';

export const useSalesGraph = (workplaceId, firstBusinessDay, lastBusinessDay) => {
	const queryData = useQuery<any>('employeeGraphSales', async () => {
		const graphResponse = await GetGraphSales({
			workplaceId,
			formatedFirstDay: firstBusinessDay,
			formatedLastDay: lastBusinessDay,
		});

		return graphResponse;
	});

	return queryData;
};
