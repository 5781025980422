import { Box, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { ClipLoader } from 'react-spinners';

export const AllToggle = ({ isLoading, onAllToggle }) => {
	return (
		<>
			{isLoading ? (
				<Box padding="0px 6px" width="80px">
					<ClipLoader color="#841D80" loading={true} />
				</Box>
			) : (
				<FormControlLabel
					sx={{ maxHeight: '38px' }}
					control={<Switch disabled={isLoading} color="secondary" onChange={onAllToggle} />}
					label="All"
				/>
			)}
		</>
	);
};

import { MultiValue } from 'react-select';
import { useMemo } from 'react';

interface UseAutocompleteProps<T> {
	data: T[] | undefined;
	selectedItems: T[];
	setSelectedItems: (items: T[]) => void;
	isLoading: boolean;
	isFetching: boolean;
}

interface SelectOption {
	value: number;
	label: string;
}

export function useAutocomplete<T extends { id: number; name: string }>({
	data,
	selectedItems,
	setSelectedItems,
	isLoading,
	isFetching,
}: UseAutocompleteProps<T>) {
	// Format the data into options for react-select
	const filteredOptions = useMemo<SelectOption[]>(() => {
		if (!data) {
			console.log('No data available');
			return [];
		}

		const options = data
			.map((item) => ({
				value: item.id,
				label: item.name,
			}))
			.filter((option) => !selectedItems.some((selectedItem) => selectedItem.id === option.value));

		console.log('Filtered options:', options);
		return options;
	}, [data, selectedItems]);

	// Format the selected items into the format for react-select
	const value = useMemo<SelectOption[]>(() => {
		const selectedValues = selectedItems.map((item) => ({
			value: item.id,
			label: item.name,
		}));

		console.log('Current selected values:', selectedValues);
		return selectedValues;
	}, [selectedItems]);

	// Handle the change event for react-select
	const handleChange = (selectedOptions: MultiValue<SelectOption>) => {
		const newItems = selectedOptions.map((option) => ({
			id: option.value,
			name: option.label,
		}));
		console.log('Selected new items:', newItems);
		setSelectedItems(newItems as T[]);
	};

	// Handle the "Select All" or "Deselect All" action
	const handleSelectAllToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			console.log('Selecting all items:', filteredOptions);
			setSelectedItems(
				filteredOptions.map((option) => ({
					id: option.value,
					name: option.label,
				})) as T[]
			);
		} else {
			console.log('Deselecting all items');
			setSelectedItems([]);
		}
	};

	return {
		filteredOptions,
		value,
		handleChange,
		handleSelectAllToggle,
		isLoading: isLoading || isFetching,
	};
}
