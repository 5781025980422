import { Conversation, ConversationList, Search } from '@chatscope/chat-ui-kit-react';
import { Box } from '@mui/material';

import {
	getBroadcastGroupMessageCount,
	setSingleBroadcastGroupMessageCount,
} from 'core/API/broadcast';
import { FC, useState } from 'react';
import { BroadcastGroup } from '../utils/BroadcastGroupProvider';
import { getColorFromSeed } from '../utils/chat.utils';

interface ChatConversationListProps {
	data: BroadcastGroup[];
	activeConversation: BroadcastGroup;
	// newMessageConversations: number[];
	setActiveConversation: (bg: BroadcastGroup) => void;
}

interface BroadcastGroupSearchProps {
	value: string;
	onChange: (value: string) => void;
}

export const BroadcastGroupSearch: FC<BroadcastGroupSearchProps> = ({ value, onChange }) => {
	const handleClearClick = () => {
		onChange('');
	};

	return (
		<Box minHeight="50px" padding={0}>
			<Search
				placeholder="Search..."
				value={value}
				onChange={onChange}
				onClearClick={handleClearClick}
				style={{ padding: '0.5rem', height: '100%' }}
			/>
		</Box>
	);
};

export const ChatSidebar: FC<ChatConversationListProps> = ({
	data,
	activeConversation,
	// newMessageConversations,
	setActiveConversation,
}) => {
	const [searchValue, setSearchValue] = useState('');

	const handleSearchChange = (value: string) => {
		setSearchValue(value);
	};

	const handleClick = (broadcastChannel) => {
		setActiveConversation(broadcastChannel);
		setSingleBroadcastGroupMessageCount(
			activeConversation.id,
			activeConversation.number_of_messages
		);
		setSingleBroadcastGroupMessageCount(broadcastChannel.id, broadcastChannel.number_of_messages);
	};

	const filteredBroadcastGroup = data?.filter((conversation) => {
		return conversation.group_name.toLowerCase().includes(searchValue.toLowerCase());
	});

	// return null
	return (
		<div as="Sidebar" className="cs-sidebar cs-sidebar--left">
			<BroadcastGroupSearch value={searchValue} onChange={handleSearchChange} />

			<ConversationList>
				{filteredBroadcastGroup?.map((broadcastGroup, i) => {
					const isActive = activeConversation.id === broadcastGroup.id;

					const { color } = getColorFromSeed(broadcastGroup.group_name, 0.1);
					const { backgroundColor: borderColor } = getColorFromSeed(broadcastGroup.group_name, 1);

					const cachedMessageCount = getBroadcastGroupMessageCount(broadcastGroup.id);

					return (
						<Conversation
							key={i}
							active={activeConversation.id === broadcastGroup.id}
							info={`${broadcastGroup.number_of_messages} messages`}
							name={broadcastGroup.group_name || 'New Group'}
							unreadDot={
								isActive ? false : Boolean(broadcastGroup.number_of_messages - cachedMessageCount)
							}
							onClick={() => handleClick(broadcastGroup)}
							style={{
								backgroundColor: isActive ? 'var(--color-bluegray)' : 'transparent',
								borderLeft: '8px solid ' + borderColor,
								color,
							}}
						>
							<Box
								sx={{
									color,
									padding: 1,
									borderRadius: '4px',
								}}
							>
								{broadcastGroup.group_name || 'New Group'}
							</Box>
						</Conversation>
					);
				})}
				{filteredBroadcastGroup.length === 0 && <Conversation name="No results found" />}
			</ConversationList>
		</div>
	);
};
