import { FC } from 'react';
import { useDrag } from 'react-dnd';
import { formatTimeWithtimezone } from 'shared/utils/dates/formatTimeWithTimezone';

interface INavShift {
	draggable?: boolean;
	event: any;
	timezone: string;
	onClick: () => void;
}

const NavShift: FC<INavShift> = ({ ...props }) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: 'shift',
		item: { ...props.event },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const shiftDateString = `${formatTimeWithtimezone(
		props?.event?.start_at,
		props.timezone,
		'D ddd MMM YYYY, HH:mm'
	)} - ${formatTimeWithtimezone(props?.event?.end_at, props.timezone, 'HH:mm')}`;

	return (
		<div
			ref={dragRef}
			className="external-drop-task"
			style={{
				backgroundColor: props.event?.department?.color?.background_color,
			}}
			onDoubleClick={props.onClick}
		>
			<p className="openShiftDate">{shiftDateString}</p>
			<p className="openShiftRole">{props?.event?.roles[0]?.name ?? 'No role'}</p>
			{isDragging && 'drag'}
		</div>
	);
};

export default NavShift;
