import ClipLoader from 'react-spinners/ClipLoader';
import './Loading.style.scss';

export default function Loading(): JSX.Element {
	return (
		<div className="LoadingBox">
			<ClipLoader color="#841D80" loading={true} size={50} />
		</div>
	);
}
