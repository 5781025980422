import cover from 'assets/icons/Cover.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import statusPublished from 'assets/icons/StatusPublished.svg';
import statusUnpublished from 'assets/icons/StatusUnpublished.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import trade from 'assets/icons/Trade.svg';
import wavingHand from 'assets/icons/WavingHand.svg';
// CSS modules
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import Icons from 'styles/icons.module.scss';

const getShiftIcon = (shift) => {
	if (!shift.scheduled) {
		if (shift.shift_type?.id === 4) {
			return <SvgIcon spriteUrl={wavingHand} className={Icons.shiftIcon} />;
		} else if (shift.shift_type?.id === 2) {
			return <SvgIcon spriteUrl={timeOff} className={Icons.shiftIcon} />;
		} else if (shift.shift_type?.id === 6) {
			return <SvgIcon spriteUrl={trade} className={Icons.shiftIcon} />;
		} else if (shift.shift_type?.id === 3) {
			return <SvgIcon spriteUrl={shiftPick} className={Icons.shiftIcon} />;
		} else if (shift.shift_type?.id === 5) {
			return <SvgIcon spriteUrl={cover} className={Icons.shiftIcon} />;
		}
	} else if (shift.overlap) {
		return <SvgIcon spriteUrl={statusUnpublished} className={Icons.shiftIcon} />;
	} else if (shift.published) {
		return <SvgIcon spriteUrl={statusPublished} className={Icons.shiftIcon} />;
	} else {
		return null;
	}
};

export const ShiftIconComponent = ({ shift }) => {
	const shiftIcon = getShiftIcon(shift);
	return <div>{shiftIcon}</div>;
};
