import style from './CommentComp.module.scss';
import questionStyle from '../QuestionComp/QuestionComp.module.scss';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';
import { Flex } from 'shared/components/Flex.tsx/Flex';

interface CommentComp extends IFooterButtons {
	numberOfQuestion: number;
	questionsLength: number;
	commentHandler: (e: any) => void;
}

export default function CommentComp({
	numberOfQuestion,
	questionsLength,
	commentHandler,
	goNext,
	goBack,
}: CommentComp): JSX.Element {
	return (
		<>
			<div className={questionStyle.Container}>
				<div className={questionStyle.TextInfo}>
					<div className={questionStyle.firstColumn}>
						<div>
							<Flex alignItems="center" justifyContent="space-between">
								<p className={style.infoSmall}>Your opinion is important to us!</p>
								<span className={questionStyle.secondColumn}>
									{numberOfQuestion}/{questionsLength}
								</span>
							</Flex>
						</div>
						<p className={questionStyle.infoBig}>Is there anything you would like us to know, please comment?</p>
					</div>
				</div>
				<textarea
					name="comment"
					className={style.Comment}
					aria-multiline
					placeholder="Leave a comment"
					onChange={commentHandler}
				></textarea>
			</div>

			<FooterButtons
				goNext={goNext}
				goBack={goBack}
				isDisabled={false}
				showPrevious={numberOfQuestion !== 1}
				label="Finish"
			/>
		</>
	);
}
