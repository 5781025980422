import { Dispatch, FC, SetStateAction } from 'react';
import moment from 'moment';

import { IFiltersList } from '../../interfaces/filters.interface';
import { PrioritiesEnum } from '../../enums';

import lowPriority from 'assets/icons/LowPriorityIcon.svg';
import mediumPriority from 'assets/icons/MediumPriorityIcon.svg';
import highPriority from 'assets/icons/HighPriorityIcon.svg';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Actions from './ActionList.module.scss';
import Sections from '../sections.module.scss';
import Buttons from 'styles/buttons.module.scss';

interface IActionList {
	openTakeActionDialog: () => void;
	currMaxPriority: string;
	data: any;
	filters: IFiltersList[];
	setActionDialogData: Dispatch<SetStateAction<any>>;
	timezone: string;
}

const ActionList: FC<IActionList> = ({
	openTakeActionDialog,
	currMaxPriority,
	data,
	filters,
	setActionDialogData,
	timezone,
}) => {
	const displayActionListTitle = (currMaxPriority: string) => {
		switch (currMaxPriority) {
			case PrioritiesEnum.LOW:
				return (
					<>
						<SvgIcon spriteUrl={lowPriority} className={Actions.actionIconLow} />
						<span className={Actions.actionTitleLow}>Low priority</span>
					</>
				);
			case PrioritiesEnum.MEDIUM:
				return (
					<>
						<SvgIcon spriteUrl={mediumPriority} className={Actions.actionIconMedium} />
						<span className={Actions.actionTitleMedium}>Medium priority</span>
					</>
				);
			case PrioritiesEnum.HIGH:
				return (
					<>
						<SvgIcon spriteUrl={highPriority} className={Actions.actionIconHigh} />
						<span className={Actions.actionTitleHigh}>High priority</span>
					</>
				);
			case PrioritiesEnum.URGENT:
				return (
					<>
						<SvgIcon spriteUrl={highPriority} className={Actions.actionIconHigh} />
						<span className={Actions.actionTitleHigh}>Urgent priority</span>
					</>
				);
			default:
				return;
		}
	};

	return (
		<article className={Actions.actionWrapper}>
			{data?.length > 0 ? (
				<>
					<div className={Actions.actionHeader}>{displayActionListTitle(currMaxPriority)}</div>
					<div className={Actions.actionContent}>
						<div className={Sections.table}>
							<div className={Sections.tableHeader}>
								<h6>Source</h6>
								<h6>Time</h6>
								<h6>Message</h6>
								<h6></h6>
							</div>
							<div className={Sections.tableBody}>
								{data
									?.filter((filter: any) =>
										filters.find((item) => item.label === filter.source.label && item.type.active)
									)
									?.map((item: any) => (
										<div key={item.id} className={Sections.tableRow}>
											<div className={Sections.tableRowItem}>
												<h6 className={Actions.actionColTitleMobile}>Source</h6>
												{item?.source?.label}
											</div>
											<div className={Sections.tableRowItem}>
												<h6 className={Actions.actionColTitleMobile}>Time</h6>
												{moment.tz(item?.created_at, timezone).format('DD ddd MMM YYYY, hh:mm:ss A')}
												{/* {moment(item?.created_at)
                                                    .utc()
                                                    .format(
                                                        'DD ddd MMM YYYY, hh:mm:ss A'
                                                    )} */}
											</div>
											<div className={Sections.tableRowItem} style={{ maxWidth: '250px' }}>
												<h6 className={Actions.actionColTitleMobile}>Message</h6>
												{item?.message}
											</div>
											<div className={Sections.tableRowItem}>
												<button
													type="button"
													className={Buttons.btnTextUnderline}
													onClick={() => {
														setActionDialogData(item);
														openTakeActionDialog();
													}}
												>
													Take Action
												</button>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</>
			) : (
				<div className={Actions.actionTextWrapper}>
					<h4>No alerts for now!</h4>
				</div>
			)}
		</article>
	);
};

export default ActionList;
