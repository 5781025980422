import { Box, Typography } from '@mui/material';
import { DirectDepositFormPayload, submitDirectDepositForm } from 'core/API/documents';
import { fileUpload } from 'core/API/events';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import {
	CommonFieldsFormIndexed,
	DirectDepositFormData,
	DirectDepositFormDataIndexed,
} from 'module/documents/data';
import { FileUploadField } from 'module/documents/tabs/HumanResource/components';
import { filterFields } from 'module/documents/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name'],
});

const bankFields = filterFields(DirectDepositFormDataIndexed, {
	include: [
		'bank_name',
		'routing_number',
		'account_number',
		'account_type',
		'change_payroll_account',
	],
});

export const DirectDepositForm = () => {
	const { formik, selectedEmployee, recordFormSubmission } = useDocumentContext();
	const { workplaceId } = useWorkplacesContext();

	const { showToast } = useToastContext();

	const onFileChange = (fieldName: string, file: File) => {
		formik.setFieldValue(fieldName, file);
	};

	const handleSubmit = async () => {
		// Prepare file upload promises
		const uploadFile = async (file: File | null) => {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				return await fileUpload(formData);
			}
			return null;
		};

		const checkFile = await uploadFile(formik.values.check_file);

		const payload: DirectDepositFormPayload = {
			bank_name: formik.values.bank_name,
			routing_number: formik.values.routing_number,
			account_number: formik.values.account_number,
			account_type: String(formik.values.account_type),
			change_payroll_account: formik.values.change_payroll_account,
			employee_file: {
				id: checkFile?.id,
			},
			workplace: {
				id: workplaceId,
			},
			employee: {
				id: selectedEmployee.user.id,
			},
		};

		try {
			await submitDirectDepositForm(payload, workplaceId);
			showToast('success', 'Form has been submitted!');
			recordFormSubmission('directDepositId');
		} catch (error: any) {
			console.error('Error submitting form:', error.error);
			alert('Something went wrong while submitting the form.');
		}
	};

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					Direct Deposit Form
				</Typography>

				<Typography variant="body1">{DirectDepositFormData.title}</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
					<FormAlert severity="error">
						<Typography variant="h6">
							<strong>The business will not process this form without:</strong>
						</Typography>
						- All fields completed!
						<br />- A voided check attached to this form. Deposit slips will not be accepted! (If a
						voided check is not available, please attach a bank-issued direct deposit form.)
					</FormAlert>

					<DynamicFormComponent>
						<GridWrapper field={DirectDepositFormDataIndexed['restaurant_name']} index={0}>
							<FieldRenderer
								field={DirectDepositFormDataIndexed['restaurant_name']}
								formik={formik}
							/>
						</GridWrapper>
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection title="Bank fields">
					<DynamicFormComponent>
						{bankFields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<FormAlert severity="warning">
						<Typography variant="h6" gutterBottom>
							<strong>Authorization for Payroll Direct Deposit</strong>
						</Typography>
						<Typography variant="body1" gutterBottom>
							I hereby authorize Restaurant Accounting Services, Inc. to initiate debit/credit
							entries to my checking/savings account indicated above at the depository financial
							institution (bank) named above, hereinafter called <strong>DEPOSITORY</strong>, and to
							debit/credit the same to such account. <br />I acknowledge that the origination of ACH
							transactions to my account must comply with the provisions of the applicable laws.
						</Typography>
						<Typography variant="body1">
							This authorization is to remain in full force and effect until Restaurant Accounting
							Services, Inc. has received notification of its termination in such time and in such
							manner as to afford Restaurant Accounting Services, Inc. and{' '}
							<strong>DEPOSITORY</strong> a reasonable opportunity to act on it.
						</Typography>
					</FormAlert>
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
					<DynamicFormComponent>
						<GridWrapper field={DirectDepositFormDataIndexed['ssn_last_4']} index={0}>
							<FieldRenderer field={DirectDepositFormDataIndexed['ssn_last_4']} formik={formik} />
						</GridWrapper>
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent flex="1" marginBottom="12px">
						<FormAlert severity="warning">
							Attach direct deposit check or a bank-issued direct deposit form
						</FormAlert>
					</DynamicFormComponent>
					<FileUploadField
						id="check_file"
						existingFilePath={formik.values.check_file?.path}
						onFileChange={(file: File) => onFileChange('check_file', file)}
					/>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={DirectDepositFormData} />
			</Box>

			<DirectDepositFormCertification />
		</>
	);
};

const DirectDepositFormCertification = () => {
	const { formik, isEmployer } = useDocumentContext();

	return (
		<FormGroupSection show={isEmployer}>
			<FormGroupSection
				title="Employer Information"
				isEmployer={true}
				marginTop="24px"
				marginBottom="12px"
			>
				<EmployerFields fieldValues={['employer_title']} />
			</FormGroupSection>
			<Box paddingY="8px">
				<DelayButton
					variant="contained"
					onClick={() => {
						formik.setFieldValue('signature_approved', true);
					}}
					style={{ width: 'max-content' }}
				>
					Employeer sign
				</DelayButton>
			</Box>
		</FormGroupSection>
	);
};
