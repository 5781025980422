import { W4FormPayload, W4Supplement2BPayload } from 'core/API/documents';

export const generateW4FormSubmissionData = (
	formik,
	w4IncludeFields,
	supplement2BIncludeFields,
	userId,
	hrDetails
) => {
	// Prepare the main W4 payload
	const w4FormSpecificValues = w4IncludeFields.reduce(
		(acc, field) => ({
			...acc,
			[field]: formik.values[field],
		}),
		{
			employee: {
				id: userId,
			},
			signature_approved: false,
		} as W4FormPayload
	);

	// Prepare the Supplement 2B payload
	const w4Supplement2BPayload: W4Supplement2BPayload = supplement2BIncludeFields.reduce(
		(acc, field) => ({
			...acc,
			[field]: formik.values[field],
		}),
		{
			employee_form_w4: {
				id: userId, // Assuming you might need to pass the form ID after submitting the W4 form
			},
			employee: {
				id: userId,
			},
			signature_approved: false,
		} as W4Supplement2BPayload
	);

	// Combine and sanitize specific fields
	const formSubmissionData = {
		...w4FormSpecificValues,
		multiple_jobs_or_spouse_works: formik.values.multiple_jobs_or_spouse_works,
		// todo: should be number in future
		filing_status: Number(formik.values.filing_status),
		head_of_household: formik.values.filing_status ? 1 : 0,
		hr: hrDetails ? { id: hrDetails?.id } : null,
	};

	return { formSubmissionData, w4Supplement2BPayload };
};
export const generateMiW4FormSubmissionData = (formik, userId, hrDetails) => {
	const formSubmissionData = {
		employee: {
			id: userId,
		},
		hr: hrDetails ? { id: hrDetails?.id } : null,
		new_employee: Boolean(formik.values.new_employee),
		personal_exceptions: formik.values.personal_exceptions || null,
		deduct_amount: formik.values.deduct_amount || null,
		tax_not_expected: formik.values.tax_not_expected || null,
		wages_exempt: Boolean(formik.values.wages_exempt),
		wages_exempt_explanation: formik.values.wages_exempt_explanation || null,
		home_residence_zone: formik.values.home_residence_zone || null,
		home_residence_zone_explanation: formik.values.home_residence_zone_explanation || null,
		signature_approved: false,
	};

	return { formSubmissionData };
};
