import {
	Avatar,
	ChatContainer,
	ConversationHeader,
	MainContainer,
	MessageInput,
	MessageList,
} from '@chatscope/chat-ui-kit-react';
import { Forum } from '@mui/icons-material';
import { Badge, Box, Button, IconButton, Modal } from '@mui/material';
import {
	BroadcastFile,
	BroadcastMessage,
	Participant,
	createBroadcastMessage,
	uploadBroadcastFile,
} from 'core/API/broadcast';
import { useBroadcastGroups } from 'core/hooks/broadcast';
import { useLocalStorage } from 'core/hooks/useLocalStorage';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC, MouseEvent, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { IPaginatedResponse } from 'types';
import { ChatMessageList } from './components/ChatMessageList';
import { ChatSidebar } from './components/ChatSidebar';
import { CreateGroupModal } from './components/CreateGroupModal';
import { ChatHeaderPopover } from './components/utils/ChatHeaderPopover';
import { BroadcastGroup, BroadcastGroupProvider } from './utils/BroadcastGroupProvider';
import { getColorFromSeed } from './utils/chat.utils';

const validateMessageInput = (message: string) => {
	// Create a temporary DOM element to decode HTML entities
	const tempElement = document.createElement('div');
	tempElement.innerHTML = message;

	// Extract the text content from the decoded HTML
	const decodedMessage = tempElement.textContent || tempElement.innerText || '';

	// Check if the decoded message is empty or contains only spaces and tabs
	return decodedMessage.trim().length === 0;
};

export const ChatKit: FC = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [_activeConversation, setActiveConversation] = useState<BroadcastGroup | null>(null);
	const [messageFiles, setFiles] = useState<FileList | undefined>();

	const [unreadMessages] = useLocalStorage('broadcastGroupMessages', {});

	const unreadMessageCount = Object.keys(unreadMessages).length || 0;

	const { workplaceId } = useWorkplacesContext();
	const auth = useAuth();
	const queryClient = useQueryClient();

	const { data, isLoading } = useBroadcastGroups(workplaceId);

	const [messageInput, setMessageInput] = useState<string>('');
	const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input

	if (isLoading) {
		return null;
	}

	const activeConversation = _activeConversation || data?.[0] || {} as BroadcastGroup;

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleAttachClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click(); // Trigger the file input click
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;

		if (files && files.length > 0) {
			const validFileTypes = [
				'image/jpeg',
				'image/png',
				'application/pdf',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			];

			const isValidFileType = Array.from(files).every((file) => validFileTypes.includes(file.type));

			if (!isValidFileType) {
				alert('Only .jpg, .jpeg, .png, .pdf, and .docx files are allowed.');
				return;
			}

			setFiles(files);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSendMessage = async (
		_innerHtml: string,
		textContent: string,
		_innerText: string,
		_nodes: NodeList
	) => {
		if (!activeConversation) return;

		let broadcastFiles: BroadcastFile[] = [];

		try {
			if (messageFiles && messageFiles?.length > 0) {
				const uploadPromises = Array.from(messageFiles).map(uploadBroadcastFile);
				broadcastFiles = await Promise.all(uploadPromises);
			}
		} catch (error) {
			console.error('Error uploading files:', error);
			return;
		}

		const newMessage = {
			content: textContent,
			author: {
				id: (auth?.user as Participant).id,
			},
			workplace: {
				id: workplaceId,
			},
			broadcast_group: {
				id: activeConversation.id,
			},
			created_at: new Date().toISOString(),
			broadcast_file: broadcastFiles.map(({ id }) => ({ id })),
		} as BroadcastMessage;

		try {
			const sentMessage = await createBroadcastMessage(newMessage);

			setMessageInput('');

			setFiles(undefined);
			console.log('Message sent successfully:', sentMessage);

			queryClient.invalidateQueries('broadcast-groups');
			queryClient.invalidateQueries(`broadcast-messages-${activeConversation.id}`);
		} catch (error) {
			console.error('Error sending message:', error);
			setMessageInput(newMessage.content);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const { backgroundColor } = getColorFromSeed(activeConversation?.group_name || '', 1);

	const isFileSelected = messageFiles?.length > 0;

	const isSendDisabled = validateMessageInput(messageInput) && !isFileSelected;

	return (
		<Box className="chat-kit-container">
			<IconButton aria-label="open chat" onClick={handleClick}>
				{unreadMessageCount ? (
					<Badge
						badgeContent={unreadMessageCount}
						color="error"
						overlap="circular"
						style={{ transform: 'translate(30px, -20px)' }}
					/>
				) : null}
				<Forum color="primary" fontSize="large" />
			</IconButton>
			{/* @ts-ignore */}
			<Modal
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
				sx={{
					width: 'clamp(100%, 700px, 100%)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{!isLoading && (
					<Box>
						<Box px={2} py={1} sx={{ background: 'white' }}>
							<input
								type="file"
								ref={fileInputRef}
								multiple
								style={{ display: 'none' }}
								onChange={handleFileChange}
								accept=".jpg,.jpeg,.png,.pdf,.docx" // This line ensures only the allowed file types can be selected
							/>
							<BroadcastGroupProvider>
								<CreateGroupModal>
									{(handleOpen) => (
										<Box p={2}>
											<Button variant="contained" onClick={handleOpen}>
												Broadcast
											</Button>
										</Box>
									)}
								</CreateGroupModal>
							</BroadcastGroupProvider>
						</Box>
						<MainContainer responsive>
							<ChatSidebar
								activeConversation={activeConversation}
								setActiveConversation={setActiveConversation}
								data={data}
							/>
							<ChatContainer>
								<ConversationHeader>
									<Avatar
										name={activeConversation.group_name}
										style={{ background: backgroundColor }}
									/>
									<ConversationHeader.Content userName={activeConversation.group_name} />
									<ConversationHeader.Actions>
										<ChatHeaderPopover group={activeConversation} />
									</ConversationHeader.Actions>
								</ConversationHeader>
								<ChatMessageList
									as={MessageList}
									setActiveConversation={setActiveConversation}
									activeConversation={activeConversation}
								></ChatMessageList>
								<StyledMessageInput
									as={MessageInput}
									className={messageFiles?.length ? 'has-files' : ''}
									sendDisabled={isSendDisabled}
									sendOnReturnDisabled={isSendDisabled}
									placeholder="Type message here"
									onSend={handleSendMessage}
									value={messageInput}
									sendButton={true}
									fileSize={messageFiles?.length || 0}
									onAttachClick={handleAttachClick}
									onChange={(value: string) => setMessageInput(value)}
								/>
							</ChatContainer>
						</MainContainer>
					</Box>
				)}
			</Modal>
		</Box>
	);
};

const StyledMessageInput = styled(MessageInput)<{ fileSize: number }>`
	position: relative;
	&.has-files::before {
		content: ${({ fileSize }) => (fileSize ? `'${fileSize}'` : '')};
		position: absolute;
		font-size: 0.75rem;
		top: 0px;
		left: 0px;
		color: white;
		background: var(--color-error);
		border: 1px solid red;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		text-align: center;
	}
`;
