import toIsoString from './toIsoString';

const generateTimeString = (date: string, time: string, isWholeday: boolean, isStartDay: boolean): string => {
	const newDate = new Date(date);

	if (isWholeday) {
		if (isStartDay) {
			newDate.setHours(0);
			newDate.setMinutes(0);
		} else {
			newDate.setHours(23);
			newDate.setMinutes(59);
		}
	} else {
		newDate.setHours(parseInt(time.split(':')[0]));
		newDate.setMinutes(parseInt(time.split(':')[1]));
	}

	return toIsoString(newDate);
};

export default generateTimeString;
