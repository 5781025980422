import { Box, Typography } from '@mui/material';
import {
    DelayButton,
    FormAlert,
    FormGroupSection,
    SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
    FieldRenderer,
    GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import {
    CommonFieldsFormIndexed,
    IUnderstandFormData
} from 'module/documents/data';
import { filterFields } from 'module/documents/utils';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name'],
});

export const IUnderstandForm = () => {
	const { formik, isEmployer, selectedEmployee } = useDocumentContext();

	const handleSubmit = () => {
		console.log();
	};

    console.log('selectedEmployee:', selectedEmployee);

    const name = selectedEmployee.user.first_name + ' ' + selectedEmployee.user.last_name;

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{IUnderstandFormData.title}
				</Typography>

				<FormAlert severity="info">
					<Typography variant="body1">
						I, <strong>{name}</strong>, <br/> understand that neither this Employee Handbook, nor any other company communication or
						practice creates an employment contract. An employment-at-will relationship exists by
						which the company or I may terminate my employment at any time, with or without cause.
						The company retains the right to change, modify, or amend, in whole or in part, any of
						its policies, in content or application, at any time. These changes may be implemented
						even if they have not been communicated, reprinted, or substituted in the Employee
						Handbook.
					</Typography>
				</FormAlert>

				<FormAlert severity="info">
					<Typography variant="body1">
						I understand that the company will be fair and consistent in conducting business and
						employee relations as outlined in the Employee Handbook.
					</Typography>
				</FormAlert>

				<FormAlert severity="info">
					<Typography variant="body1">
						I acknowledge receipt of the Employee Handbook, and I have read and understand it. I
						take all responsibility for the knowledge of its content and will review it on a regular
						basis.
					</Typography>
				</FormAlert>

				<FormAlert severity="info">
					<Typography variant="body1">
						I agree to comply, to the best of my ability, with the high standards set per this
						manual for the restaurant and my individual job function.
					</Typography>
				</FormAlert>

				<FormAlert severity="info">
					<Typography variant="body1">
						I understand that my continued employment constitutes my acceptance of any changes that
						may be made in the content or application of the Employee Handbook.
					</Typography>
				</FormAlert>

				<FormAlert severity="info">
					<Typography variant="body1">
						This Employee Handbook, updated March 2022, supersedes all previous Employee Handbooks.
					</Typography>
				</FormAlert>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={IUnderstandFormData} />
			</Box>

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_title']} />
				</FormGroupSection>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Employeer sign
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
