import { FC } from 'react';
import DivisionAutocomplete from '../selects/DivisionAutocomplete';
import WorkplaceAutocomplete from '../selects/WorkplaceAutocomplete';
import { BroadcastTabProps } from './tabUtils';
import { Stack } from '@mui/material';
import RolesAutocomplete from '../selects/RolesAutocomplete';
import { useMe } from 'core/hooks/broadcast';
import { BroadcastRoleType } from 'types';
import OperatingUnitAutocomplete from '../selects/OperatingUnitsAutocomplete';

export const GroupTab: FC<BroadcastTabProps> = () => {
  const { data: me, isLoading } = useMe();

  if (isLoading) {
	return null;
  }

  return (
    <Stack direction="column" gap="2rem">
      {me?.roles.includes(BroadcastRoleType.BROADCAST_WORKPLACE) && <RolesAutocomplete />}
      {me?.roles.includes(BroadcastRoleType.BROADCAST_WORKPLACE) && <WorkplaceAutocomplete />}
      {me?.roles.includes(BroadcastRoleType.BROADCAST_OPERATING_UNIT) && <OperatingUnitAutocomplete />}
      {me?.roles.includes(BroadcastRoleType.BROADCAST_DIVISION) && <DivisionAutocomplete />}
    </Stack>
  );
};
