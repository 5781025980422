import { FC, useState } from 'react';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/ArrowUp.svg';

import { SalesNumber } from 'module/laborsaber/interface/Sales';
import moment from 'moment';
import Buttons from 'styles/buttons.module.scss';
import Sections from '../sections.module.scss';

import { GetSalesDailyLabor } from 'core/API/laborsaber';
import { getBusinessDay } from 'module/laborsaber/utils/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useQuery } from 'react-query';
import ClipLoader from 'react-spinners/ClipLoader';

interface iNetSalse {
	isLoading: boolean;
	sales: SalesNumber;
}

const calculatePercentage = (a: number, b: number) => {
	// (a/b)*100

	const firstParam = a || 0;
	const secondParam = b || 0;

	const result = (firstParam / secondParam) * 100;

	if (!isFinite(a)) return 0;
	if (!isFinite(b)) return 0;

	if (a === 0 && b === 0) return 0;

	if (isFinite(result)) return result;

	return 100;
};

const displayValue = (value: number): string => {
	return value.toFixed(2);
};

const NetSales: FC<iNetSalse> = ({ isLoading, sales: ufanje }) => {
	const { workplaceId, timezone } = useWorkplacesContext();

	const [firstBusinessDay, lastBusinessDay, activeTime] = getBusinessDay(timezone);

	const { isLoading: isSalesGraphLoading, data: laborData } = useQuery(
		`labor-saber-sales/daily/labor?workplace=${workplaceId}}`,
		() => {
			return GetSalesDailyLabor(workplaceId, firstBusinessDay, lastBusinessDay, activeTime);
		}
	);

	const [toggle, setToggle] = useState(true);

	const predictedSalesToday = parseFloat(laborData?.sales.today.predicted_sales);
	const predictedSalesLastWeek = parseFloat(laborData?.sales.last_week.predicted_sales);

	return (
		<article
			style={{
				width: '100%',
				    alignSelf: 'baseline',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'netSales',
			}}
		>
			{isLoading || isSalesGraphLoading ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '400px',
					}}
				>
					<ClipLoader color="#841D80" loading={true} size={50} />
				</div>
			) : (
				<>
					<div className={Sections.header}>
						<h4
							style={{
								marginBottom: '20px',
							}}
						>
							Daily Sales
						</h4>

						<div>
							<span className={Sections.label}>Data up to</span>
							<span className={Sections.labelDarker}>{moment.tz(timezone).format('HH:mm A')}</span>
						</div>
					</div>
					<div>
						<span className={Sections.label}>Compare to same day</span>
						<div className={Buttons.btnToggle}>
							<button
								type="button"
								className={toggle ? Buttons.btnToggleActive : Buttons.btnToggleDefault}
								onClick={() => setToggle((prevValue) => !prevValue)}
								style={{ width: '50%' }}
							>
								Today
							</button>
							<button
								type="button"
								className={!toggle ? Buttons.btnToggleActive : Buttons.btnToggleDefault}
								onClick={() => setToggle((prevValue) => !prevValue)}
								style={{ width: '50%' }}
							>
								Last week
							</button>
						</div>
					</div>
					<div className={Sections.body}>
						<div className={Sections.row}>
							<span className={Sections.label}>Predicted Sales</span>
							<div className={Sections.rowBody}>
								<span className={Sections.rowContent}>
									$ {toggle ? predictedSalesToday.toFixed(2) : predictedSalesLastWeek.toFixed(2)}
								</span>
								<div className={Sections.rowIndicator}>
									{calculatePercentage(predictedSalesToday - predictedSalesLastWeek, predictedSalesLastWeek) >= 0 ? (
										<ArrowUp />
									) : (
										<ArrowDown />
									)}
									<span
										className={
											calculatePercentage(predictedSalesToday - predictedSalesLastWeek, predictedSalesLastWeek) >= 0
												? Sections.rowContentPositive
												: Sections.rowContentNegative
										}
									>
										{displayValue(
											Math.abs(
												calculatePercentage(predictedSalesToday - predictedSalesLastWeek, predictedSalesLastWeek)
											)
										)}
										%
									</span>
								</div>
							</div>
						</div>
						<div className={Sections.row}>
							<span className={Sections.label}>Net Actual Sales</span>
							<div className={Sections.rowBody}>
								<span className={Sections.rowContent}>
									$ {toggle ? ufanje.sales.today.net_sales.toFixed(2) : ufanje.sales.last_week.net_sales.toFixed(2)}
								</span>
							</div>
						</div>
					</div>
				</>
			)}
		</article>
	);
};

export default NetSales;
