import { ROUTES } from 'module/navigation/enums/routes.enums';
import { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

interface PrivateRouteProps {
	path: RouteProps['path'];
	component: React.ElementType;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, children, ...rest }) => {
	const { isLoggedin } = useAuth();
	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return isLoggedin() ? (
					children ?? <Component {...routeProps} />
				) : (
					<Redirect
						to={{
							pathname: ROUTES.LOGIN,
						}}
					/>
				);
			}}
		/>
	);
};
