import { Box, Stack } from '@mui/material';
import { useDivision } from 'core/hooks/broadcast';
import { FC } from 'react';
import Select from 'react-select';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';

import { useAutocomplete } from 'module/laborsaber/components/Chat/components/selects/components';
import { ClipLoader } from 'react-spinners';
import { AllCheckbox } from './AllCheckbox';
import { Division } from 'core/interfaces/userInterface';

const DivisionAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const { data: divisions, isLoading, isFetching } = useDivision();

	const {
		filteredOptions,
		value,
		handleChange,
		handleSelectAllToggle,
		isLoading: selectIsLoading,
	} = useAutocomplete<Division>({
		data: divisions?.items,
		selectedItems: broadcastGroup.division,
		setSelectedItems: (newDivisions) =>
			setBroadcastGroup((prev) => ({ ...prev, division: newDivisions })),
		isLoading,
		isFetching,
	});

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllCheckbox isDisabled={selectIsLoading} onChange={handleSelectAllToggle} />
			<Box sx={{ width: '100%' }}>
				<Select
					isMulti
					value={value}
					isLoading={selectIsLoading}
					onChange={handleChange}
					options={filteredOptions}
					placeholder="Select Divisions"
					classNamePrefix="select"
					isClearable
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? 'grey' : '',
						}),
						multiValue: (baseStyles) => ({
							...baseStyles,
							backgroundColor: '#2196f3',
							color: 'white',
						}),
						multiValueLabel: (baseStyles) => ({
							...baseStyles,
							color: 'white',
						}),
					}}
				/>
				{selectIsLoading && <ClipLoader color="#841D80" loading={true} />}
			</Box>
		</Stack>
	);
};

export default DivisionAutocomplete;
