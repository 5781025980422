import moment from 'moment';
import { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { GetOneShift, PutOneShift } from 'core/API/shifts';
import Loading from 'core/components/Loading/Loading';
import CenterModal from 'core/components/Modal/CenterModal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import useModal from 'core/hooks/useModal';
// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

// const mockData = {
//     firstEmployee: 'John Doe',
//     firstShift: 'Wednesday, 14 July 2021, 9 AM - 5 PM',
//     secondEmployee: 'Lucy Blunt',
//     secondShift: 'Thursday, 15 July 2021, 9 AM - 5 PM',
//     status: 'Lucy accepted to trade shifts',
// };

interface ITradeRequestModal {
	closeModal: () => void;
	requestData: any;
}

const TradeRequestModal: FC<ITradeRequestModal> = (props) => {
	const { showToast } = useToastContext();

	const { open: isModalOpen, openModal, closeModal } = useModal();

	const [firstShiftDate, setFirstShiftDate] = useState<string>('');

	const { data: requestData, isLoading: shiftLoading } = useQuery(
		['trade-shift', props?.requestData?.shift_id],
		() => GetOneShift(props.requestData.shift_id!),
		{
			onSuccess: (data) => {
				const firstShiftDateString = `${moment.utc(data.start_at).format('dddd, D MMMM YYYY, H A')} - ${moment
					.utc(data.end_at)
					.format('H A')}`;

				setFirstShiftDate(firstShiftDateString);
			},
		}
	);

	const { mutate: updateOneShift, isLoading: updateShiftLoading } = useMutation(PutOneShift);

	const approveHandler = () => {
		if (props.requestData.overlap) {
			openModal();
		} else {
			updateShift();
		}
	};

	const updateShift = () => {
		const { id, time_card_id, ...rest } = requestData!;
		updateOneShift(
			{
				id,
				body: {
					...rest,
					scheduled: true,
					denied: false,
					time_card_id: parseInt(time_card_id), //  idk
				},
			},
			{
				onSuccess: () => {
					showToast('success', 'Request aproved!');
					props.closeModal();
				},
				onError: () => {
					showToast('error');
					props.closeModal();
				},
			}
		);
	};

	const denyHandler = () => {
		const { id, time_card_id, ...rest } = requestData!;
		updateOneShift(
			{
				id,
				body: {
					...rest,
					denied: true,
					time_card_id: parseInt(time_card_id), //  idk
				},
			},
			{
				onSuccess: () => {
					showToast('success', 'Request denied!');
					props.closeModal();
				},
				onError: () => {
					showToast('error');
					props.closeModal();
				},
			}
		);
	};

	return shiftLoading ? (
		<Loading />
	) : (
		<>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>First employee (made the request)</p>
				<p className={Forms.formLockedText}>
					{requestData?.user.first_name} {requestData?.user.last_name}
				</p>
			</div>
			<div className={Forms.formGroup}>
				<p className={Forms.formLabel}>First shift</p>
				<p className={Forms.formLockedText}>{firstShiftDate}</p>
			</div>
			{/* <div className={Forms.formGroup}>
                <p className={Forms.formLabel}>Second employee</p>
                <p className={Forms.formLockedText}>
                    {mockData.secondEmployee}
                </p>
            </div>
            <div className={Forms.formGroup}>
                <p className={Forms.formLabel}>Second shift</p>
                <p className={Forms.formLockedText}>{mockData.secondShift}</p>
            </div>
            <div className={Forms.formGroup}>
                <p className={Forms.formLabel}>Status</p>
                <p className={Forms.formLockedText}>{mockData.status}</p>
            </div> */}
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Leave a note for employee"
					maxLength={160}
					disabled
					value={requestData?.note}
				></textarea>
			</div>
			<div className={Forms.formFooterSplit}>
				{updateShiftLoading ? (
					<Loading />
				) : (
					<>
						<button className={Buttons.btnSuccess} onClick={approveHandler}>
							Approve
						</button>
						<button className={Buttons.btnError} onClick={denyHandler}>
							Deny
						</button>
					</>
				)}
			</div>

			{isModalOpen ? (
				<CenterModal
					title="Conflict warning"
					close={() => {
						closeModal();
					}}
					render={() => (
						<div>
							<h5>If accepted, this shift will cause a conflict with an already existing shift on the same user</h5>
							<div className={Forms.formFooterSplit}>
								<button className={Buttons.btnSuccess} onClick={updateShift}>
									Continue
								</button>
								<button className={Buttons.btnError} onClick={closeModal}>
									Back
								</button>
							</div>
						</div>
					)}
				/>
			) : null}
		</>
	);
};

export default TradeRequestModal;
