import { FC } from 'react';
import { useIsFetching, useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'module/navigation/enums/routes.enums';

import useModal from 'core/hooks/useModal';
import { useAuth } from 'module/auth/context/AuthContext';

import { GetNotifications } from 'core/API/notification';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import ClipLoader from 'react-spinners/ClipLoader';

import { fetchTopPriority } from 'core/API/situationRoom';

import logo from 'assets/icons/Mabel_Logo_Blue.png';
import hamburger from 'assets/icons/Hamburger.svg';
import highPriority from 'assets/icons/HighPriorityIcon.svg';
import lowPriority from 'assets/icons/LowPriorityIcon.svg';
import mediumPriority from 'assets/icons/MediumPriorityIcon.svg';

import MobileMenu from 'core/components/MobileMenu/MobileMenu';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import NavigationMenu from 'components/featured/navigation/NavigationMenu';
import NotificationsMenu from 'components/featured/navigation/NotificationsMenu';

import { DocumentModal } from 'module/documents/DocumentModal';
import { ChatKit } from 'module/laborsaber/components/Chat/ChatKit';
import Header from './HeaderBar.module.scss';

const HeaderPriorityIcon = ({ isCurrMaxPriorityFetched, currMaxPriority }) => {
	const isFetching = useIsFetching();

	if (isFetching) {
		return <ClipLoader color="#841D80" loading={!!isFetching} size={25} />;
	}
	switch (isCurrMaxPriorityFetched && currMaxPriority?.top_priority) {
		case 'Low':
			return <SvgIcon spriteUrl={lowPriority} className={Header.navIconLow} />;
		case 'Medium':
			return <SvgIcon spriteUrl={mediumPriority} className={Header.navIconMedium} />;
		case 'High':
			return <SvgIcon spriteUrl={highPriority} className={Header.navIconHigh} />;
		case 'Urgent':
			return <SvgIcon spriteUrl={highPriority} className={Header.navIconHigh} />;
		default:
			return null;
	}
};

const HeaderBar: FC = (): JSX.Element => {
	const auth = useAuth();

	const { workplaceId } = useWorkplacesContext();

	const { open: isMobileMenuOpen, openModal: openMobileMenu, closeModal: closeMobileMenu } = useModal();

	const { data: currMaxPriority, isFetched: isCurrMaxPriorityFetched } = useQuery(['top-priority'], () =>
		fetchTopPriority(workplaceId)
	);

	const { data } = useQuery('notifications', GetNotifications, {

	});

	return (
		<>
			<header className={Header.header}>
				<SvgIcon spriteUrl={hamburger} className={Header.navHamIcon} onClick={() => openMobileMenu()} />
				<img src={logo} alt="BYOD logo" className={Header.headerLogo} />
				<nav className={Header.nav}>
					<ul className={Header.navList}>
						<li className={Header.navListItem}>
							<NavLink
								exact
								to={auth.isManager ? ROUTES.MANAGER : ROUTES.EMPLOYEE}
								className={Header.navListLink}
								activeClassName={Header.navListLinkActive}
							>
								Hyperscheduler
							</NavLink>
						</li>
						{auth.isManager && (
							<li className={Header.navListItem}>
								<NavLink
									to={ROUTES.LABORSABER}
									className={Header.navListLink}
									activeClassName={Header.navListLinkActive}
								>
									Laborsaber
								</NavLink>
							</li>
						)}
						{auth.isManager && (
							<li className={Header.navListItem} style={{ display: 'flex', width: 'max-content' }}>
								<NavLink
									to={ROUTES.SITUATION_ROOM}
									className={Header.navListLink}
									activeClassName={Header.navListLinkActive}
									style={{ marginRight: '10px' }}
								>
									Situation room
								</NavLink>
								<HeaderPriorityIcon
									isCurrMaxPriorityFetched={isCurrMaxPriorityFetched}
									currMaxPriority={currMaxPriority}
								/>
							</li>
						)}
					</ul>
					<section className={Header.headerInfo}>
						<DocumentModal />
						<ChatKit />
						<NotificationsMenu data={data} />
						<NavigationMenu />
					</section>
				</nav>
			</header>
			{isMobileMenuOpen && <MobileMenu close={() => closeMobileMenu()} />}
		</>
	);
};

export default HeaderBar;
