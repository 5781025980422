import moment from 'moment';
import getTimeWithTimezone from 'shared/utils/dates/formatTimeWithTimezone';

export const getScheduleDateRange = (timeView, timezone, isMobile) => {
	let firstday, lastday;

	if (timeView === 'day' && !isMobile) {
		const today = moment();

		firstday = today.startOf('day');
		lastday = today.endOf('day');
	} else {
		const curr = moment().startOf('day');
		const first = curr.date() - curr.day() + 1;

		firstday = curr.date(first);
		lastday = moment(firstday).endOf('week');
	}

	return getTimeWithTimezone(firstday, lastday, timezone);
};
