import { Box, FormControl, FormLabel } from '@mui/material';
import { useParticipants } from 'core/hooks/broadcast'; // Adjust the import path as needed
import { FC, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';

interface ParticipantsAutocompleteProps {
	multiple?: boolean;
}

const ParticipantsAutocomplete: FC<ParticipantsAutocompleteProps> = ({ multiple = false }) => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const textFieldRef = useRef<HTMLInputElement>(null);
	const { data: participants, isLoading, isFetching } = useParticipants();
	const [isChangingParticipant, setIsChangingParticipant] = useState(false);

	const handleParticipantChange = (selectedOption: any) => {
		setIsChangingParticipant(true);

		setBroadcastGroup((prev) => ({
			...prev,
			participants: multiple
				? selectedOption.map((option: any) => participants?.find((p) => p.id === option.value))
				: [participants?.find((p) => p.id === selectedOption?.value) || null].filter(Boolean),
		}));

		setTimeout(() => {
			setIsChangingParticipant(false);
		}, 2000);

		if (textFieldRef.current) {
			textFieldRef.current.value = '';
		}
	};

	const filteredOptions = useMemo(() => {
		if (!participants) {
			return [];
		}

		return participants
			.sort((a, b) => a.last_name.localeCompare(b.last_name))
			.map((participant) => ({
				value: participant.id,
				label: `${participant.first_name} ${participant.last_name}`,
			}));
	}, [participants]);

	console.log('filteredOptions:', filteredOptions);

	const value = useMemo(() => {
		if (!broadcastGroup.participants || !filteredOptions) {
			return multiple ? [] : null;
		}

		return multiple
			? filteredOptions.filter((option) =>
					broadcastGroup.participants?.some(
						(selectedParticipant) => selectedParticipant.id === option.value
					)
				)
			: filteredOptions.find((option) =>
					broadcastGroup.participants?.some(
						(selectedParticipant) => selectedParticipant.id === option.value
					)
				);
	}, [broadcastGroup.participants, filteredOptions, multiple]);

	return (
		<FormControl component="fieldset" fullWidth>
			<FormLabel
				component="legend"
				sx={{ minHeight: '35px', display: 'flex', alignItems: 'center', gap: '10px' }}
			>
				Select Participants
				{(isLoading || isFetching) && <ClipLoader color="#841D80" loading={true} />}
			</FormLabel>
			<Box mt={1}>
				<Select
					isMulti={multiple}
					className="basic-single"
					classNamePrefix="select"
					value={value}
					isLoading={isLoading || isFetching || isChangingParticipant}
					onChange={handleParticipantChange}
					isClearable={true}
					isSearchable={true}
					name="participants"
					placeholder="Select Participants"
					options={filteredOptions}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? 'grey' : '',
						}),
					}}
				/>
			</Box>
		</FormControl>
	);
};

export default ParticipantsAutocomplete;
