import { MbscCalendarEvent, MbscResource } from '@mobiscroll/react';
import { useWorkspaceResources } from 'core/hooks/broadcast';
import { useAuth } from 'module/auth/context/AuthContext';
import { useMemo } from 'react';

export const useUserResources = (workplaceId: number, myEvents: MbscCalendarEvent[]) => {
	const auth = useAuth();

	const { data: usersData } = useWorkspaceResources(workplaceId);

	const myResources = useMemo<MbscResource[]>(() => {
		const usersListResource: MbscResource[] = [];
		const firstUser: MbscResource[] = [];

		usersData?.forEach((value) => {
			if (value.user.id === auth.user.id) {
				firstUser.push({
					id: value.user.id,
					name: `${value.user.first_name} ${value.user.last_name}`,
					role: value.role,
				});
			} else {
				usersListResource.push({
					id: value.user.id,
					name: `${value.user.first_name} ${value.user.last_name}`,
					role: value.role,
				});
			}
		});

		usersListResource.sort((a, b) => {
			let userAshift = null;
			let userBshift = null;

			myEvents.forEach((shift) => {
				if (shift.resource === a.id) {
					userAshift = shift;
				} else if (shift.resource === b.id) {
					userBshift = shift;
				}
			});

			if (userAshift !== null && userBshift !== null) {
				const firstShiftStart = new Date(userAshift.start).getTime();
				const secondShiftStart = new Date(userBshift.start).getTime();

				const titleA = userAshift.title.split(' ');
				const titleB = userBshift.title.split(' ');

				return (
					userAshift?.role?.localeCompare(userBshift.role) ||
					firstShiftStart - secondShiftStart ||
					titleB[titleB.length - 1].localeCompare(titleA[titleA.length - 1])
				);
			}

			// if last user does not have a shift, push them to the end
			if (userBshift === null) {
				return -1;
			}

			return 0;
		});

		return [...firstUser, ...usersListResource]!;
	}, [usersData, auth.user.id, myEvents]);

	return myResources;
};
