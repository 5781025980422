import { useMobileDetect } from 'core/hooks/useMobileDetect';
import { useEffect, useRef, useState } from 'react';
import DesktopPage from '../DesktopPage/DesktopPage';
import MobilePage from '../MobilePage/MobilePage';
import './EmployeeScheduler.style.scss';

import cover from 'assets/icons/Cover.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import wavingHand from 'assets/icons/WavingHand.svg';
import moment from 'moment';

import { MbscCalendarEvent, MbscEventClickEvent } from '@mobiscroll/react';
import { GetOneShift, PutOneShift } from 'core/API/shifts';
import Modal from 'core/components/Modal/Modal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useModalManagement } from 'core/hooks/useModalManagement';
import useQueryParams from 'core/hooks/useQueryParams';
import { useSchedules } from 'core/hooks/useSchedules';
import { useAuth } from 'module/auth/context/AuthContext';
import CoverRequestModal from 'module/schedule/Modals/CoverRequestModal/CoverRequestModal';
import TimeOffRequestModal from 'module/schedule/Modals/TimeOffRequestModal/TimeOffRequestModal';
import Navigation from 'module/schedule/components/Navigation/Navigation';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import getTimeWithTimezone from 'shared/utils/dates/formatTimeWithTimezone';
import AvailabilityModal from '../AvailabilityModal/AvailabilityModal';
import ShiftPickRequest from '../ShiftPickRequest/ShiftPickRequest';

const EmployeeScheduler = (): JSX.Element => {
	const { timezone, setIsTradeSelected } = useWorkplacesContext();
	const { showToast } = useToastContext();

	const { query } = useQueryParams();

	const auth = useAuth();

	const { isModalOpen, openModal, closeModal } = useModalManagement();

	const isMobile = useMobileDetect().isMobile();

	const queryClient = useQueryClient();

	const [menuView, setMenuView] = useState<'regular' | 'trade'>('regular');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');

	const selectedAvailability = useRef<MbscCalendarEvent | null>(null);

	const [tradeMyShift, setTradeMyShift] = useState<MbscCalendarEvent | null>(null);
	const [tradeOtherShift, setTradeOtherShift] = useState<MbscCalendarEvent | null>(null);

	const [shiftPickID, setShiftPickID] = useState(0);

	const {
		events: myEvents,
		eventsUnassigned: myEventsUnassigned,
		refetchSchedules,
	} = useSchedules({
		fromDate,
		toDate,
		view: query.view,
	});

	const { mutate: updateOneShift } = useMutation(PutOneShift);

	const { refetch: refetchMyShiftData, isLoading: isLoadingMyShift } = useQuery(
		['trade-shift', tradeMyShift?.shift_id],
		() => GetOneShift(tradeMyShift?.shift_id),
		{
			enabled: false,
			onSuccess: (data) => {
				const { id, time_card_id, ...rest } = data;
				if (tradeOtherShift && tradeMyShift) {
					updateOneShift(
						{
							id,
							body: {
								...rest,
								scheduled: false,
								user: { id: +tradeOtherShift.resource! },
								shift_type: { id: 6 },
								note: `${tradeMyShift.title} made trade request with ${tradeOtherShift?.title}`,
								time_card_id: parseInt(time_card_id),
							},
						},
						{
							onSuccess: () => {
								showToast('success', 'Shift trade requested!');
								setMenuView('regular');
								setIsTradeSelected(false);
								refetchSchedules();
							},
							onError: () => {
								showToast('error');
							},
						}
					);
				}
			},
			onError: (e: any) => {
				showToast('error', `${e?.error}`);
			},
		}
	);

	const { refetch: refetchOtherShiftData, isLoading: isLoadingOtherShift } = useQuery(
		['trade-shift', tradeOtherShift?.shift_id],
		() => GetOneShift(tradeOtherShift?.shift_id),
		{
			enabled: false,
			onSuccess: (data) => {
				const { id, time_card_id, ...rest } = data;
				if (tradeMyShift) {
					updateOneShift(
						{
							id,
							body: {
								...rest,
								scheduled: false,
								user: { id: +tradeMyShift.resource! },
								shift_type: { id: 6 },
								note: `${tradeMyShift.title} made trade request with ${tradeOtherShift?.title}`,
								time_card_id: parseInt(time_card_id),
							},
						},
						{
							onSuccess: () => {
								showToast('success', 'Shift trade requested!');
								setMenuView('regular');
								setIsTradeSelected(false);
								refetchSchedules();
							},
							onError: () => {
								showToast('error');
							},
						}
					);
				}
			},
			onError: (e: any) => {
				showToast('error', `${e?.error}`);
			},
		}
	);

	useEffect(() => {
		let firstday, lastday;

		if (query.view === 'day' && !isMobile) {
			const today = moment();

			firstday = today.startOf('day');
			lastday = today.endOf('day');
		} else {
			const curr = moment().startOf('day');
			const first = curr.date() - curr.day() + 1;

			firstday = curr.date(first);
			lastday = moment(firstday).endOf('week');
		}

		updateTimeRange(firstday, lastday);
	}, [query.view]);

	const updateTimeRange = (firstday: Date, lastday: Date) => {
		const [formatedFirstDay, formatedLastDay] = getTimeWithTimezone(firstday, lastday, timezone);

		setFromDate(formatedFirstDay);
		setToDate(formatedLastDay);
	};

	const handleOpenShiftClick = (ID: number) => {
		setShiftPickID(ID);
		openModal('shiftPickModal');
	};

	const closeShiftPickModalHandler = () => {
		closeModal('shiftPickModal');
		refetchSchedules();
	};

	const closeTimeOffModalHandler = () => {
		closeModal('timeOffModal');
		refetchSchedules();
	};

	const closeCoverModalHandler = () => {
		closeModal('coverModal');
		refetchSchedules();
	};

	const closeAvailibilityModalHandler = () => {
		selectedAvailability.current = null;
		closeModal('availabilityModal');
		refetchSchedules();
	};

	const tradeMenuClickHandler = () => {
		setMenuView('trade');
		setIsTradeSelected(true);
	};

	const handleTradePickingShift = (shift: any) => {
		if (tradeMyShift === null) {
			// select first shift (My shift)
			if (auth.user.id === shift.resource) {
				setTradeMyShift(shift);
			} else {
				showToast('error', 'Please first select your shift!');
			}
		} else if (tradeOtherShift === null) {
			setTradeOtherShift(shift);
		}
	};

	const startTradeShiftHandler = () => {
		if (tradeMyShift && tradeOtherShift) {
			refetchMyShiftData();
			refetchOtherShiftData();
		} else {
			showToast('error', 'Select other shift!');
		}
	};

	const onScheduleEventClick = (e: MbscEventClickEvent) => {
		if (e.event.editable) {
			selectedAvailability.current = e.event;
			prefetchShift(e.event.shift_id).then(() => {
				openModal('availabilityModal');
			});
		}
	};

	const prefetchShift = async (shiftID) => {
		// The results of this query will be cached like a normal query
		await queryClient.prefetchQuery({
			queryKey: `shifts/${shiftID}`,
			queryFn: () => {
				if (shiftID === null) {
					return null;
				}
				return GetOneShift(shiftID);
			},
		});
	};

	return (
		<section className="Content">
			<div className="draggable">
				{isMobile ? (
					<MobilePage
						myEvents={myEvents}
						updateTimeRange={updateTimeRange}
						handleOpenShiftClick={handleOpenShiftClick}
						handleTradePickingShift={handleTradePickingShift}
					/>
				) : (
					<DesktopPage
						events={myEvents}
						onScheduleEventClick={onScheduleEventClick}
						updateTimeRange={updateTimeRange}
						handleTradePickingShift={handleTradePickingShift}
						tradeMyShift={tradeMyShift}
					/>
				)}
			</div>
			<Navigation
				menuView={menuView}
				setMenuView={setMenuView}
				schedulesUnassigned={myEventsUnassigned}
				handleOpenShiftClick={handleOpenShiftClick}
				tradeMyShift={tradeMyShift}
				refetchSchedules={refetchSchedules as () => any}
				tradeOtherShift={tradeOtherShift}
				isLoadingMyShift={isLoadingMyShift}
				isLoadingOtherShift={isLoadingOtherShift}
				startTradeShiftHandler={startTradeShiftHandler}
				setTradeMyShift={setTradeMyShift}
				setTradeOtherShift={setTradeOtherShift}
				tradeMenuClickHandler={tradeMenuClickHandler}
				openAvailabilityModal={() => openModal('availabilityModal')}
				openTimeOffModal={() => openModal('timeOffModal')}
				openCoverModal={() => openModal('coverModal')}
			/>
			{isModalOpen('availabilityModal') ? (
				<Modal
					icon={wavingHand}
					title="Request availability"
					close={() => closeModal('availabilityModal')}
					render={() => (
						<AvailabilityModal
							fromDate={fromDate}
							defaultData={selectedAvailability.current}
							onClose={closeAvailibilityModalHandler}
						/>
					)}
				/>
			) : null}
			{isModalOpen('shiftPickModal') ? (
				<Modal
					icon={shiftPick}
					title="Shift pick"
					close={() => closeModal('shiftPickModal')}
					render={() => (
						<ShiftPickRequest shiftPickID={shiftPickID} onClose={closeShiftPickModalHandler} />
					)}
				/>
			) : null}

			{isModalOpen('timeOffModal') ? (
				<Modal
					icon={timeOff}
					title="Request time off"
					close={() => closeModal('timeOffModal')}
					render={() => (
						<TimeOffRequestModal role={auth.isManager} onClose={closeTimeOffModalHandler} />
					)}
				/>
			) : null}

			{isModalOpen('coverModal') ? (
				<Modal
					icon={cover}
					title="Request cover"
					close={() => closeModal('coverModal')}
					render={() => (
						<CoverRequestModal
							role={auth.isManager}
							onClose={closeCoverModalHandler}
							shifts={myEvents}
						/>
					)}
				/>
			) : null}
		</section>
	);
};

export default EmployeeScheduler;
