import {
	Box,
	BoxProps,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	GridProps,
	Radio,
	RadioGroup,
	Switch,
	TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import { FieldHelper } from 'module/documents/components/components';
import { FormField } from 'module/documents/data';
import { useDocumentContext } from 'module/documents/context';
import { FC } from 'react';
import { Person } from 'types';
import { rest } from 'lodash';

const validate = (values, fields) => {
	const errors = {};
	fields.forEach((field) => {
		if (!field.isOptional && !values[field.value]) {
			errors[field.value] = 'Required';
		} else if (field.type === 'date' && isNaN(Date.parse(values[field.value]))) {
			errors[field.value] = 'Invalid date';
		}
	});
	return errors;
};

// Function to convert gridWidth to Material-UI Grid's xs value
const getGridWidth = (gridWidth) => {
	switch (gridWidth) {
		case '1/1':
			return 12;
		case '1/2':
			return 6;
		case '1/3':
			return 4;
		case '1/4':
			return 3;
		default:
			return 12; // Default to full width
	}
};

export const FieldRenderer: FC<{ field: FormField; formik: FormikProps<any> }> = ({
	field,
	formik,
}) => {
	if (!field || !field.type) {
		console.error('Field type is required');
		return null;
	}

	if (field.type === 'hidden') {
		return null;
	}

	switch (field.type) {
		case 'radio':
			return (
				<FormControl component="fieldset">
					<FormLabel component="legend">
						{field.label} {field.isOptional ? '' : <span style={{ color: 'red' }}>*</span>}
					</FormLabel>
					<FieldHelper text={field.helper} />
					<RadioGroup
						aria-label={field.label}
						name={field.value}
						value={formik.values[field.value] || ''}
						onChange={formik.handleChange}
					>
						{field.options?.map((option, i) => (
							<FormControlLabel
								key={i}
								value={option.value}
								control={<Radio />}
								label={option.label}
							/>
						))}
					</RadioGroup>
				</FormControl>
			);
		case 'boolean':
			return (
				<>
					<FieldHelper text={field.helper} />
					<FormControlLabel
						control={
							<Switch
								required={!field.isOptional}
								checked={formik.values[field.value] || false}
								onChange={(event) => formik.setFieldValue(field.value, event.target.checked)}
								name={field.value}
								color="info"
							/>
						}
						label={field.label}
					/>
				</>
			);
		default:
			return (
				<>
					<TextField
						required={!field.isOptional}
						fullWidth
						id={field.value}
						name={field.value}
						label={field.label}
						variant="standard"
						type={field.type}
						value={formik.values[field.value]}
						onChange={formik.handleChange}
						error={formik.touched[field.value] && Boolean(formik.errors[field.value])}
						helperText={formik.touched[field.value] && formik.errors[field.value]}
						InputLabelProps={{ shrink: true }}
						autoComplete={field.autocomplete}
						disabled={field.disableField ? !formik.values[field.disableField] : false}
					/>
					<FieldHelper text={field.helper} />
				</>
			);
	}
};

export const GridWrapper: FC<
	{ field: FormField; index?: number; children: React.ReactNode } & GridProps
> = ({ field, children, ...rest }) => (
	<Grid item width="100%" md={getGridWidth(field.style?.gridWidth)} {...rest}>
		<Box
			sx={{
				p: 2,
				borderRadius: 1,
			}}
		>
			{children}
		</Box>
	</Grid>
);

export interface IDynamicFormComponentProps extends BoxProps {
	// formData: FormGroup;
	alternateBackgrounds?: boolean;
	defaultData?: Partial<Person & { phone: string }>;
}

export const DynamicFormComponent: FC<IDynamicFormComponentProps> = ({ children, ...rest }) => {
	const { formik } = useDocumentContext();

	return (
		// @ts-ignore
		<Box as="form" onSubmit={formik.handleSubmit} {...rest}>
			<Grid container rowGap={1}>
				{children}
			</Grid>
		</Box>
	);
};

export default DynamicFormComponent;
