import { Box, Button, FormControl, FormLabel, Link, Typography, RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMe, useWorkspaceResources, useWorkplaces } from 'core/hooks/broadcast';
import { useDocumentContext } from 'module/documents/context';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEmployeeFormData } from 'module/documents/hooks';
import { CreateEmployeePostPayload, submitCreateEmployeeForm } from 'core/API/documents';
import { useQueryClient } from 'react-query';
import { FormGroupSection } from 'module/documents/components/components';

interface WorkspaceUserAutocompleteProps {
	setEnableHumanResource: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

  export const WorkspaceUserAutocomplete: FC<WorkspaceUserAutocompleteProps> = ({ setEnableHumanResource, setActiveTabIndex }) => {
	const queryClient = useQueryClient();
	const { data: usersData } = useWorkspaceResources();
	const { selectedEmployee, setSelectedEmployee, formik, newEmployee, setNewEmployee, newEmployeeCreated, setNewEmployeeCreated, handleModalClose } = useDocumentContext();
	const [isChangingUser, setIsChangingUser] = useState(false);
	const [enableSelectEmployee, setenableSelectEmployee] = useState(false);
	const [disableUserForm, setDisableUserForm] = useState(false);
	const [employeeState, setEmployeeState] = useState('new_employee');
	const { isLoading } = useEmployeeFormData();
	const { data: usersWorkplaces } = useWorkplaces();

	const handleUserChange = (selectedOption: any) => {
		setIsChangingUser(true);

		if (!selectedOption) {
			setSelectedEmployee(null);
			return;
		}

		setSelectedEmployee(usersData[selectedOption.index] || null);

		setTimeout(() => {
			setIsChangingUser(false);
		}, 2000);
	};

	const filteredOptions = useMemo(() => {
		if (!usersData) {
			return [];
		}

		return usersData.map((user, i) => {
			return {
				index: i,
				value: user.user.id,
				email: user.user.email,
				label: `${user.user.first_name} ${user.user.last_name} (${user.user.email})`,
			};
		});
	}, [usersData]);

	interface FormErrors {
		firstName: boolean;
		lastName: boolean;
		email: boolean;
		existingEmployee: boolean;
	}
	
	const [formErrors, setFormErrors] = useState<FormErrors>({
		firstName: false,
		lastName: false,
		email: false,
		existingEmployee: false,
	});
	
	const createEmployee = async (e) => {
		e.preventDefault();
		const errors = {
			firstName: false,
			lastName: false,
			email: false,
			existingEmployee: false,
		};
		let isValid = true;
	
		if (employeeState === "new_employee" || employeeState === "re_hiring_employee") {
			if (!newEmployee.firstName.trim()) {
				errors.firstName = true;
				isValid = false;
			}
			if (!newEmployee.lastName.trim()) {
				errors.lastName = true;
				isValid = false;
			}
			if (!newEmployee.email.trim()) {
				errors.email = true;
				isValid = false;
			}
		} else if (employeeState === "existing_employee") {
			if (!selectedEmployee) {
				errors.existingEmployee = true;
				isValid = false;
			}
		}
	
		setFormErrors(errors);
	
		if (isValid) {
			if (employeeState === "new_employee" || employeeState === "re_hiring_employee") {
				const payload: CreateEmployeePostPayload = {
					first_name: newEmployee.firstName,
					middle_initial: newEmployee.middleName,
					last_name: newEmployee.lastName,
					email: newEmployee.email,
					active: true,
				};

				try {
					const response = await submitCreateEmployeeForm(payload); 
					console.log("Response:", response);
					// showToast('success', 'Form submitted successfully!');
					queryClient.invalidateQueries(['employee-form/create-employee']);
					setNewEmployeeCreated(true);
					setActiveTabIndex(1); // Set human resource tab active
					setEnableHumanResource(false);
				} catch (error) {
					console.error('Error submitting form:', error);
					// showToast('error', 'Error submitting form!');
				}
			} else {
				setActiveTabIndex(1); //setting human resource tab active
				setEnableHumanResource(false);
			}
		}
	};

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewEmployee((prev) => ({ ...prev, [name]: value }));
    };

	const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
	  const newValue = event.target.value;
	  setEmployeeState(newValue);
  
	  if (newValue === "existing_employee") {
		setEnableHumanResource(false);
		setenableSelectEmployee(true);
		setDisableUserForm(true);
		//SET FORM VALUES
		formik.setFieldValue('employer_business_name', usersWorkplaces?.items?.[0]?.name);
		formik.setFieldValue('employer_city', usersWorkplaces?.items?.[0]?.city);
		formik.setFieldValue('employer_ein', usersWorkplaces?.items?.[0]?.identifier); 
		formik.setFieldValue('employer_business_address', (usersWorkplaces?.items?.[0]?.street_address + ", " + usersWorkplaces?.items?.[0]?.state + ", " + usersWorkplaces?.items?.[0]?.city + ", " + usersWorkplaces?.items?.[0]?.country));
		formik.setFieldValue('employer_state', usersWorkplaces?.items?.[0]?.state);
		formik.setFieldValue('employer_zip_code', usersWorkplaces?.items?.[0]?.postcode);
		formik.setFieldValue('employer_title', usersWorkplaces?.items?.[0]?.division?.name);
	  } else {
		setDisableUserForm(false);
		setEnableHumanResource(true);
		setenableSelectEmployee(false);
		if (newEmployeeCreated) {
			setEnableHumanResource(false);
		}
		//SET FORM VALUES
		formik.setFieldValue('employer_business_name', "");
		formik.setFieldValue('employer_city', "");
		formik.setFieldValue('employer_ein', ""); 
		formik.setFieldValue('employer_business_address', "");
		formik.setFieldValue('employer_state', "");
		formik.setFieldValue('employer_zip_code', "");
		formik.setFieldValue('employer_title', "");
	  }
	};

	return (
		<>	
			<FormGroupSection title=""
								isEmployer={true}
								marginTop="14px"
								marginBottom="12px">
				<RadioGroup
					aria-label="document-options"
					name="document-options-group"
					row
					value={employeeState}
					onChange={handleSelection}
				>
					<FormControlLabel value="new_employee" control={<Radio />} label="New Employee" />
					<FormControlLabel value="re_hiring_employee" control={<Radio />} label="Re-hire Employee" />
					<FormControlLabel value="existing_employee" control={<Radio />} label="Existing Employee" />
				</RadioGroup>
				<FormControl component="fieldset" fullWidth>
					{(enableSelectEmployee && 
						<>
							<FormLabel
								component="legend"
								sx={{ minHeight: '35px', display: 'flex', alignItems: 'center', gap: '10px' }}
							>
								Select Employee
								{isLoading && <ClipLoader color="#841D80" loading={true} />}
							</FormLabel>
							<Box mt={1}>
								<Select
									className="basic-single"
									classNamePrefix="select"
									isDisabled={!enableSelectEmployee}
									defaultValue={
										selectedEmployee
											? {
													value: selectedEmployee.id,
													email: selectedEmployee.user.email,
													label: `${selectedEmployee.user.first_name} ${selectedEmployee.user.last_name} (${selectedEmployee.user.email})`,
												}
											: null
									}
									isLoading={isLoading || isChangingUser}
									onChange={handleUserChange}
									// isClearable={true}
									isSearchable={true}
									name="employees"
									placeholder="Select Employee"
									options={filteredOptions}
									styles={{
										control: (base, state) => ({
											...base,
											borderColor: formErrors.existingEmployee ? 'red' : state.isFocused ? 'blue' : base.borderColor,
											boxShadow: formErrors.existingEmployee ? '0 0 5px red' : 'none',
										}),
										option: (baseStyles, _state) => {
											return baseStyles;
										},
									}}
								/>
								{formErrors.existingEmployee && <Typography color="error">Please select an existing employee</Typography>}
							</Box>
						</>
					)}
					</FormControl>
				{(!disableUserForm || (!enableSelectEmployee && (newEmployee.firstName || newEmployee.lastName || newEmployee.email)) ) && (<Box component="form" mt={2}>
					<FormControl component="fieldset">

					<Box sx={{ display: "flex", gap: 2 }}>
						<TextField onChange={handleChange} helperText={formErrors.firstName ? "First Name is required" : ""} label="First Name" error={formErrors.firstName} name="firstName" value={newEmployee.firstName} fullWidth />
						<TextField onChange={handleChange} label="Middle Name" name="middleName" value={newEmployee.middleName} fullWidth />
						<TextField onChange={handleChange} error={formErrors.lastName} helperText={formErrors.lastName ? "Last Name is required" : ""} label="Last Name" name="lastName" value={newEmployee.lastName} fullWidth />
						</Box>
						<TextField onChange={handleChange} error={formErrors.email} helperText={formErrors.email ? "Email is required" : ""} label="Email Address" name="email" type="email" value={newEmployee.email} fullWidth sx={{ mt: 2 }} />
					</FormControl>
				</Box> )}
				<Box 
					sx={{ 
						display: "flex", 
						justifyContent: "flex-end", 
						alignItems: "center", 
						gap: 2, // Adds spacing between buttons
						mt: 3, 
						mb: 2 
					}}
				>
					{/* Next Button - Styled to Fit Better */}
					<Button 
						variant="contained" 
						onClick={createEmployee} 
						sx={{ 
							backgroundColor: "#1976D2", 
							color: "white",
							padding: "12px 24px",
							borderRadius: "8px",
							fontSize: "16px",
							fontWeight: "bold",
							display: "flex",
							alignItems: "center",
							gap: "8px",
							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
							transition: "all 0.3s ease",
							"&:hover": {
								backgroundColor: "#115293",
								transform: "translateY(-2px)"
							}
						}}
					>
						Next 
						<span style={{ fontSize: "18px" }}>➜</span>
					</Button>
					<Button
						variant="outlined"
						onClick={handleModalClose}
						sx={{
							backgroundColor: "#E0E0E0",
							color: "#333",
							padding: "12px 16px",
							borderRadius: "8px",
							fontSize: "16px",
							fontWeight: "bold",
							display: "flex",
							alignItems: "center",
							gap: "8px",
							border: "1px solid #BDBDBD",
							transition: "all 0.3s ease",
							"&:hover": {
								backgroundColor: "#D6D6D6",
								transform: "translateY(-2px)"
							}
						}}
					>
						<Close /> Cancel
					</Button>
				</Box>
			</FormGroupSection>
		</>
	);
};

interface FileUploadProps {
	id?: string;
	label?: string;
	existingFilePath?: Record<any, any>;
	onFileChange?: (file: File) => void;
} 

export const FileUploadField: FC<FileUploadProps> = ({ id, existingFilePath, label, onFileChange }) => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const { selectedEmployee } = useDocumentContext();

	const { data: me } = useMe();

	console.log('existingFilePath:', existingFilePath);

	const isActiveUserSelected = selectedEmployee.user.id === me.id;

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			setSelectedFile(file);
			onFileChange(file);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mb={2}>
			{label && <Typography variant="h6">{label}</Typography>}

			{existingFilePath && (
				<Link href={process.env.REACT_APP_URL + existingFilePath} target="_blank">
					<img
						src={process.env.REACT_APP_URL + existingFilePath}
						style={{ marginBottom: 20, maxHeight: '200px' }}
					/>
				</Link>
			)}

			<input
				accept=".jpg,.jpeg,.png,.pdf,.docx"
				style={{ display: 'none' }}
				id={id}
				type="file"
				onChange={handleFileChange}
			/>
			<label htmlFor={id}>
				<Button
					variant="contained"
					component="span"
					startIcon={<CloudUploadIcon />}
					sx={{ marginRight: 2 }}
					disabled={!isActiveUserSelected}
				>
					Choose File
				</Button>

				{selectedFile && (
					<Typography variant="body1">{selectedFile.name}</Typography>
				)}
			</label>
		</Box>
	);
};
