import { Box, Stack } from '@mui/material';
import { useOperatingUnits } from 'core/hooks/broadcast';

import {
	AllToggle,
	useAutocomplete,
} from 'module/laborsaber/components/Chat/components/selects/components';
import { selectStyles } from 'module/laborsaber/components/Chat/components/selects/styles';
import { FC } from 'react';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { useBroadcastGroup } from '../../utils/BroadcastGroupProvider';
import { OperatingUnit } from 'core/interfaces/userInterface';

const OperatingUnitAutocomplete: FC = () => {
	const { setBroadcastGroup, broadcastGroup } = useBroadcastGroup();
	const {
		data: operatingUnits,
		isLoading,
		isFetching,
	} = useOperatingUnits(broadcastGroup.division.map((division) => division.id));

	const {
		filteredOptions,
		value,
		handleChange,
		handleSelectAllToggle,
		isLoading: selectIsLoading,
	} = useAutocomplete<OperatingUnit>({
		data: operatingUnits?.items,
		selectedItems: broadcastGroup.operating_unit,
		setSelectedItems: (newOperatingUnits) =>
			setBroadcastGroup((prev) => ({ ...prev, operating_unit: newOperatingUnits })),
		isLoading,
		isFetching,
	});

	return (
		<Stack spacing={2} direction="row" alignItems="center">
			<AllToggle isLoading={selectIsLoading} onAllToggle={handleSelectAllToggle} />
			<Box sx={{ width: '100%' }}>
				<Select
					isMulti
					value={value}
					isLoading={selectIsLoading}
					onChange={handleChange}
					options={filteredOptions}
					placeholder="Select Operating Units"
					classNamePrefix="select"
					isClearable
					styles={selectStyles}
				/>
				{selectIsLoading && <ClipLoader color="#841D80" loading={true} />}
			</Box>
		</Stack>
	);
};

export default OperatingUnitAutocomplete;
