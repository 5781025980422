import { Box, Typography } from '@mui/material';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	GridWrapper,
	FieldRenderer,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import {
	CommonFieldsFormIndexed,
	FoodReportAgreementsData,
	FoodReportAgreementsIndexed,
	MIW4FormDataIndexed,
} from 'module/documents/data';
import { filterFields } from 'module/documents/utils';

const wageFields = filterFields(MIW4FormDataIndexed, {
	include: [
		'tax_not_expected',
		'wages_exempt',
		'wages_exempt_explanation',
		'home_residence_zone',
		'home_residence_zone_explanation',
	],
});

const historyOfExposure = filterFields(FoodReportAgreementsIndexed, {
	include: [
		'exposed_to_outbreak',
		'date_of_outbreak',
		'ecoli_exposure',
		'norovirus_exposure',
		'norovirus_outbreak_date',
		'ecoli_outbreak_date',
		'hepatitis_a_exposure',
		'hepatitis_a_outbreak_date',
		'typhoid_fever_exposure',
		'typhoid_fever_outbreak_date',
		'shigellosis_exposure',
		'shigellosis_outbreak_date',
	],
});

const historyOfExposure2 = filterFields(FoodReportAgreementsIndexed, {
	include: ['live_with_person_diagnosed', 'date_of_onset_household_member'],
});
const historyOfExposure3 = filterFields(FoodReportAgreementsIndexed, {
	include: ['household_member_in_outbreak_area', 'date_of_onset_outbreak_area'],
});
const doctorContact = filterFields(FoodReportAgreementsIndexed, {
	include: ['doctor_name', 'doctor_address', 'doctor_phone'],
});

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name'],
});

const step2Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['address', 'city', 'state'],
});

export const FoodReportAgreements = () => {
	const { formik, isEmployer } = useDocumentContext();

	const handleSubmit = () => {
		console.log();
	};
	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					Food Report Agreements
				</Typography>

				<Typography variant="body1">
					The purpose of this interview is to inform conditional employees and food employees to
					advise the person in charge of past and current conditions described so that the person in
					charge can take appropriate steps to preclude the transmission of foodborne illness.
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection title="Enter Personal Information">
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent>
						{step2Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection marginTop="12px">
					<Typography variant="h5" mb="8px">
						History of exposures
					</Typography>
					<FormAlert>Explanation</FormAlert>
					<DynamicFormComponent>
						{historyOfExposure.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<FormAlert severity="info">
						Do you live in the same household as a person diagnosed with Norovirus, Shigellosis,
						Typhoid Fever, Hepatitis A, or illness due to E. coli O157:H7 or other EHEC/STEC?
					</FormAlert>
					<DynamicFormComponent>
						{historyOfExposure2.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<FormAlert severity="info">
						Do you have a household member attending or working in a setting where there is a
						confirmed disease outbreak of Norovirus, Typhoid Fever, Shigellosis, EHEC/STEC
						Infection, or Hepatitis A?
					</FormAlert>
					<DynamicFormComponent>
						{historyOfExposure3.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<FormAlert severity="info">Address of</FormAlert>
					<DynamicFormComponent>
						{historyOfExposure3.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<FormAlert severity="info">
						Contact details of your health practitioner or doctor
					</FormAlert>
					<DynamicFormComponent>
						{doctorContact.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>

				<SignAndSubmit onSubmit={handleSubmit} formData={FoodReportAgreementsData} />

				<FormGroupSection show={isEmployer}>
					<FormGroupSection
						title="Employer Information"
						isEmployer={true}
						marginTop="24px"
						marginBottom="12px"
					>
						<EmployerFields
							fieldValues={[
								'employer_name',
							]}
						/>
					</FormGroupSection>
					<FormAlert>
						Under penalties of perjury, I declare that this certificate, to the best of my knowledge
						and belief, is true, correct, and complete.
					</FormAlert>
					<Box paddingY="8px">
						<DelayButton
							variant="contained"
							onClick={() => {
								formik.setFieldValue('signature_approved', true);
							}}
							style={{ width: 'max-content' }}
						>
							Sign employee certification
						</DelayButton>
					</Box>
				</FormGroupSection>
			</Box>
		</>
	);
};
