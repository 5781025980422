import React from 'react';
import './style.scss';

interface ILoginInput {
	value: string;
	placeholder: string;
	hasError?: boolean | string;
	errorMsg?: string;
	width?: number;
	disabled?: boolean;
	name: string;
	type: string;
	onChange: (e: React.ChangeEvent<unknown>) => unknown;
	onBlur: (e: React.ChangeEvent<unknown>) => unknown;
}

export const LoginInput: React.FC<ILoginInput> = ({
	placeholder,
	hasError,
	errorMsg,
	disabled,
	...rest
}: ILoginInput) => {
	return (
		<div className="InputRoot">
			<label htmlFor="input" className="InputPlaceholder">
				{placeholder}
			</label>
			<input {...rest} disabled={disabled} className="InputContent"></input>
			{hasError ? <p className="InputErrorBox">{errorMsg}</p> : null}
		</div>
	);
};
