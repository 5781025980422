import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { useDocumentContext } from 'module/documents/context';
import { EmployerFieldsForm } from 'module/documents/data';
import { useFieldFilter } from 'module/documents/utils';
import { FC } from 'react';

interface EmployerFieldsProps {
	fieldValues: string[];
	excludeFields?: string[];
}

export const EmployerFields: FC<EmployerFieldsProps> = ({
	fieldValues = [],
	excludeFields = [],
}) => {
	const { formik, isEmployer } = useDocumentContext();

	const data = useFieldFilter(EmployerFieldsForm, fieldValues, excludeFields);

	if (!isEmployer) {
		return null;
	}
	return (
		<DynamicFormComponent>
			{data.map((field, index) => (
				<GridWrapper key={index} field={field} index={index}>
					<FieldRenderer field={field} formik={formik} />
				</GridWrapper>
			))}
		</DynamicFormComponent>
	);
};
