import { parseISO, format, differenceInMinutes, isSameDay } from 'date-fns';

import { createTheme } from '@mui/material/styles';
import { getContrastRatio } from '@mui/material';
import { BroadcastType, BroadcastSettings } from 'core/API/broadcast';
import { BroadcastGroup } from './BroadcastGroupProvider';

const colorGroups = {
	gray: ['#718096', '#4A5568', '#2D3748'],
	red: ['#FC8181', '#F56565', '#E53E3E', '#C53030', '#9B2C2C', '#822727', '#63171B'],
	orange: [
		'#FEEBC8',
		'#FBD38D',
		'#F6AD55',
		'#ED8936',
		'#DD6B20',
		'#C05621',
		'#9C4221',
		'#7B341E',
		'#652B19',
	],
	yellow: [
		'#FEFCBF',
		'#FAF089',
		'#F6E05E',
		'#ECC94B',
		'#D69E2E',
		'#B7791F',
		'#975A16',
		'#744210',
		'#5F370E',
	],
	green: [
		'#C6F6D5',
		'#9AE6B4',
		'#68D391',
		'#48BB78',
		'#38A169',
		'#2F855A',
		'#276749',
		'#22543D',
		'#1C4532',
	],
	teal: [
		'#B2F5EA',
		'#81E6D9',
		'#4FD1C5',
		'#38B2AC',
		'#319795',
		'#2C7A7B',
		'#285E61',
		'#234E52',
		'#1D4044',
	],
	cyan: [
		'#C4F1F9',
		'#9DECF9',
		'#76E4F7',
		'#0BC5EA',
		'#00B5D8',
		'#00A3C4',
		'#0987A0',
		'#086F83',
		'#065666',
	],
	purple: [
		'#E9D8FD',
		'#D6BCFA',
		'#B794F4',
		'#9F7AEA',
		'#805AD5',
		'#6B46C1',
		'#553C9A',
		'#44337A',
		'#322659',
	],
	pink: ['#FED7E2', '#FBB6CE'],
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#dc004e',
		},
		...colorGroups,
	},
});

// Function to format date to a readable day label
export const formatDate = (date) => format(parseISO(date), 'MMMM d, yyyy');

export const getChatLikeDate = (date) => {
	const parsedDate = parseISO(date);
	const now = new Date();
	const diffInMinutes = differenceInMinutes(now, parsedDate);

	if (diffInMinutes < 1) return 'moments ago';
	if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
	const diffInHours = Math.floor(diffInMinutes / 60);
	if (diffInHours < 24) return `${diffInHours} hours ago`;

	return format(parsedDate, 'MMM d, yyyy h:mm a');
};

// Function to convert hex color to RGBA
function hexToRgba(hex, opacity) {
	let r = 0,
		g = 0,
		b = 0;
	if (hex.length === 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);
	} else if (hex.length === 7) {
		r = parseInt(hex[1] + hex[2], 16);
		g = parseInt(hex[3] + hex[4], 16);
		b = parseInt(hex[5] + hex[6], 16);
	}
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Function to get the best contrast text color
function getBestContrastTextColor(backgroundColor) {
	const textColors = [theme.palette.common.black, theme.palette.common.white, '#000000', '#FFFFFF'];
	let bestContrast = 0;
	let bestColor = textColors[0];

	textColors.forEach((color) => {
		const contrast = getContrastRatio(backgroundColor, color);
		if (contrast > bestContrast) {
			bestContrast = contrast;
			bestColor = color;
		}
	});

	return bestColor;
}

export function getColorFromSeed(seed, opacity = 0.25) {
	// Simple hash function to generate a number from the seed
	let hash = 0;
	for (let i = 0; i < seed.length; i++) {
		hash = seed.charCodeAt(i) + ((hash << 5) - hash);
	}
	// Flatten the color groups into a single array
	const allColors = Object.values(colorGroups).flat();
	// Choose a color from the palette
	const color = allColors[Math.abs(hash) % allColors.length];
	const rgbaColor = hexToRgba(color, opacity);

	// Get the best contrast text color
	const contrastText = getBestContrastTextColor(color);

	return {
		backgroundColor: rgbaColor,
		color: contrastText,
	};
}

const colors = [
	'#FF5733', // Vivid Red
	'#FFBD33', // Vivid Orange
	'#FFF033', // Vivid Yellow
	'#75FF33', // Vivid Lime Green
	'#33FFBD', // Vivid Cyan
	'#33D4FF', // Vivid Sky Blue
	'#3375FF', // Vivid Blue
	'#7533FF', // Vivid Indigo
	'#BD33FF', // Vivid Purple
	'#FF33F0', // Vivid Magenta
	'#FF3375', // Vivid Pink
];

export function getColorByAuthor(name) {
	const hash = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
	return colors[hash % colors.length];
}

export const getBroadcastSettings = (type: BroadcastType): BroadcastSettings => {
	return {
		organization_broadcast: type === 'organization',
		division_broadcast: type === 'division',
		operating_unit_broadcast: type === 'operating_unit',
		workplace_broadcast: type === 'workplace',
	};
};

interface Level {
	levelName: string;
	items: { id: number; name?: string }[];
}

export const generateGroupName = (broadcastGroup: Partial<BroadcastGroup>): string => {
	console.log('broadcastGroup:', broadcastGroup);
	const levels: Level[] = [
		{ levelName: 'Organization', items: broadcastGroup.organization || [] },
		{ levelName: 'Division', items: broadcastGroup.division || [] },
		{ levelName: 'Operating Unit', items: broadcastGroup.operating_unit || [] },
		{ levelName: 'Workplace', items: broadcastGroup.workplace || [] },
		{ levelName: 'Participants', items: broadcastGroup.participants || [] },
	];

	for (const level of levels) {
		if (level.items.length > 0) {
			if (level.levelName === 'Participants') {
				// @ts-ignore
				return `Group: ${level.items.map((item) => item.last_name).join(', ')}`;
			}
			return `${level.levelName}: ${level.items[0].name}`;
		}
	}

	return 'Unnamed Group'; // Default name if no levels have a name
};
