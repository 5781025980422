import { Box, Typography } from '@mui/material';
import SignatureBox from 'components/featured/documents/SignatureBox';
import {
	EmployeeFormDetailsPayload,
	submitEmployeeFormDetails,
	uploadSignatureImage,
} from 'core/API/documents';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useAuth } from 'module/auth/context/AuthContext';
import { FormGroupSection, SignAndSubmit } from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';

import { useDocumentContext } from 'module/documents/context';
import { CommonFieldsForm, CommonFieldsFormIndexed } from 'module/documents/data';
import { filterFields } from 'module/documents/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC } from 'react';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name', 'date_of_birth', 'email', 'phone'],
});

const step2Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['address', 'city', 'state', 'zip_code'],
});

const step3Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['ssn', 'drivers_license_number'],
});

const CommonFields: FC = () => {
	const { formik, recordFormSubmission } = useDocumentContext();

	const auth = useAuth();
	const { showToast } = useToastContext();

	const { workplaceId } = useWorkplacesContext();

	// console.log('data:', data);

	// useEffect(() => {
	// 	if (data?.items) {
	// 		formik.setValues({
	// 			...formik.values,
	// 			...last(data.items),
	// 		});
	// 	}
	// }, [isLoading]);

	const handleSignatureSubmit = async (file) => {
		try {
			const response = await uploadSignatureImage(file);
			console.log('Signature uploaded successfully:', response);
			formik.setFieldValue('employee_signature', response.id);
		} catch (error) {
			console.error('Error uploading signature:', error);
			formik.setFieldValue('employee_signature', null);
		}
	};

	const handleCommonFieldsSubmit = () => {
		const excludeFields = ['first_name', 'last_name', 'email'];

		const defaultFormSpecificValues: Partial<EmployeeFormDetailsPayload> = {
			employee_signature_file: {
				id: formik.values.employee_signature,
			},
			employee: {
				id: auth.user.id,
			},
			author: {
				id: auth.user.id,
			},
		};

		const formSpecificValues = CommonFieldsForm.fields.reduce((acc, iter) => {
			if (excludeFields.includes(iter.value)) {
				return acc;
			}

			if (formik.values[iter.value]) {
				return { ...acc, [iter.value]: formik.values[iter.value] };
			}

			return acc;
		}, defaultFormSpecificValues as EmployeeFormDetailsPayload);

		submitEmployeeFormDetails(formSpecificValues, workplaceId).then(
			(response) => {
				showToast('success', 'Form has been submitted!');
				recordFormSubmission("employeeFormId");
				console.debug('response:', response);
			},
			(error) => {
				console.error('Error submitting form:', error);
				showToast('error', 'Something went wrong !');
			}
		);
	};

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					Basic Employee Information
				</Typography>

				<Typography variant="body1">
					This is a basic form to collect information about the employee.
				</Typography>
			</Box>

			<SignatureBox
				onSubmit={handleSignatureSubmit}
				existingPath={formik.values.internal_signature_file}
			/>
			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection title="Step 1: Enter Personal Information">
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection title="Step 2: Residence Information">
					<DynamicFormComponent>
						{step2Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection title="Step 3: Additional information">
					<DynamicFormComponent>
						{step3Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<SignAndSubmit
				onSubmit={handleCommonFieldsSubmit}
				formData={CommonFieldsForm}
				extraValidation={[{ value: formik.values.employee_signature, field: 'Employee Signature' }]}
			/>
		</>
	);
};

export default CommonFields;
