import { Box, Typography } from '@mui/material';
import { PCIFormPayload, submitPCIForm } from 'core/API/documents';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import { PCIComplianceAcknowledgmentForm } from 'module/documents/data';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

export const PCIComplianceForm = () => {
	const { formik, isEmployer, selectedEmployee } = useDocumentContext();
	const { workplaceId } = useWorkplacesContext();
	const { showToast } = useToastContext();

	const handleSubmit = async () => {
		const payload: PCIFormPayload = {
			reviewed_acceptable_use_policy: formik.values.reviewed_acceptable_use_policy,
			reviewed_credit_card_policy: formik.values.reviewed_credit_card_policy,
			reviewed_security_policy: formik.values.reviewed_security_policy,

			workplace: {
				id: workplaceId,
			},
			employee: {
				id: selectedEmployee.user.id,
			},
		};

		try {
			await submitPCIForm(payload);
			showToast('success', 'Form has been submitted!');
		} catch (error: any) {
			console.error('Error submitting form:', error.error);
			alert('Something went wrong while submitting the form.');
		}
	};

	const name = selectedEmployee.user.first_name + ' ' + selectedEmployee.user.last_name;

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{PCIComplianceAcknowledgmentForm.title}
				</Typography>

				<Typography variant="body1">
					This certificate is for Michigan income tax withholding purposes only.
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
					<FormAlert severity="info">
						I <strong>{name}</strong>, have received, reviewed and understand the Employee Training
						Documents for PCI Compliance for [Company Name]. I agree to observe the terms and
						conditions of this training. I understand that failure to comply may result in
						disciplinary action and could jeopardize my organizations PCI-DSS compliance standing.
					</FormAlert>

					<FormAlert severity="info">Documents reviewed:</FormAlert>

					<DynamicFormComponent>
						{PCIComplianceAcknowledgmentForm.fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={PCIComplianceAcknowledgmentForm} />
			</Box>

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_title']} />
				</FormGroupSection>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Employeer sign
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
