import { apiService } from 'core/services/apiService';
import { CommonGetParams, IPaginatedResponse } from 'types';

export const uploadSignatureImage = async (file: File) => {
	const formData = new FormData();
	formData.append('image', file);

	const response = await apiService.postUpload('/employee-form/signiture/upload', formData);

	return response.data;
};

export interface EmployeeFormDetailsPayload {
	employee: { id: number };
	date_of_birth: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	ssn: string;
	drivers_license_number: string;
	author: { id: number };
	employee_signature_file: { id: number };
}

export interface I9FormPayload {
	employee: {
		id: number;
	};
	hr?: {
		id: number;
	};
	workplace: {
		id: number;
	};
	first_date_employment: string;
	immigration_status: number;
	uscis_a_number?: string;
	i94_admission_number?: string;
	foreign_passport_number?: string;
	authorization_expiration_date?: string;
	translator_assisted?: boolean;
	employee_rehire?: boolean;
	signature_approved: boolean;

	// Non-Citizen Document Fields
	employee_form_i9_not_citizen_a?: { id: number };
	employee_form_i9_not_citizen_b?: { id: number };
	employee_form_i9_not_citizen_c?: { id: number };
}

export interface I9SupplementAPayload {
	employee_form_i9: {
		id: number;
	};
	employee: {
		id: number;
	};
	rehire_date: string;
	document_title: string;
	document_number: string;
	expiration_date: string;
	employer_title: string;
	employer_business_name: string;
	employer_business_address: string;
	employer_city: string;
	employer_state: string;
	employer_zip_code: string;
	signature_approved: boolean;
}

// Define the payload interface
export interface W4FormPayload {
	employee: {
		id: number;
	};
	multiple_jobs_or_spouse_works: number;
	filing_status: number;
	number_of_children: number;
	number_of_dependents: number;
	other_income: number;
	deductions: number;
	extra_withholding: number;
	signature_approved: boolean;
}

// Define the payload interface
export interface MiW4FormPayload {
	employee: {
		id: number;
	};
	new_employee: boolean;
	personal_exceptions: number;
	deduct_amount: number;
	tax_not_expected: boolean;
	wages_exempt: boolean;
	wages_exempt_explanation: string;
	home_residence_zone: boolean;
	home_residence_zone_explanation: string;
	signature_approved: boolean;
}

export interface I9SupplementTranslatorPayload {
	employee_form_i9: {
		id: number;
	};
	first_name: string;
	middle_initial: string;
	last_name: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	signature_approved?: boolean;
}

export interface W4Supplement2BPayload {
	employee_form_w4: {
		id: number;
	};
	employee: {
		id: number;
	};
	two_jobs_amount: number;
	three_jobs_line2_a: number;
	three_jobs_line2_b: number;
	three_jobs_line2_c: number;
	pay_periods_per_year: number;
	amount_step4_c: number;
	signature_approved: boolean;
}

export interface FileUploadResponse {
	id: number;
	name: string;
	path: string;
	extension: string;
	size: string;
	mime_type: string;
}

export const fileUpload = async (file: any): Promise<FileUploadResponse> => {
	const { ...data } = apiService.responseHandler(
		await apiService.postUpload(`employee-form/file/upload`, file)
	);

	return data;
};

export interface I9NonCitizenPayload {
	title: string;
	authority: string;
	number: string;
	expiration_date: string;
	employee_file: {
		id: number;
	};
}
export interface I9NonCitizenResponse {
	id: number;
	identifier: string;
	title: string;
	authority: string;
	number: string;
	expiration_date: string;
	employee_file: FileUploadResponse;
	synced: true;
}

export const submitI9NonCitizen = async (
	payload: I9NonCitizenPayload
): Promise<I9NonCitizenResponse> => {
	let data = null;
	try {
		data = apiService.responseHandler(
			await apiService.post(`employee-form/i9/non-citizen`, payload)
		);
	} catch (error) {
		//
	}

	return data;
};

export interface PCIFormPayload {
	workplace: {
		id: number;
	};
	employee: {
		id: number;
	};
	reviewed_acceptable_use_policy: boolean;
	reviewed_credit_card_policy: boolean;
	reviewed_security_policy: boolean;
	signature_approved?: boolean;
}

export const submitPCIForm = async (payload: PCIFormPayload): Promise<any> => {
	const { ...data } = apiService.responseHandler(
		await apiService.postUpload(`employee-form/pci-compliance`, payload)
	);

	return data;
};

export interface DirectDepositFormPayload {
	workplace: {
		id: number;
	};
	employee: {
		id: number;
	};
	bank_name: string;
	routing_number: string;
	account_number: string;
	account_type: string;
	change_payroll_account: boolean;
	employee_file: {
		id: number;
	};
	signature_approved?: boolean;
}

// **************************** POST FUNCTIONS ***************************

export const submitEmployeeFormDetails = async (
	payload: EmployeeFormDetailsPayload,
	workplaceId: number
) => {
	const response = await apiService.post('/employee-form/details', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

export const submitI9FormDetails = async (payload: I9FormPayload, workplaceId: number) => {
	const response = await apiService.post('/employee-form/i9', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

export const submitI9SupplementADetails = async (payload: I9SupplementAPayload) => {
	const response = await apiService.post('/employee-form/i9/supplement', payload);
	return response.data;
};

// API function to submit W4 form details
export const submitW4FormDetails = async (payload: W4FormPayload, workplaceId: number) => {
	const response = await apiService.post('/employee-form/w4', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

// API function to submit MI W4 form details
export const submitMiW4FormDetails = async (payload: MiW4FormPayload, workplaceId: number) => {
	const response = await apiService.post('/employee-form/w4/michigan', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

// API function to submit I-9 Supplement Translator details
export const submitI9SupplementTranslator = async (payload: I9SupplementTranslatorPayload) => {
	const response = await apiService.post('/employee-form/i9/supplement/translator', payload);
	return response.data;
};

export const submitW4Supplement2B = async (payload: W4Supplement2BPayload) => {
	const response = await apiService.post('/employee-form/w4/supplement/2b', payload);
	return response.data;
};

export const submitDirectDepositForm = async (
	payload: DirectDepositFormPayload,
	workplaceId: number
) => {
	const response = await apiService.post('/employee-form/direct-deposit', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

export const getDirectDepositForm = async (employeeId: number) => {
	const response = await apiService.get(`/employee-form/direct-deposit/${employeeId}`);

	return response.data;
};

export const getElectronicW2ConsentForm = async (employeeId: number) => {
	const response = await apiService.get(`/employee-form/w2-consent/${employeeId}`);
	console.log('response:', response);
	return response.data;
};

export interface W2ConsentForm {
	workplace: {
		id: number;
	};
	employee: {
		id: number;
	};
	consent_electronic_w2: boolean;
	entered_email_phone_rasi: boolean;
	portal_invite_sent: boolean;
	hr_copy_sent: boolean;
	signature_approved: boolean;
}

export const submitW2ConsentForm = async (payload: W2ConsentForm, workplaceId: number) => {
	const response = await apiService.post('/employee-form/w2-consent', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

// **************************** GET FUNCTIONS ***************************

export interface CommonFormResponseFields {
	id: number;
	identifier?: string;
	workplace?: DocsWorkplace;
	employee?: DocsEmployee;
	author?: DocsAuthor;
}

export interface CommonFormRequestFields {
	workplace?: { id: number };
	employee?: { id: number };
	signature_approved?: boolean;
	human_resources_signature_date?: boolean;
	hr: { id: number };
}

export interface DocsWorkplace {
	id: string;
}

export interface DocsEmployee {
	email?: string;
	first_name?: string;
	middle_initial?: string;
	last_name?: string;
}

export interface DocsAuthor {
	email?: string;
	first_name?: string;
	middle_initial?: string;
	last_name?: string;
}

export interface DocsEmployeeSignatureFile {
	id: number;
	name: string;
	path: string;
	extension: string;
	size: string;
	mime_type: string;
}

export interface DocsEmployeeFormDetail extends CommonFormResponseFields {
	date_of_birth?: string;
	phone?: string;
	address?: string;
	city?: string;
	state?: string;
	zip_code?: string;
	ssn?: string;
	drivers_license_number?: string;
	employee_signature_file?: DocsEmployeeSignatureFile;
}

export interface DirectDepositResponse {
	id: number;
	identifier: string;
	workplace: { id: number };
	employee: DocsAuthor;
	bank_name: string;
	routing_number: string;
	account_number: string;
	account_type: string;
	change_payroll_account: boolean;
	employee_file: FileUploadResponse;
	author: DocsAuthor;
	signature_approved: boolean;
	signature_date: string;
	synced: boolean;
}

// ****

export interface DocsEmployeeFormI9 extends CommonFormResponseFields {
	immigration_status: number;
	uscis_a_number?: string;
	i94_admission_number?: string;
	foreign_passport_number?: string;
	authorization_expiration_date?: string;
	translator_assisted?: boolean;
	employee_rehire?: boolean;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
	employee_form_i9_not_citizen_a?: I9NonCitizenResponse;
	employee_form_i9_not_citizen_b?: I9NonCitizenResponse;
	employee_form_i9_not_citizen_c?: I9NonCitizenResponse;
	employee_form_i9_supplement_translator?: DocsEmployeeFormI9SupplementTranslator[];
	hr?: DocsHR;
}

export interface DocsEmployeeFormI9Supplement {
	identifier: string;
	rehire_date?: string;
	document_title: string;
	document_number: string;
	expiration_date?: string;
	employer_title: string;
	employer_business_name: string;
	employer_business_address: string;
	employer_city: string;
	employer_state: string;
	employer_zip_code: string;
	author: DocsAuthor;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export interface DocsEmployeeFormI9SupplementTranslator {
	identifier: string;
	first_name: string;
	middle_initial?: string;
	last_name: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	author: DocsAuthor;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export interface DocsHR {
	identifier: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	employer_ein: string;
	first_date_employment: string;
	signature_approved: boolean;
	signature_date: string;
	author: DocsAuthor;
	synced?: boolean;
}

// *****

export interface DocsEmployeeFormW4 extends CommonFormResponseFields {
	multiple_jobs_or_spouse_works: number;
	filing_status: number;
	number_of_children: number;
	number_of_dependents: number;
	other_income: number;
	deductions: number;
	extra_withholding: number;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
	employee_form_w4_supplement2_b?: DocsEmployeeFormW4Supplement2B[];
	hr?: DocsHR;
}

export interface DocsEmployeeFormW4Supplement2B extends Partial<CommonFormResponseFields> {
	identifier: string;
	two_jobs_amount: number;
	three_jobs_line2_a: number;
	three_jobs_line2_b: number;
	three_jobs_line2_c: number;
	pay_periods_per_year: number;
	amount_step4_c: number;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export const fetchEmployeeFormDetails = async (
	employeeId,
	params: CommonGetParams = {}
): Promise<DocsEmployeeFormDetail> => {
	let response;
	try {
		response = await apiService.get(`/employee-form/details/${employeeId}`, {
			params: {
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 500,
				page: params.page || 1,
			},
		});
		return response.data as DocsEmployeeFormDetail;
	} catch (error) {
		console.log(error);
	}
};

export const fetchMIW4FormDetails = async (
	employeeId,
	params: CommonGetParams = {}
): Promise<DocsEmployeeFormDetail> => {
	let response;
	try {
		response = await apiService.get(`/employee-form/w4/michigan/${employeeId}`, {
			params: {
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 500,
				page: params.page || 1,
			},
		});
		return response.data as DocsEmployeeFormDetail;
	} catch (error) {
		console.log(error);
	}
};

export const fetchI9EmployeeForms = async (
	employeeId,
	params: CommonGetParams = {}
): Promise<DocsEmployeeFormI9> => {
	let response;
	try {
		response = await apiService.get(`/employee-form/i9/${employeeId}`, {
			params: {
				query: params.query || '',
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 500,
				page: params.page || 1,
			},
		});
		return response.data as DocsEmployeeFormI9;
	} catch (error) {
		console.log(error);
	}
};

export const fetchEmployeeFormW4s = async (
	employeeId,
	params: CommonGetParams = {}
): Promise<DocsEmployeeFormW4> => {
	let response;
	try {
		response = await apiService.get(`/employee-form/w4/${employeeId}`, {
			params: {
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 5,
				page: params.page || 1,
			},
		});

		return response.data as DocsEmployeeFormW4;
	} catch (error) {
		console.log(error);
	}
};

export interface DocsHRResponse {
	id: number;
	identifier: string;
	employer_title: string;
	employer_name: string;
	employer_business_name: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	employer_ein: string;
	signature_approved: boolean;
	signature_date: string;
	signature_file: DocsEmployeeSignatureFile;
	author: DocsAuthor;
	synced: boolean;
}

export interface CreateEmployeeResponse {
	id: number;
	email: string;
	roles: string[];
	first_name: string;
	middle_initial: string;
	last_name: string;
	gender: string;
	active: boolean;
	login_token: string;
	password_reset_token: string;
	email_confirmed: boolean;
	tags: UserTag[];
	person: UserPerson;
	profile_picture: string;
	qr_code: string;
	pin: number;
	deleted: boolean;
	import_origin: ImportOrigin;
	import_id: string;
	parameter: string;
	phone: string;
}

export interface UserTag {
	id: number;
	name: string;
	color: string;
}

export interface UserPerson {
	id: number;
	first_name: string;
	middle_initial: string;
	last_name: string;
	email: string;
	email_confirmed: boolean;
	active: boolean;
}

export interface ImportOrigin {
	id: number;
}

export interface CreateEmployeePostPayload {
	first_name: string;
	middle_initial: string;
	last_name: string;
	email: string;
	active: boolean;
}

export const submitCreateEmployeeForm = async (payload: CreateEmployeePostPayload): Promise<CreateEmployeeResponse | null> => {
	try {
		const response = await apiService.post('employee-form/create-employee', payload);
		return response.data ? (response.data as CreateEmployeeResponse) : null;
	} catch (error) {
		console.error('Error submitting HR form:', error);
		return null;
	}
};

export const fetchHRForm = async (): Promise<DocsHRResponse | null> => {
	try {
		const response = await apiService.get('employee-form/hr');
		return response.data ? (response.data as DocsHRResponse) : null;
	} catch (error) {
		console.error('Error fetching HR form:', error);
		return null;
	}
};

export interface DocsHRPostPayload {
	employer_title: string;
	employer_name: string;
	employer_business_name: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	employer_ein: string;
	signature_approved: boolean;
	signature_file: {
		id: number;
	};
}

export const submitHRForm = async (payload: DocsHRPostPayload): Promise<DocsHRResponse | null> => {
	try {
		const response = await apiService.post('employee-form/hr', payload);
		return response.data ? (response.data as DocsHRResponse) : null;
	} catch (error) {
		console.error('Error submitting HR form:', error);
		return null;
	}
};

export const uploadHRFile = async (file: File): Promise<DocsEmployeeSignatureFile | null> => {
	const formData = new FormData();
	formData.append('file', file);

	try {
		const response = await apiService.postUpload('employee-form/hr/upload', formData);
		return response.data ? (response.data as DocsEmployeeSignatureFile) : null;
	} catch (error) {
		console.error('Error uploading HR file:', error);
		return null;
	}
};

// **************************** PUT FUNCTIONS ***************************

export interface UpdateI9FormPayload extends CommonFormRequestFields {
	first_date_employment: string;
}

// Define the API function
export const updateI9Form = async (formId: string, payload: UpdateI9FormPayload): Promise<any> => {
	const response = await apiService.put(`/employee-form/i9/${formId}`, {
		human_resources_signature_date: new Date().toISOString().split('T')[0],
		...payload,
	});
	return response.data;
};

export const updateW4Form = async (formId: string, hrId: string): Promise<any> => {
	const response = await apiService.put(`/employee-form/w4/${formId}`, {
		human_resources_signature_date: new Date().toISOString().split('T')[0],
		hr: { id: hrId },
	});
	return response.data;
};
